<form #demandeChangementAdminForm="ngForm">
	<div class="p-fluid formgrid grid">
		<div class="field col-12 md:col-6 xl:col-4">
			<label for="uti_email">Email du nouvel administrateur</label>
			<input
				type="email"
				pInputText
				name="uti_email"
				#uti_email
				[(ngModel)]="noa_json_source.email"
				[required]="true"
				email="true"
				[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
				placeholder="email@exemple.com"
			>
			<div *ngIf="submitted && uti_email.errors" class="invalid-feedback p-invalid">
				<small *ngIf="uti_email.errors.required" >Email requis</small>
				<small *ngIf="uti_email.errors.email">Adresse email invalide</small>
			</div>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="prenom">Date d'application</label>
			<p-calendar
				name="date_application"
				#date_application="ngModel"
				[(ngModel)]="noa_json_source.date_application"
				firstDayOfWeek="1"
				dateFormat="dd/mm/yy"
				[yearNavigator]="true"
				yearRange="2020:2030"
				[minDate]="minDate"
				[showIcon]="true"
				[required]="true"
				placeholder="jj/mm/yyyy"
			></p-calendar>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="nom">Nom du nouvel administrateur</label>
			<input
				type="text"
				pInputText
				name="nom"
				#nom
				[(ngModel)]="noa_json_source.nom"
				placeholder="Nom"
				[ngClass]="{ 'p-invalid': submitted && nom.errors }"
				[required]="true"
			>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="prenom">Prénom du nouvel administrateur</label>
			<input
				type="text"
				pInputText
				name="prenom"
				#prenom
				[(ngModel)]="noa_json_source.prenom"
				placeholder="Prénom"
				[ngClass]="{ 'p-invalid': submitted && prenom.errors }"
				[required]="true"
			>
		</div>


	</div>

	<div class="p-fluid formgrid grid">
		<div class="field col-12 md:col-12">
			<label for="commentaire">Commentaire</label>
			<textarea
				pInputTextarea
				name="commentaire"
				#commentaire
				[(ngModel)]="noa_description"
				[autoResize]="true"
				[rows]="9"
				[cols]="30"
				placeholder="Merci de préciser toute information susceptible d'aider au traitement de votre demande."
			></textarea>
		</div>
	</div>

	<div class="text-center">
		<button
			type="button"
			pButton
			class="p-button-outlined mr-2"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			label="Envoyer la demande"
			[loading]="loading"
			[disabled]="!demandeChangementAdminForm.form.valid"
			(click)="submit()"
		></button>
	</div>
</form>
