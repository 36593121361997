<h4 class="text-center">Changement de mot de passe</h4>
<form class="p-fluid mb-2" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
	<div class="form-group field">
		<label>Nouveau mot de passe</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input type="password" style="display: none;" name="damn-you-autocomplete"/>
			<input type="password" style="display: none;" name="damn-you-autocomplete2"/>
			<input type="password" style="display: none;" name="damn-you-autocomplete3"/>
			<p-password
				formControlName="password"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': f.password.dirty && f.password.errors }"
				placeholder="Nouveau mot de passe"
				promptLabel="Saisissez votre nouveau mot de passe"
				appendTo="body"
				(ngModelChange)="checkCriterias()"
			></p-password>
		</div>
		<div>
			<small>
				Le mot de passe doit :
				<ul class="pl-2 ml-2 mt-0">
					<li [ngClass]="{'text-color-success': f.password.dirty && f.password.valid, 'text-color-danger': f.password.errors && f.password.errors.pattern}">être suffisamment complexe</li>
					<li [ngClass]="{'text-color-success': criterias.nb_chars.result == 1, 'text-color-danger': criterias.nb_chars.result == 0}">contenir au minimum 8 caractères</li>
					<li [ngClass]="{'text-color-success': criterias.uppercase.result == 1, 'text-color-danger': criterias.uppercase.result == 0}">contenir au minimum une majuscule</li>
					<li [ngClass]="{'text-color-success': criterias.lowercase.result == 1, 'text-color-danger': criterias.lowercase.result == 0}">contenir au minimum une minuscule</li>
					<li [ngClass]="{'text-color-success': criterias.nb_numbers.result == 1, 'text-color-danger': criterias.nb_numbers.result == 0}">contenir au minimum un chiffre</li>
				</ul>
			</small>
		</div>
	</div>
	<div class="form-group field">
		<label>Confirmation nouveau mot de passe</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input
				pInputText
				type="password"
				formControlName="passwordConfirm"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.passwordConfirm.errors }"
				placeholder="Confirmation mot de passe"
			/>
		</div>
		<div *ngIf="f.passwordConfirm.dirty && f.passwordConfirm.errors" class="text-color-danger">
			<small *ngIf="f.passwordConfirm.errors.required">Confirmation du mot de passe requise</small>
			<small *ngIf="f.passwordConfirm.errors.mustMatch">Le nouveau mot de passe et sa confirmation sont différents</small>
		</div>
	</div>
	<div *ngIf="success">
		<p-message severity="success" text="{{messageSuccess}}"></p-message>
	</div>
	<button
		type="submit"
		pButton
		label="Valider le nouveau mot de passe"
		[disabled]="!changePasswordForm.valid || loading"
		*ngIf="!success"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

</form>

<p class="text-center">
	<a uiSref="auth.login">Retourner au formulaire de connexion</a>
</p>
