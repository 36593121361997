import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';

import { AuthService } from '@app/auth/auth.service';
import { EqipModule } from '@global/eqip.module';
import { EventManagerService } from '@global/event-manager.service';

@Component({
	template: `
		<div class="landing flex-column justify-content-center align-items-center vertical-container relative">
			<img src="/assets/instance/logo-iteq-large.png" class="mb-5 logo-xl">

			<p *ngIf="!error">Service momentanément indisponible</p>

			<ng-container *ngIf="error">
				<ng-container [ngSwitch]="error">

					<p *ngSwitchCase="'maintenance'" class="text-center">Une opération de maintenance est en cours. <br>Toutes nos excuses pour le désagrément. Nous revenons très vite.</p>

					<p *ngSwitchDefault>Aïe. Ceci n'aurait pas dû arriver...</p>

				</ng-container>
			</ng-container>


			<a href="/">
				<button
					type="button"
					pButton
					class="mb-3"
					label="Retourner à la page d'accueil"
				></button>
			</a>

			<button
				type="button"
				pButton
				class="p-button-sm p-button-text"
				label="Déconnexion"
				(click)="logout()"
				*ngIf="loggedIn"
			></button>

		</div>
	`
})
export class ServiceUnavailableComponent implements OnInit {

	@Input() error: any;

	loggedIn: boolean = false;

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private stateService: StateService,
	) { }

	ngOnInit() {
		if (!this.error) {
			this.error = this.stateService.params.error;
		}
		this.loggedIn = !!this.authService.token;
	}

	logout() {
		this.eventManager.emit('logout', true);
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		ButtonModule,
		EqipModule
	],
	exports: [ServiceUnavailableComponent],
	declarations: [ServiceUnavailableComponent]
})
export class ServiceUnavailableModule { }
