import { Ristourne } from '@app/ristourne/ristourne.model';
import { uid } from '@helpers/utils';

export class Segment {
	sca_id: number;
	con_id: number;
	tsc_type: string;
	sca_libelle: string;
	sca_description: string;
	sca_bonifiable?: boolean = false;
	sca_inclusion_objectif: boolean = false;
	sca_complementeur: boolean = false;
	sca_groupe_condition: number = 0;
	sca_affichage_fiche_nego: boolean = true;
	ristournes: Ristourne[] = [];
	uid: string = uid();

	constructor(
		tsc_type?: string,
		sca_bonifiable?: boolean,
		sca_complementeur?: boolean,
		sca_groupe_condition?: number
	) {
		this.tsc_type = (tsc_type)? tsc_type : 'S';
		if (this.tsc_type == 'S') {
			if (typeof sca_groupe_condition != 'undefined') {
				this.sca_groupe_condition = sca_groupe_condition;
			}
			if (sca_bonifiable) {
				this.sca_bonifiable = true;
				this.sca_inclusion_objectif = true;
				this.sca_libelle = 'CA bonifiable';
			}
			else {
				this.sca_bonifiable = false;
				this.sca_inclusion_objectif = false;
				this.sca_libelle = 'CA non-bonifiable';
				if (sca_complementeur == true) {
					this.sca_complementeur = true;
				}
			}
		}
		else {
			this.sca_bonifiable = true;
			this.sca_inclusion_objectif = true;
			this.sca_libelle = 'Challenge';
		}
	}
}
