import { Segment } from '@app/convention/segment.model';
import { TscType } from '@app/convention/type-segment.model';

export class CorrespondanceSegmentInstance {
	cos_id: number|null = null;
	cic_code: string = ''; // provient du excel
	cos_annee: number = 0;
	cos_mois: number = 0;
	cos_importer: boolean = false;
	// source
	cos_sca_libelle_source: string = '';
	// cible
	cic_id: number|null = null;
	sca_id: number|null = null;
}

export class CorrespondanceSegmentInstanceExtended extends CorrespondanceSegmentInstance {
	// cos_id: number|null; // null si pas encore défini
	// cos_annee: number;
	// cos_mois: number;
	// cos_importer: boolean;

	// source
	// cic_code: string; // provient du excel
	ctx_libelle_source: string; // provient du excel
	// cos_sca_libelle_source: string; // provient du excel ou de la bdd
	tsc_type_source: string; // provient du excel
	con_date_debut_source: Date|string; // provient du excel
	con_date_fin_source: Date|string; // provient du excel

	// cible
	por_id: number;
	// cic_id: number;
	ctx_id: number; // partenariat
	ctx_libelle: string;
	ctx_logo_listing: string;
	grc_id: number; // groupe fournisseur
	grc_libelle: string;
	con_id: number;
	con_date_debut: Date|string;
	con_date_fin: Date|string;
	// sca_id: number|null; // null si pas encore défini
	sca_libelle: string|null; // null si pas encore défini
	tsc_type: TscType|null; // null si pas encore défini

	// liste des segments et challenges de la convention cible, pour permettre une sélection
	segments: Segment[]|any[]
	segmentsGrouped: any[];

	nbImports: number;
	nbSegments: number;
	uid: string;
}

