import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateService } from '@uirouter/core';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { MenuItem } from 'primeng/api';
import { ParametreService } from '@app/parametre/parametre.service';
import { PortailService } from '@app/portail/portail.service';

import { clone, uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class AppMenuService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private toggleMenuSubject = new Subject<boolean>();
	public toggleMenu$ = this.toggleMenuSubject.asObservable();

	private toggleMobileMenuSubject = new Subject<boolean>();
	public toggleMobileMenu$ = this.toggleMobileMenuSubject.asObservable();

	private menuItemsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
	public menuItems$ = this.menuItemsSubject.asObservable();

	constructor(
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private portailService: PortailService,
		private stateService: StateService,
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.setMenuItems([]);
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	toggleMenu(toggle?: boolean): void {
		this.toggleMenuSubject.next(!!toggle);
	}

	toggleMobileMenu(toggle?: boolean): void {
		this.toggleMobileMenuSubject.next(!!toggle);
	}

	setMenuItems(items: any[]): void {
		let preppedItems = this.prepareItems(items);
		this.menuItemsSubject.next(preppedItems);
	}

	checkItemRight(item: any) {
		let hasRight = true;
		if (typeof item.droits != 'undefined') {
			hasRight = this.portailService.checkIfHasRight(item.droits, !!item.requiresAllRights);
		}
		if (typeof item.participantModule != 'undefined') {
			const currentContexte = this.portailService.currentContexte;
			if (currentContexte.groupe_contexte && !!!currentContexte.groupe_contexte[`grc_module_${item.participantModule}`]) {
				hasRight = false;
			}
		}
		return hasRight;
	}

	checkIfFonctionnaliteActive(item: any) {
		if (typeof item.fonctionnalite != 'undefined') {
			return this.parametreService.getParam(item.fonctionnalite, false);
		}
		return true;
	}

	prepareItems(items: any[]): MenuItem[] {
		const tmp = clone(items);

		for (let i = tmp.length - 1; i >= 0 ; i--) {
			if (!this.checkItemRight(tmp[i]) || !this.checkIfFonctionnaliteActive(tmp[i])) {
				tmp.splice(i, 1);
			}
			else if (tmp[i].items && tmp[i].items.length) { // has sublevels
				tmp[i].items = this.prepareItems(tmp[i].items);
				if (tmp[i].items.length > 1) {
					// tmp[i]['expanded'] = true; // the "expanded" state is managed in the menu definition
				}
				else if (tmp[i].items.length == 1 && !!!tmp[i].keepIfHasSingleChild) { // if only one sublevel, and parent is replaceable, replace the parent
					tmp.splice(i, 1, tmp[i].items[0]);
				}
				else if (tmp[i].items.length == 0) { // if no sublevel, delete the parent
					tmp.splice(i, 1);
				}
			}
		}
		return tmp;
	}

	updateAppMenuActiveStatus() {
		let tmp = this.menuItemsSubject.value;
		this.updateActiveStatus(tmp, true);
		this.menuItemsSubject.next(tmp);
	}

	updateActiveStatus(items: any[], setExpandedStatus?: boolean) {
		let parentShouldBeActive = false;

		items.forEach((item: any) => {
			if (item.items && item.items.length > 0) {
				let hasChildrenWithActiveStates = this.updateActiveStatus(item.items, setExpandedStatus);
				if (setExpandedStatus) {
					item.expanded = item.expanded || hasChildrenWithActiveStates;
				}
				if (!parentShouldBeActive) parentShouldBeActive = hasChildrenWithActiveStates;
				item.active = !!hasChildrenWithActiveStates;
			}
			else {
				if (item.state && this.stateService.includes(item.state)) {
					item.active = true;
					parentShouldBeActive = true;
					if (setExpandedStatus) {
						item.expanded = true;
					}
				}
			}
		});
		return parentShouldBeActive;
	}


}
