import { NgModule, Component, OnChanges, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalPipe } from '@angular/common';

import { copyToClipboard } from '@helpers/utils';

@Component({
	selector: 'number-display',
	template: `
		<span *ngIf="valueFixed == null">
			&nbsp;
		</span>
		<span title="{{_title}}" *ngIf="valueFixed != null" (copy)="onCopy($event)">
			<ng-container *ngIf="labelInfinity && isInfinite">
				{{labelInfinity}}
			</ng-container>
			<ng-container *ngIf="!isInfinite">
				<span *ngIf="prefix && showPrefix">{{prefix}}</span>
				<ng-container [ngSwitch]="type">
					<span *ngSwitchCase="'percent'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchCase="'short_percent'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchCase="'currency'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchCase="'short_currency'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchCase="'quantity'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchCase="'short_quantity'">{{value|number:numberFormat}}{{_suffix}}</span>
					<span *ngSwitchDefault>
						<ng-container *ngIf="numberFormat !== undefined">
							{{value|number:numberFormat}}
						</ng-container>
						<ng-container *ngIf="numberFormat == undefined">
							{{value|number}}
						</ng-container>
					</span>
				</ng-container>
			</ng-container>
		</span>
	`,
	providers: [DecimalPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberDisplayComponent implements OnChanges {

	@Input() value: number;
	@Input() type: 'percent'|'short_percent'|'currency'|'short_currency'|'short_decimal'|'quantity'|'short_quantity'|undefined;
	@Input() prefix: string;
	@Input() showPrefix: boolean = false;
	@Input() suffix: string;
	@Input() showSuffix: boolean = true;
	@Input() lowerThreshold: number;
	@Input() numberFormat: string; // from decimalPipe. {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
	@Input() hideZero: boolean;
	@Input() labelInfinity: string;
	@Input() title: string;

	valueFixed: number|null = null;
	valueString: string|null;
	isInfinite: boolean = false;
	_suffix: string;
	_title: string;

	constructor(private decimalPipe: DecimalPipe) {

	}

	ngOnChanges(changes: any): void {
		if (typeof this.value == 'undefined' || this.value == null || this.value == NaN) {
			this.valueFixed = null;
		}
		else {
			if (this.value === 0 && this.hideZero) {
				this.valueFixed = null;
			}
			else {
				this.isInfinite = this.value == Infinity;

				// limit decimals
				this.valueFixed = Math.round((this.value + Number.EPSILON) * 10000) / 10000;
				switch (this.type) {
					case 'percent':
						this.numberFormat = this.numberFormat || '.2-3';
						break;
					case 'quantity':
						this.numberFormat = this.numberFormat || '.';
						break;
					case 'currency':
					case 'short_decimal':
						this.numberFormat = this.numberFormat || '.0-2';
						break;
					case 'short_currency':
					case 'short_percent':
					case 'short_quantity':
						this.numberFormat = this.numberFormat || '.0-0';
						break;
				}

				if (typeof this.suffix == 'undefined') {
					switch (this.type) {
						case 'percent':
						case 'short_percent':
							this.suffix = '%';
							break;
						case 'quantity':
						case 'short_quantity':
							this.suffix = 'unit.';
							break;
						case 'currency':
						case 'short_currency':
							this.suffix = '€';
							break;
					}
				}
				if (this.showSuffix && this.suffix) {
					this._suffix = ` ${this.suffix}`;
				}

				this.valueString = this.decimalPipe.transform(this.value);

				if (this.showPrefix) {
					if (typeof this.prefix == 'undefined') {
						this.prefix = '≥ ';
						if (typeof this.lowerThreshold != 'undefined' && this.value <= this.lowerThreshold) {
							this.prefix = '< ';
						}
					}
				}
			}
		}
		let suffix: string = '';
		if (this._suffix) suffix = this._suffix;
		this._title = (this.title)? this.title : `${this.valueString}${suffix}`;
	}

	ngOnDestroy(): void {

	}

	onCopy(event: any) {
		const selection: Selection|null = window.getSelection();
		if (selection) {
			const range: any = selection.getRangeAt(0);
			const unformated = selection.toString().replace(/\s/g, '');
			copyToClipboard(unformated);
			window.getSelection()!.removeAllRanges();
			document.getSelection()!.addRange(range);
		}
		event.preventDefault();
	}

}


@NgModule({
	imports: [
		CommonModule
	],
	exports: [NumberDisplayComponent],
	declarations: [NumberDisplayComponent]
})
export class NumberDisplayModule { }
