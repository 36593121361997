import { PortailParam } from '@app/portail/portail.service';
import { TypePerformance } from '@app/ristourne/type-performance.model';
import { TypePrestation } from '@app/convention/type-prestation.model';

export class DemandeReglement {
	drf_id: number;
	sdr_code: string;
	drf_annee: number;
	drf_mois: number;
	drf_date_creation: string|Date;
	edr_code: string = 'E';
	lignes_demandes_reglements: any[];
	por_id: number;
	portail?: PortailParam;
	label?: string;

	mcp_code?: string;

	total_conventions?: number;
	total_conventions_attentes?: number;
	total_conventions_soldees?: number;
	total_conventions_distribuees?: number;
	total_montant_calcule?: number;
	total_montant_reclame?: number;
	total_montant_regle?: number;
	total_montant_distribue?: number;
	total_montant_regle_distribuable?: number;
	total_reglement_non_valide?: number;
	total_tva_percue_distribue?: number;

	types_performances?: number[]|TypePerformance[] = [];
	types_prestations?: number[]|TypePrestation[] = [];

	constructor() {

	}
}
