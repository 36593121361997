<h4 class="text-center">Vérification de sécurité</h4>
<p class="mb-3">Un email vient de vous être envoyé. Saisissez ici le code contenu dans cet email.</p>
<form class="p-fluid mb-2" #login2faForm="ngForm">
	<div class="form">

		<div class="field text-center">
			<p-inputOtp
				name="code2fa"
				[(ngModel)]="code2fa"
				[integerOnly]="true"
				[length]="6"
				[required]="true"
			>
			</p-inputOtp>
		</div>

	</div>

	<button
		type="button"
		pButton
		label="Valider"
		[disabled]="!login2faForm.valid || loading"
		(click)="submit()"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

	<p class="text-center mt-3">Si vous n'avez pas reçu l'email ou si le code est invalide, <span class="link clickable" (click)="resend()">cliquez ici pour recevoir un nouveau code</span>.</p>

</form>

<p class="text-center">
	<button
		type="button"
		pButton
		class="p-button-text"
		label="Retourner au formulaire de connexion"
		[disabled]="loading"
		(click)="logout()"
	></button>
</p>
