import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { OrigineFacture } from '@app/compta/origine-facture.model';

@Pipe({name: 'origineFacture'})
export class OrigineFacturePipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(orf_code?: string): string {
		const originesFacture = this.comptaService.originesFacture;
		const origine = originesFacture.find((one: OrigineFacture) => {return one.orf_code == orf_code;});
		if (origine) {
			return  origine.orf_libelle;
		}
		return orf_code? orf_code.toString() : '';
	}
}
