import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';
import { TypeSegment } from '@app/convention/type-segment.model';

@Pipe({name: 'typeSegment'})
export class TypeSegmentPipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(tsc_type?: string): string {
		const typesSegment = this.conventionService.typesSegment;
		const segment = typesSegment.find((one: TypeSegment) => {return one.tsc_type == tsc_type;});
		if (segment) {
			return  segment.tsc_libelle;
		}
		return tsc_type? tsc_type.toString() : '';
	}
}
