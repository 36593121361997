import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CodesInternesService } from '@global/codes-internes.service';
import { CommentaireSuivi } from './commentaire-suivi.model';
import { Convention } from '@app/convention/convention.model';
import { ConventionService } from '@app/convention/convention.service';
import { CorrespondanceSegmentInstance, CorrespondanceSegmentInstanceExtended } from './correspondance-segment-instance.model';
import { Segment } from '@app/convention/segment.model';
import { environment } from '@environments/environment';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import { SaisieCA } from '@app/ca/saisie-ca.model';
import { SaisieCAAdherent } from '@app/ca/saisie-ca-adherent.model';

import { Contexte } from '@app/contexte/contexte.model';

import { localeFR } from '@helpers/locale-fr';

import {
	assignExisting,
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	ExtensibleObject,
	padString,
	uid,
} from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class CAService {

	private _codesSuivis: any[] = [
		{csu_code_suivi: 'bfa', libelle: 'BFA', endpoint: 'commentaires_bfa'},
		{csu_code_suivi: 'bfa_prestations', libelle: 'Prestations', endpoint: 'commentaires_bfa'},
		{csu_code_suivi: 'bfa_ristournes', libelle: 'Ristournes', endpoint: 'commentaires_bfa'},
		{csu_code_suivi: 'ca_groupes_adherents', libelle: 'CA Groupes Adhérents', endpoint: 'commentaires_ca_groupes_adherents'},
	];

	public get codesSuivi() {
		return [...this._codesSuivis];
	}


	constructor(
		private codesInternesService: CodesInternesService,
		private conventionService: ConventionService,
		private http: HttpClient,
		private portailService: PortailService
	) {

	}

	public prepareSaisiesCA(saisies_ca: SaisieCA[]) {
		let tmp = clone(saisies_ca);
		let model = new SaisieCA();
		tmp.forEach((one: any) => {
			for (let key in one) {
				if (!model.hasOwnProperty(key)) {
					delete one[key];
				}
			}
		});
		return tmp;
	}

	public getCAConvention(ctx_id: number,	con_id: number, params: any) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/saisies_ca`, {params: params});
	}

	public exportCA(
		con_id: number,
		ctx_id: number,
		annee_precedente?: boolean,
		tsc_type?: string,
		sca_id?: number,
		mode?: 'montant'|'quantite'
	) {
		let tmpParams: any= {};
		if (annee_precedente) {
			tmpParams.annee_precedente = true;
		}
		if (tsc_type) {
			tmpParams.tsc_type = tsc_type;
		}
		if (sca_id) {
			tmpParams.sca_id = sca_id;
		}
		tmpParams.mode = mode;
		tmpParams = prepareQueryParamsForDownload(tmpParams);
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/export_ca`, tmpParams);
	}

	public getUrlImportCA(
		con_id: number,
		ctx_id: number,
		validation?: boolean,
		annee_precedente?: boolean,
		tsc_type?: string,
		sca_id?: number,
		mode: 'montant'|'quantite' = 'montant'
	) {
		let url: string = `${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}`;

		let endpoint: string =  'import_ca';
		if (validation) endpoint = `${endpoint}/validation`;

		url = `${url}/${endpoint}`;

		if (tsc_type) {
			url = `${url}?tsc_type=${tsc_type}`;
			if (sca_id) {
				url = `${url}&sca_id=${sca_id}`;
			}
		}

		if (annee_precedente) {
			url = `${url}&annee_precedente=true`;
		}

		url = `${url}&mode=${mode}`;

		return url;
	}


	public exportCAMatriciel(con_id: number, ctx_id: number, annee_precedente?: boolean) {
		let tmpParams = prepareQueryParamsForDownload();
		if (annee_precedente) {
			tmpParams.params.annee_precedente = true;
		}
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/export_ca_matriciel`, tmpParams);
	}

	public getUrlImportCAMatriciel(con_id: number, ctx_id: number, annee_precedente?: boolean) {
		let tmp;
		tmp = `${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/import_ca_matriciel`;
		if (annee_precedente) tmp = `${tmp}&annee_precedente=true`;
		return tmp;
	}

	public getURlImportCAMatricielValidation(con_id: number, ctx_id: number, annee_precedente?: boolean) {
		let tmp;
		tmp = `${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/import_ca_matriciel/validation`;
		if (annee_precedente) tmp = `${tmp}&annee_precedente=true`;
		return tmp;
	}

	public post(saisies_ca: SaisieCA[], con_id: number, ctx_id: number) {
		let tmp = this.prepareSaisiesCA(saisies_ca);
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/saisies_ca`, tmp);
	}

	public saisieZero(con_id: number, ctx_id: number, mois: number, annee: number) {
		let tmp: {[key: string]: any} = {};
		tmp.annee = annee;
		tmp.mois = mois;
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/saisies_zero`, tmp);
	}

	public repriseCAPrecedent(convention: Convention) {
		return this.http.post<any>(`${environment.api_url}/contextes/${convention.ctx_id}/conventions_partenariats/${convention.con_id}/reprise_ca_precedent`, {});
	}

	public prepareSuivisCAFromServer(suivisCA: any[]) {
		suivisCA.forEach(one => {
			if (one.evolutions_mois) {
				one.evolutions_mois = this.formatEvolutionsMois(one.evolutions_mois);
			}
			convertDateFieldsToDate(one, true);
			this.codesInternesService.formatLabel(one);
		});
	}

	public prepareSuivisBFAFromServer(suivisBFA: any[]) {
		suivisBFA.forEach((one: any) => {
			// totaux "complets" des prestations
			this.calculateTotauxPrestations(one);
			// totaux redistribuables des prestations
			this.calculateTotauxPrestations(one, true);
			this.codesInternesService.formatLabel(one);
		});
	}

	public calculateTotauxPrestationsBaseCalcul(suivi: any, baseCalcul: 'prev'|'real', redistribuable: boolean = false) {
		let suffix: string = baseCalcul;
		suffix += redistribuable? '_redistribuable' : '';
		suivi[`presta_${suffix}`] = 0;
		suivi[`total_presta_${suffix}`] = 0;
		if (suivi.prestations && Array.isArray(suivi.prestations)) {
			suivi.prestations.forEach((presta: any) => {
				// on additionne si on traite les totaux "complets"
				// ou bien si on demande le redistribuable et que la prestation est effectivement redistribuable
				if (!redistribuable || redistribuable && presta.pre_redistribuable) {
					suivi[`presta_${suffix}`] += presta[baseCalcul];
					suivi[`total_presta_${suffix}`] += presta[baseCalcul];
				}
			});
		}
	}

	public calculateTotauxPrestations(suivi: any, redistribuable: boolean = false) {
		this.calculateTotauxPrestationsBaseCalcul(suivi, 'prev', redistribuable);
		this.calculateTotauxPrestationsBaseCalcul(suivi, 'real', redistribuable);
	}

	public calculateMoisDeclares(objectOfMoisSaisis: any[], ...attrsToCheck: Array<string>) {
		let result: any = {
			mois_declares: [],
			mois_declares_title: ''
		}

		for (let propMois in objectOfMoisSaisis){
			// on considère que le mois est déclaré si la valeur de contrôle n'est pas null
			const declare: boolean = attrsToCheck.some((attr: string) => {
				return typeof objectOfMoisSaisis[propMois][attr] != 'undefined' && objectOfMoisSaisis[propMois][attr] != null;
			});
			const horsConvention = objectOfMoisSaisis[propMois].hors_convention;
			const booleanString: string = declare? 'Oui' : horsConvention? 'Hors convention' : 'Non';
			const moisString: string = localeFR.monthNames[objectOfMoisSaisis[propMois].mois -1];
			result.mois_declares_title = `${result.mois_declares_title}${moisString}: ${booleanString}\n`;

			result.mois_declares.push({
				label: moisString.substring(0,1),
				declare: declare,
				hors_convention: horsConvention
			});
		}
		return result;
	}

	// transformer un array de mois en un objet avec attribut pour chaque mois
	public formatEvolutionsMois(evolutions: any[]|any) {
		let tmp = new ExtensibleObject();
		if (Array.isArray(evolutions)) {
			evolutions.forEach((mois: any) => {
				this.formatEvolutionMois(tmp, mois);
			});
		}
		else {
			for (let prop in evolutions) {
				this.formatEvolutionMois(tmp, evolutions[prop]);
			}
		}
		return tmp;
	}

	public formatEvolutionMois(obj: any, rawEvolution: any) {
		let padded;
		if (rawEvolution.cag_mois && rawEvolution.cag_annee) {
			padded = padString(rawEvolution.cag_mois.toString(), '0', 2);
			obj[`${rawEvolution.cag_annee}${padded}`] = rawEvolution;
		}
		else if (rawEvolution.mois && rawEvolution.annee) {
			padded = padString(rawEvolution.mois.toString(), '0', 2);
			obj[`${rawEvolution.annee}${padded}`] = rawEvolution;
		}
	}

	public getSuiviCAFournisseurs(params: any, portail?: PortailParam, grc_id_reference?: number, ctx_id_reference?: number) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/suivi_ca_fournisseurs`, tmpParams)
			.pipe(map((response: any) => {
				this.prepareSuivisCAFromServer(response.conventions);
				return response;
			}));
	}

	public getSuiviCADetaille(params: any, portail?: PortailParam, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/suivi_ca_detaille`, tmpParams)
			.pipe(map((response: any) => {
				convertDateFieldsToDate(response, true);
				return response;
			}));
	}

	public getSuiviCADetailleTotalGroupement(params: any, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		let ctx_id = this.portailService.currentContexte.ctx_id;
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/suivi_ca_detaille_total_groupement`, tmpParams)
		.pipe(map((response: any) => {
			convertDateFieldsToDate(response, true);
			return response;
		}));
	}

	public getSuiviCADetailleAgence(params: any, portail?: PortailParam, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/suivi_ca_agences`, tmpParams)
			.pipe(map((response: any) => {
				convertDateFieldsToDate(response, true);
				return response;
			}));
	}

	public exportSuiviCAAgences(con_id: number, ctx_id?: number) {
		let tmpParams = prepareQueryParamsForDownload();
		let url = `${environment.api_url}`;
		if (ctx_id) {
			url = `${environment.api_url}/contextes/${ctx_id}`;
		}
		url = `${url}/conventions_partenariats/${con_id}/export_suivi_ca_agences`;
		return this.http.get<any>(url, tmpParams);
	}

	public getSuiviCAFournisseursTotalGroupement(params: any, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		let ctx_id = this.portailService.currentContexte.ctx_id;
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/ca_fournisseurs_total_groupement`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisCAFromServer(response.conventions);
			return response;
		}));
	}

	public getSuiviCAAdherents(params: any, portail: PortailParam, grc_id_reference?: number, ctx_id_reference?: number) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/suivi_ca_adherents`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisCAFromServer(response.adherents);
			return response;
		}));
	}

	public getCAAdherent(params: any, grc_id: number) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/groupes_contextes/${grc_id}/saisies_ca_adherent`, tmpParams);
	}

	public postSaisiesCAAdherent(saisies: any, grc_id: number) {
		return this.http.post<any>(`${environment.api_url}/groupes_contextes/${grc_id}/saisies_ca_adherent`, saisies);
	}

	public getSuiviCAGroupeAdherents(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/suivi_ca_groupes_adherents`, tmpParams)
		.pipe(map(response => {
			this.prepareSuivisCAFromServer(response.ca_groupes_adherents);
			return response;
		}));
	}

	public getSuiviBFAFournisseurs(params: any, portail: PortailParam, grc_id_reference?: number, ctx_id_reference?: number) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/bfa_fournisseurs`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisBFAFromServer(response.bfa_fournisseurs);
			return response;
		}));
	}

	public getSuiviBFAFournisseursTotalGroupement(params: any, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		let ctx_id = this.portailService.currentContexte.ctx_id;
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/bfa_fournisseurs_total_groupement`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisBFAFromServer(response.bfa_fournisseurs);
			return response;
		}));
	}

	public getSuiviBFAAdherents(params: any, portail: PortailParam, grc_id_reference?: number, ctx_id_reference?: number) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		const url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference, 'current');

		return this.http.get<any>(`${url}/bfa_adherents`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisBFAFromServer(response.bfa_adherents);
			return response;
		}));
	}

	public getSuiviBFAAdherentsTotalGroupement(params: any, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);

		let ctx_id = this.portailService.currentContexte.ctx_id;
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/bfa_adherents_total_groupement`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareSuivisBFAFromServer(response.bfa_adherents);
			return response;
		}));
	}

	public getCommentaireSuivi(annee: number, portail: PortailParam, code_suivi: string) {
		const por_id = this.portailService.getPortailId(portail);
		return this.getCommentairesSuivis({csu_annee: annee, por_id: por_id, csu_code_suivi: code_suivi})
		.pipe(map((response: any) => {
			return response.length? response.shift() : null;
		}));
	}

	private getCommentaireSuiviEndpoint(csu_code_suivi: string) {
		const found: any = this._codesSuivis.find((one: any) => { return one.csu_code_suivi == csu_code_suivi; });
		if (!found) {
			throw `Code suivi inexistant: ${csu_code_suivi}`;
		}
		return found.endpoint;
	}

	public postCommentaireSuivi(commentaire: CommentaireSuivi) {
		const endpoint: string = this.getCommentaireSuiviEndpoint(commentaire.csu_code_suivi);
		return this.http.post<any>(`${environment.api_url}/${endpoint}`, commentaire);
	}

	public putCommentaireSuivi(commentaire: CommentaireSuivi) {
		if (!commentaire.csu_id) {
			return this.postCommentaireSuivi(commentaire);
		}
		else {
			const endpoint: string = this.getCommentaireSuiviEndpoint(commentaire.csu_code_suivi);
			return this.http.put<any>(`${environment.api_url}/${endpoint}/${commentaire.csu_id}`, commentaire);
		}
	}

	public deleteCommentaireSuivi(commentaire: CommentaireSuivi) {
		const endpoint: string = this.getCommentaireSuiviEndpoint(commentaire.csu_code_suivi);
		return this.http.delete<any>(`${environment.api_url}/${endpoint}/${commentaire.csu_id}`);
	}

	public getCommentairesSuivis(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/commentaires_suivis`, tmpParams);
	}

	// public filterCommentairesBFA(commentaires: CommentaireSuivi[], annee: number, por_id: number) {
	// 	return commentaires.filter((one: CommentaireSuivi) => {
	// 		return one.por_id == por_id && one.csu_annee == annee;
	// 	});
	// }

	public exportCAInstance(params: any) {
		let formData: any = clone(params);
		convertDateFieldsToString(formData, false, true);
		formData.filiales = formData.filiales.map((one: Contexte) => {return one.ctx_id});
		formData.partenariats = formData.partenariats.map((one: Contexte) => {return one.ctx_id});
		let customParams: any = prepareQueryParams({}, true);
		return this.http.post<any>(`${environment.api_url}/export_ca_instance`, formData, customParams);
	}

	public getUrlImportCAInstance(validation: boolean = false) {
		let url: string = `${environment.api_url}/import_ca_instance`;
		if (validation) {
			url = `${url}/validation`;
		}
		return url;
	}

	public prepareCorrespondancesSegmentsFromServer(items: CorrespondanceSegmentInstanceExtended[]) {
		items.forEach((one: any) => {
			convertDateFieldsToDate(one, true);
			one.uid = uid();
			one.label_convention_source = this.conventionService.getConventionNicename(one, false, 'ctx_libelle_source', 'con_date_debut_source','con_date_fin_source');
			one.label_convention_cible = this.conventionService.getConventionNicename(one, false);
			convertDateFieldsToDate(one, true);
			this.codesInternesService.formatLabel(one);
			// regroupement des segments par type
			let tmpSegmentsStandards: any = {
				tsc_type: 'S',
				items: []
			};
			let tmpChallenges: any = {
				tsc_type: 'C',
				items: []
			};
			one.segments.forEach((segment: Segment) => {
				if (segment.tsc_type == 'S') {
					tmpSegmentsStandards.items.push(segment);
				}
				else {
					tmpChallenges.items.push(segment);
				}
			});
			one.segmentsGrouped = [];
			if (tmpSegmentsStandards.items.length) {
				one.segmentsGrouped.push(tmpSegmentsStandards);
			}
			if (tmpChallenges.items.length) {
				one.segmentsGrouped.push(tmpChallenges);
			}
		});
	}

	public prepareCorrespondanceSegment(correspondance: CorrespondanceSegmentInstance|CorrespondanceSegmentInstanceExtended): CorrespondanceSegmentInstance {
		let tmp: CorrespondanceSegmentInstance = new CorrespondanceSegmentInstance();
		tmp = assignExisting(tmp, correspondance);
		return tmp;
	}

	public prepareCorrespondancesSegments(items: CorrespondanceSegmentInstance[]|CorrespondanceSegmentInstanceExtended[]) {
		for (let i = 0; i < items.length; i++) {
			items[i] = this.prepareCorrespondanceSegment(items[i]);
		}
	}

	public postCorrespondancesSegments(formData: any) {
		let data: any = clone(formData);
		this.prepareCorrespondancesSegments(data.correspondances);
		return this.http.post<any>(`${environment.api_url}/import_ca_instance/correspondances_segments`, data);
	}

}
