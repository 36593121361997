import { NgModule, Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { AuthService } from '@app/auth/auth.service';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { clone, uid } from '@helpers/utils';

@Component({
	selector: 'profil-dialog',
	templateUrl: './profil-dialog.html'
})
export class ProfilDialogComponent implements OnInit, IEventListener {

	@Input() editMode: boolean;
	@ViewChild('profilForm', { static: true }) profilForm: NgForm;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	title = 'Mon profil';
	pristine: Utilisateur;
	utilisateur: Utilisateur = new Utilisateur();
	canEdit: boolean = true;
	submitted: boolean = false;
	loading: boolean = false;
	disconnecting: boolean = false;

	constructor(
		private utilisateurService: UtilisateurService,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private authService: AuthService,
		private confirmationService: ConfirmationService
	) {

 }

	ngOnInit(): void {
		this.loading = true;
		this.utilisateurService.getCurrentUtilisateur()
		.subscribe(
			(utilisateur: Utilisateur) => {
				this.pristine = clone(utilisateur);
				this.utilisateur = utilisateur;
			}
		).add(() => {this.loading = false;});

		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit(): void {
		this.submitted = true;
		if (this.profilForm.invalid) return;
		let utilisateur = Object.assign(new Utilisateur(), this.utilisateur);
		if (this.pristine.uti_email != utilisateur.uti_email) {
			this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: 'L\'adresse email liée à ce compte va être modifiée.<br>Cette action déconnectera la session en cours.',
				acceptLabel: 'Continuer',
				rejectLabel: 'Annuler',
				accept: () => {
						this.putUtilisateur(utilisateur, true);
					},
				},
			);
		}
		else {
			this.putUtilisateur(utilisateur);
		}

	}

	putUtilisateur(utilisateur: Utilisateur, logout?: boolean) {
		this.loading = true;
		this.utilisateurService.putCurrentUser(utilisateur)
		.subscribe(
			(response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Profil modifié'});
				if (logout) {
					this.eventManager.emit('close-modals', true);
				}
				else {
					this.dialogRef.close({utilisateur: utilisateur, refreshCurrentUtilisateur: true});
				}
			}
		).add(() => {this.loading = false;});
	}

	resetPassword() {
		// console.log('TODO', 'we should use the email value from db, which means having another route, otherwise we could use the email form the form, but it\'s not prudent');
		this.authService.reinitialisationMotDePasse(this.utilisateur.uti_email)
		.subscribe(
			(reponse: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Un email de réinitialisation de mot de passe à été envoyé à l\'adresse de votre compte.'});
			}
		)
	}

	disconnect() {
		this.disconnecting = true;
		this.utilisateurService.disconnect()
		.subscribe()
		.add(() => { this.disconnecting = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		DropdownModule,
		InputTextModule,
		CiviliteSelectorModule,
	],
	exports: [ProfilDialogComponent],
	declarations: [ProfilDialogComponent]
})
export class ProfilDialogModule { }

