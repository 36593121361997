import { NgModule, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { flatMap } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from '@app/primeng-overrides/table';

import { Contexte } from '@app/contexte/contexte.model';
import { PortailService } from '@app/portail/portail.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { EventManagerService } from '@global/event-manager.service';
import { clone, simpleComparison } from '@helpers/utils';

@Component({
	selector: 'utilisateur-abonnements-contact',
	templateUrl: './utilisateur-abonnements-contact.html'
})
export class UtilisateurAbonnementsContactComponent {
	@Input() ctx_id: number;
	@Input() contexte: Contexte;
	@Input() uti_id: number;
	@Input() editMode: boolean;
	@Input() canEdit: boolean;
	@Output() updatedEvent = new EventEmitter<boolean>();

	pristine: any;
	title: string = 'Contact sur le contexte';
	loading: boolean = false;
	submitted: boolean = false;
	typesContact: any[] = [];
	currentAbonnementsContacts: any[] = [];
	abonnements: any[] = [];

	constructor(
		private portailService: PortailService,
		private utilisateurService: UtilisateurService,
		private eventManager: EventManagerService
	){
	}

	ngOnInit() {
		if (!this.contexte) {
			this.loading = true;
			this.portailService.getContexte(this.ctx_id)
			.subscribe(contexte => {
				this.contexte = contexte;
				this.getCurrentAbonnements();
			});
		}
		else {
			this.getCurrentAbonnements();
		}
	}

	getCurrentAbonnements() {
		this.loading = true;
		this.title = `${this.title} ${this.contexte.ctx_libelle}`;
		this.portailService.getContactsDisponibles(this.contexte.por_id)
		.pipe(
			flatMap( response => {
				this.typesContact = response;
				return this.utilisateurService.getAbonnementsContact(this.uti_id, this.contexte.ctx_id);
			})
		)
		.subscribe(
			response => {
				this.currentAbonnementsContacts = response.abonnements_contacts? response.abonnements_contacts : [];
				this.prepare();
			},
			error => { console.log(error);  this.loading = false;},
			() => { this.loading = false;}
		)
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	onChange(event: any) {
		this.updatedEvent.emit(!simpleComparison(this.pristine, this.abonnements));
	}

	prepare() {
		for (let type of this.typesContact) {
			let current = this.currentAbonnementsContacts.find(one => {return one.ctc_id == type.ctc_id;});
			let abonnement = (current)? true : false;
			this.abonnements.push({
				ctc_id: type.ctc_id,
				abonnement: abonnement,
				ctc_libelle: type.ctc_libelle,
				ctc_description: type.ctc_description
			});
		}
		this.pristine = clone(this.abonnements);
	}


	submit() {
		this.loading = true;
		let prepared: any[] = [];
		this.abonnements.forEach(one => {
			prepared.push({ctc_id: one.ctc_id, abonnement: one.abonnement });
		});
		this.utilisateurService.setAbonnementsContact(this.uti_id, this.contexte.ctx_id, prepared)
		.subscribe(
			response => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Modification effectuée'});
				this.pristine = clone (this.abonnements);
				this.updatedEvent.emit(!simpleComparison(this.pristine, this.abonnements));
				this.toggleEditMode(false);
			},
			error => {this.loading = false;},
			() => {this.loading = false;}
		)
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		PanelModule,
		TableModule,
	],
	exports: [UtilisateurAbonnementsContactComponent],
	declarations: [UtilisateurAbonnementsContactComponent]
})
export class UtilisateurAbonnementsContactModule { }

