import { NgModule, Component, forwardRef, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { Adresse } from '@app/societe/adresse.model';
import { Pays } from '@app/pays/pays.model';
import { PaysService } from '@app/pays/pays.service';

@Component({
	selector: 'adresse-form',
	templateUrl: './adresse-form.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AdresseFormComponent),
			multi: true
		},
	],
})
export class AdresseFormComponent implements ControlValueAccessor, OnInit {

	@Input() name: string;
	@Input('value') innerValue: Adresse = new Adresse();
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() submitted: boolean;

	pays: Pays[] = [];

	constructor(private paysService: PaysService) {

	}

	ngOnInit() {
		this.paysService.getCachedList()
		.subscribe(
			response => {
				if (response) {
					this.pays = this.paysService.pays;
				}
			}
		);
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: Adresse) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: Adresse) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		InputTextModule,
		DropdownModule,
	],
	exports: [AdresseFormComponent],
	declarations: [AdresseFormComponent]
})
export class AdresseFormModule { }

