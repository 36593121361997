import { NgModule, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from '@uirouter/core';

import { EqipModule } from '@global/eqip.module';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { Archive } from '@app/document/archive.model';
import { ArchiveInformationModule } from '@app/document/archive/archive-information';

@Component({
	selector: 'archive-view',
	providers: [],
	template: `
		<div class="p-5">
			<button
				type="button"
				pButton
				label="Retourner au portail"
				class="mb-2"
				(click)="returnToPortail()"
			></button>
			<p-card ><archive-information [archive]="archive"></archive-information></p-card>
		</div>
	`,
})
export class ArchiveViewComponent {

	@Input() archive: Archive;

	constructor(private stateService: StateService) {

	}

	ngOnInit() {

	}

	returnToPortail() {
		this.stateService.go('portail');
	}
}


@NgModule({
	imports: [
		CommonModule,
		EqipModule,
		ButtonModule,
		CardModule,
		ArchiveInformationModule
	],
	exports: [ArchiveViewComponent],
	declarations: [ArchiveViewComponent]
})
export class ArchiveViewModule { }
