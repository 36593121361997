import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'casRistourne'})
export class CasRistournePipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(crp_id?: number): string {
		const casRistourne = this.conventionService.casRistournePerformance;
		const cas = casRistourne.find(one => {return one.crp_id == crp_id;});
		if (cas) {
			return  cas.crp_libelle;
		}
		return crp_id? crp_id.toString() : '';
	}
}
