import { Pipe, PipeTransform } from '@angular/core';

import { CAService } from '@app/ca/ca.service';


@Pipe({name: 'codeSuivi'})
export class CodeSuiviPipe implements PipeTransform {

	constructor(private caService: CAService) {}

	transform(csu_code_suivi: string, short: boolean = false): string {
		const found = this.caService.codesSuivi.find((one: any) => {
			return one.csu_code_suivi == csu_code_suivi;
		});
		if (found) {
			if (short) return found.libelle;
			return `Suivi ${found.libelle}`;
		}
		return csu_code_suivi? csu_code_suivi.toString() : '';
	}
}
