import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';
import { TcoType } from '@app/convention/type-convention.model';

@Pipe({name: 'typeConvention'})
export class TypeConventionPipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(tco_type: TcoType, short?: boolean): string {
		const typesConvention = this.conventionService.typesConvention;
		const type = typesConvention.find(one => {return one.tco_type == tco_type;});
		if (type) {
			if (short) {
				let tmp = type.tco_libelle.split(' ').pop();
				if (tmp) {
					return tmp.charAt(0).toUpperCase() + tmp.slice(1);
				}
			}
			return  type.tco_libelle;
		}
		return tco_type.toString();
	}
}
