import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '@app/document/document.service';

@Pipe({name: 'trameTarifs'})
export class TrameTarifsPipe implements PipeTransform {

	constructor(private documentService: DocumentService) {}

	transform(trm_id?: number): string {
		const found = this.documentService.tramesTarifs.find((one: any) => {return one.trm_id == trm_id;});
		if (found) {
			return  found.trm_libelle;
		}
		return trm_id? trm_id.toString() : '';
	}
}
