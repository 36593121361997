import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ModeCalculPrestation } from '@app/convention/mode-calcul-prestation.model';
import { PrestationService } from '@app/convention/prestation.service';

@Injectable({providedIn: 'root'})
@Pipe({name: 'modeCalculPrestation'})
export class ModeCalculPrestationPipe implements PipeTransform {

	constructor(private prestationService: PrestationService) {}

	transform(mcp_code?: string): string {
		const modesCalculPrestation = this.prestationService.modesCalculPrestation;
		if (typeof mcp_code != 'undefined') {
			const mode = modesCalculPrestation.find((one: ModeCalculPrestation) => {return one.mcp_code == mcp_code;});
			if (mode) {
				return mode.mcp_libelle;
			}
			return mcp_code.toString();
		}
		return '';
	}
}
