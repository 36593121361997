import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';

import { NotificationService } from '@app/notification/notification.service';
import { PortailService } from '@app/portail/portail.service';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { clone, simpleDateToString, uid } from '@helpers/utils';

@Component({
	selector: 'demande-changement-admin-dialog',
	templateUrl: './demande-changement-admin-dialog.html',
	providers: []
})
export class DemandeChangementAdminDialogComponent implements OnInit, IEventListener {

	@ViewChild('demandeChangementAdminForm', { static: true }) demandeChangementAdminForm: NgForm;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	submitted: boolean = false;
	loading: boolean = false;
	minDate: Date = new Date();

	noa_description: string;
	noa_json_source = {
		email: null,
		nom: null,
		prenom: null,
		date_application: null
	};

	constructor(
		private notificationService: NotificationService,
		private dialogRef: DynamicDialogRef,
		private portailService: PortailService,
		private eventManager: EventManagerService,
	) {

 }

	ngOnInit(): void {

		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit(): void {
		this.submitted = true;
		if (this.demandeChangementAdminForm.invalid) return;
		let tmpJson = clone(this.noa_json_source);
		tmpJson.date_application = simpleDateToString(tmpJson.date_application, true, true);
		this.loading = true;
		this.notificationService.demandeChangementAdmin(
			this.portailService.currentContexte.ctx_id,
			tmpJson,
			this.noa_description
		)
		.subscribe(
			response => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Demande transmise', detail: 'Nous traiterons votre demande dans les plus brefs délais.'});
				this.dialogRef.close();
			}
		)
		.add(() => { this.loading = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		InputTextModule,
		InputTextareaModule
	],
	exports: [DemandeChangementAdminDialogComponent],
	declarations: [DemandeChangementAdminDialogComponent]
})
export class DemandeChangementAdminDialogModule { }

