import { NgModule, Component, OnInit, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogoService } from './logo.service';

import { ParametreService } from '@app/parametre/parametre.service';

import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'logo-display',
	template: `
	<ng-container *ngIf="!_reload">
		<img [attr.class]="styleClass" [src]="urlThumb" loading="lazy" title="{{title}}" alt="logo {{title}}">
	</ng-container>
	`,
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoDisplayComponent implements OnInit, IEventListener {

	@Input() entity: any;
	@Input() listing: boolean;
	@Input() size: string;
	@Input() placeholderIfNone: boolean = true;
	@Input() placeholderColor: boolean = false;
	@Input() attrPrefix: string|undefined;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	attr: string;
	styleClass: string;
	_reload = false;
	urlThumb: string;
	title: string;

	constructor(
		private eventManager: EventManagerService,
		private logoService: LogoService,
		private parametreService: ParametreService
	) {

	}

	ngOnInit(): void {
		this.getUrlThumb();
	}

	ngOnDestroy(): void {

	}

	reload() {
		setTimeout(() => this._reload = false);
		setTimeout(() => this._reload = true);
	}

	ngOnChanges() {
		this.getUrlThumb();
		if (this.attrPrefix && this.entity[`${this.attrPrefix}_libelle`]) {
			this.title = this.entity[`${this.attrPrefix}_libelle`];
		}
	}

	getUrlThumb() {
		if (typeof this.attrPrefix == 'undefined') {
			this.attrPrefix = (this.entity)? this.logoService.getPrefix(this.entity) : undefined;
		}
		this.styleClass = (this.listing)? 'logo-listing' : 'logo-print';
		this.attr = (this.listing)? `${this.attrPrefix}_logo_listing` : `${this.attrPrefix}_logo_print`;

		let url;
		if (this.entity && this.entity[this.attr]) {
			url = this.logoService.getUrlThumb(this.entity, this.listing, this.size, this.attrPrefix);
		}
		else if (this.placeholderIfNone) {
			url = (this.placeholderColor)? this.parametreService.getParam('logo_placeholder_small') : this.parametreService.getParam('logo_placeholder_small_bw');
		}
		return this.urlThumb = url;
	}
}


@NgModule({
	imports: [
		CommonModule
	],
	exports: [LogoDisplayComponent],
	declarations: [LogoDisplayComponent]
})
export class LogoDisplayModule { }
