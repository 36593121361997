<div class="login-body">
	<div class="login-wrapper">
		<div class="login-panel flex flex-column justify-content-evenly">
			<div class="flex-shrink-0">
				<div class="text-center">
					<div><img class="logo mb-5" src="/assets/instance/logo-iteq-large.png"></div>
					<h1 *ngIf="instanceName && display_instance_name_on_auth_page" class="mt-0">
						{{instanceName}}
					</h1>
				</div>

				<ui-view class="login-form mb-5"></ui-view>
			</div>
			<div class="flex-shrink-0" *ngIf="mailtoHelp">
				<p class="text-center">Besoin d'aide ? <a href="mailto:{{mailtoHelp}}">Cliquez ici</a> pour nous contacter</p>
			</div>

			<div class="flex-shrink-0 text-center" *ngIf="displayAppLogo"  >
				<img class="logo-sm mb-2" src="/assets/images/logo-iteq-large.png">
			</div>

		</div>
		<ng-container *ngIf="frontPage">
			<div class="login-image" [innerHTML]="frontPage"></div>
		</ng-container>
	</div>
</div>
