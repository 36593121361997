<h4 class="text-center">Connexion</h4>
<form class="p-fluid mb-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

	<div class="form-group field">
		<label>Adresse email</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
			<input
				pInputText
				type="text"
				formControlName="email"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.email.errors }"
				placeholder="email@exemple.com"
			>
		</div>
		<div *ngIf="submitted && f.email.errors" class="invalid-feedback p-invalid">
			<small *ngIf="f.email.errors.required" >Email requis</small>
			<small *ngIf="f.email.errors.email">Adresse email invalide</small>
		</div>
	</div>
	<div class="form-group field">
		<label>Mot de passe</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input
				type="password"
				pInputText
				formControlName="password"
				[attr.disabled]="loading? true : null"
				class="form-control"
				[ngClass]="{ 'p-invalid': submitted && f.password.errors }"
			/>
		</div>
		<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
			<small *ngIf="f.password.errors.required" class="p-invalid">Mot de passe requis</small>
		</div>
	</div>
	<div class="form-group field">
		<div class="field-checkbox">
			<p-checkbox
				inputId="remember_me"
				[formControl]="loginForm.controls['remember_me']"
				[binary]="true"
				label="Se souvenir de moi"
				[disabled]="loading"
			></p-checkbox>
		</div>
	</div>

	<p-message severity="error" text="{{error}}" *ngIf="error && !loading"></p-message>


	<button pButton type="submit" class="btn btn-primary" label="Connexion" [disabled]="loading"></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
</form>

<p class="text-center">
	<a uiSref="auth.reset_password">Mot de passe oublié<br>ou 1<sup>ère</sup> connexion ?</a>
</p>
