import { Pipe, PipeTransform } from '@angular/core';

import { Segment } from '@app/convention/segment.model';
import { isObject } from '@helpers/utils';

@Pipe({name: 'segment'})
export class SegmentPipe implements PipeTransform {

	constructor() {}

	transform(segments?: Segment|Segment[], separator: string = ', '): string {
		if (Array.isArray(segments)) {
			let result: string[] = [];
			segments.forEach((one: Segment) => {
				result.push(one.sca_libelle);
			});
			result.sort();
			return result.join(separator);
		}
		else if (typeof segments != 'undefined' && isObject(segments)) {
			return segments.sca_libelle;
		}
		return '';	
		
	}

}
