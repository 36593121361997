export class SaisieCA {
	sca_id: number|null = null;
	con_id: number|null = null;
	fco_id: number|null = null;
	agc_id: number|null = null;
	age_id: number|null = null;
	scc_annee: number|null = null;
	scc_mois: number|null = null;
	scc_montant: number|null = null;
	scc_quantite: number|null = null;

	constructor(
		con_id?: number|null,
		sca_id?: number|null,
		scc_annee?: number|null,
		scc_mois?: number|null,
		fco_id?: number|null,
		agc_id?: number|null,
		age_id?: number|null
	) {
		if (typeof con_id != 'undefined') this.con_id = con_id;
		if (typeof sca_id != 'undefined') this.sca_id = sca_id;
		if (typeof scc_annee != 'undefined') this.scc_annee = scc_annee;
		if (typeof scc_mois != 'undefined') this.scc_mois = scc_mois;
		if (typeof fco_id != 'undefined') this.fco_id = fco_id;
		if (typeof agc_id != 'undefined') this.agc_id = agc_id;
		if (typeof age_id != 'undefined') this.age_id = age_id;
	}
};
