import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { PortailService } from '@app/portail/portail.service';

@Injectable()
export class ApiPortailInterceptor implements HttpInterceptor {

	constructor(private portailService: PortailService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentPortail = this.portailService.currentPortail;
		const isApiUrl = request.url.startsWith(environment.api_url);
		if (currentPortail && isApiUrl) {
			request = request.clone({
				setHeaders: {
					'Iteq-Portail': `${currentPortail.por_id}`
				}
			});
		}
		return next.handle(request);
	}
}


@Injectable()
export class ApiContexteInterceptor implements HttpInterceptor {

	constructor(private portailService: PortailService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentContexte = this.portailService.currentContexte;
		const isApiUrl = request.url.startsWith(environment.api_url);
		if (currentContexte && isApiUrl) {
			request = request.clone({
				setHeaders: {
					'Iteq-Contexte': `${currentContexte.ctx_id}`
				}
			});
		}
		return next.handle(request);
	}
}
