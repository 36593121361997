import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Societe } from '@app/societe/societe.model';
import { Adresse } from '@app/societe/adresse.model';
import { TypeAdresse } from '@app/societe/type-adresse.model';

import { environment } from '@environments/environment';
import { EventManagerService } from '@global/event-manager.service';
import { prepareQueryParams } from '@helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class SocieteService {


	private typesSociete: string[] = ['N/R', 'EURL', 'GIE', 'SA', 'SARL', 'SAS', 'SASU', 'SCA', 'SCS', 'SCI', 'SCOP', 'SNC'];
	private typesAdresse: TypeAdresse[] = [
		{ code: 'siege', label: 'Adresse du siège' },
		{ code: 'facturation', label: 'Adresse de facturation' },
		{ code: 'livraison', label: 'Adresse de livraison' },
		{ code: 'correspondance', label: 'Adresse de correspondance' }
	];

	constructor(private http: HttpClient, private eventManager: EventManagerService) {

	}

	public get getTypesSociete() {
		return [...this.typesSociete];
	}

	public get typesSocieteValueItems() {
		let temp = [];
		for (let one in this.typesSociete) {
			temp.push({label: one, value: one});
		}
		return temp;
	}


	public getTypesAdresse() {
		return [...this.typesAdresse];
	}

	public getDummy() {
		return new Societe();
	}

	public prepareForSubmit(societe: Societe) {
		let prepared = clone(societe);
		convertDateFieldsToString(prepared, false, true);
		return prepared;
	}

	public prepareFromServer(societe: Societe) {
		let prepared = clone(societe);
		convertDateFieldsToDate(prepared);
		return prepared;
	}

	public siretExists(soc_siret: string) {
		return this.http.get<any>(`${environment.api_url}/societes/siret/${soc_siret}`);
	}

	public post(societe: Societe) {
		return this.http.post<any>(`${environment.api_url}/societes`, societe);
	}

	public get(soc_id: number) {
		return this.http.get<any>(`${environment.api_url}/societes/${soc_id}`);
	}

	public getSocietes(params: any, grc_id?: number) {
		if (grc_id) {
			return this.http.get<any>(`${environment.api_url}/groupes_contextes/${grc_id}/societes`, prepareQueryParams(params));
		}
		else {
			return this.http.get<any>(`${environment.api_url}/societes`, prepareQueryParams(params));
		}

	}

	public getUrlAdresse(soc_id: number, grc_id?: number, ctx_id?: number, age_id?: number) {
		let url = '';
		if (!!age_id) {
			url = `${environment.api_url}/contextes/${ctx_id}/agences_filiales/${age_id}/societes/${soc_id}/adresses`
		}
		else if (!!ctx_id) {
			url = `${environment.api_url}/contextes/${ctx_id}/societes/${soc_id}/adresses`;
		}
		else if (!!grc_id) {
			url = `${environment.api_url}/groupes_contextes/${grc_id}/societes/${soc_id}/adresses`;
		}
		return url;
	}

	public getAdresses(soc_id: number, grc_id?: number, ctx_id?: number, age_id?: number) {
		const url = this.getUrlAdresse(soc_id, grc_id, ctx_id, age_id);
		return this.http.get<any>(url);
	}

	public postAdresse(adresse: Adresse, grc_id?: number, ctx_id?: number, age_id?: number) {
		const url = this.getUrlAdresse(adresse.soc_id, grc_id, ctx_id, age_id);
		return this.http.post<any>(url, adresse);
	}

	public putAdresse(adresse: Adresse, grc_id?: number, ctx_id?: number, age_id?: number) {
		const url = this.getUrlAdresse(adresse.soc_id, grc_id, ctx_id, age_id);
		return this.http.put<any>(`${url}/${adresse.adr_id}`, adresse);
	}

	public deleteAdresse(adresse: Adresse, grc_id?: number, ctx_id?: number, age_id?: number) {
		const url = this.getUrlAdresse(adresse.soc_id, grc_id, ctx_id, age_id);
		return this.http.delete<any>(`${url}/${adresse.adr_id}`);
	}


}
