import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Pays } from './pays.model';
import { environment } from '@environments/environment';

import { isNumeric, isObject } from '@helpers/utils';
import { prepareQueryParams } from '@helpers/prepare-query-params';
import { PortailParam, PortailService } from '@app/portail/portail.service';

@Injectable({ providedIn: 'root' })
export class PaysService {

	private _pays: Pays[] = [];
	private paysPerimetres: Pays[] = [];
	private paysPerimetresParPortail: Pays[][] = [];

	constructor(
		private http: HttpClient,
		private portailService: PortailService
	) {

	}

	ngOnDestroy() {

	}

	public get pays(): Pays[] {
		return [...this._pays];
	}

	public get paysAsItems() {
		let temp: any[] = [];
		for (let one of this._pays) {
			temp.push({label: one['pay_nom'], value: one['pay_id'], code: one['pay_code']});
		}
		return temp;
	}

	public getPays() {
		return this.http.get<any>(`${environment.api_url}/pays`)
		.pipe(map(response => {
			response.pays.sort(this.sort);
			this._pays = response.pays;
			return response;
		}));
	}

	sort(a: Pays, b: Pays) {
		if (a.pay_nom < b.pay_nom) return -1;
		if (a.pay_nom > b.pay_nom) return 1;
		return 0;
	}

	public getPaysPerimetres(portail?: PortailParam) {
		let tmpParams: any = this.portailService.maybeAddPorIdToObject({}, portail);
		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(`${environment.api_url}/pays_perimetres`, tmpParams)
		.pipe(map((response: any) => {
			if (portail) {
				const por_id: number = this.portailService.getPortailId(portail);
				this.paysPerimetresParPortail[por_id] = response.pays;
			}
			else {
				this.paysPerimetres = response.pays;
			}
			return response;
		}));
	}

	public getCachedList(refresh?: boolean) {
		if (!refresh && !!this._pays.length) {
			return new Observable<any>((sub: any) => { sub.next(this._pays); sub.complete(); });
		}
		else {
			return this.getPays().pipe(map(response => { return response.pays; }));
		}
	}

	public getCachedListPaysPerimetres(refresh?: boolean, portail?: PortailParam) {
		if (!refresh) {
			if (portail) {
				const por_id: number = this.portailService.getPortailId(portail);
				if (this.paysPerimetresParPortail[por_id] && this.paysPerimetresParPortail[por_id].length){
					return new Observable<any>((sub: any) => { sub.next(this.paysPerimetresParPortail[por_id]); sub.complete(); });
				}
			}
			else if (this.paysPerimetres.length) {
				return new Observable<any>((sub: any) => { sub.next(this.paysPerimetres); sub.complete(); });
			}
		}
		return this.getPaysPerimetres(portail).pipe(map((response: any) => { return response.pays; }));
	}

	public paysArrayToPayIdArray(paysArray: (Pays|number)[]) {
		return paysArray.map(
			(one: Pays|number) => {
				return (typeof one == 'number')? one : one.pay_id;
			}
		);
	}

}
