import { Pipe, PipeTransform } from '@angular/core';

import { TypeNotificationAdmin } from '@app/notification/type-notification-admin.model';
import { NotificationService } from '@app/notification/notification.service';

@Pipe({name: 'typeNotificationAdmin'})
export class TypeNotificationAdminPipe implements PipeTransform {

	constructor(private notificationService: NotificationService) {}

	transform(input?: string|number): Promise<string> {
		return this.notificationService.getTypesNotificationAdmin()
		.toPromise().then(
			typesNotificationAdmin => {
				const notif = typesNotificationAdmin.find((one: TypeNotificationAdmin) => {
					if (typeof input == 'string') return one.tna_code == input;
					else return one.tna_id == input;
				});
				if (notif) return  notif.tna_libelle;
				return input? input.toString() : '';
			},
			err => {
				return input? input.toString() : '';
			}
		)
	}
}
