import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

import { DestinataireRelance } from '@app/relance/destinataire-relance.model';
import { ModeleRelance } from '@app/relance/modele-relance.model';
import { PortailService } from '@app/portail/portail.service';
import { Relance } from '@app/relance/relance.model';
import { RequeteContactsRelance } from '@app/relance/requete-contacts-relance.model';

import { environment } from '@environments/environment';
import { prepareQueryParams } from '@helpers/prepare-query-params';

import { clone, convertDateFieldsToDate, arrayOfMonths, simpleUTCDateTimeToString } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class RelanceService {

	private requetesContactsRelanceSubject = new BehaviorSubject<RequeteContactsRelance[]>([]);


	constructor(
		private http: HttpClient,
		private portailService: PortailService,
		private datePipe: DatePipe
	) {

	}

	public sortRequetesContacts(a: RequeteContactsRelance, b: RequeteContactsRelance) {
		if (a.rcr_categorie < b.rcr_categorie) return -1;
		if (a.rcr_categorie > b.rcr_categorie) return 1;
		if (a.rcr_libelle < b.rcr_libelle) return -1;
		if (a.rcr_libelle > b.rcr_libelle) return 1;
		return 0;
	}

	public get requetesContactsRelanceValue(): RequeteContactsRelance[] {
		return this.requetesContactsRelanceSubject.getValue();
	}

	public getDummyModeleRelance() {
		return new ModeleRelance();
	}


	public prepareModeleRelanceFromServer(modele: ModeleRelance) {
		let tmp = clone(modele);
		if (typeof tmp.requetes_contacts == 'undefined') {
			tmp.requetes_contacts = [];
		}
		return tmp;
	}

	public prepareModelesRelanceFromServer(modeles: ModeleRelance[]) {
		for (let i = 0; i < modeles.length ; i++) {
			modeles[i] = this.prepareModeleRelanceFromServer(modeles[i]);
		}
	}

	public prepareModeleRelance(modele: ModeleRelance) {
		let tmp = clone(modele);
		tmp.requetes_contacts = tmp.requetes_contacts.map((one: RequeteContactsRelance) => { return one.rcr_id; } )
		return tmp;
	}

	public getModeleRelance(mre_id: number) {
		return this.http.get<any>(`${environment.api_url}/modeles_relances_emails/${mre_id}`)
			.pipe(map(response => {
				return this.prepareModeleRelanceFromServer(response);
			}));
	}

	public getModelesRelance(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/modeles_relances_emails`, tmpParams)
		.pipe(map(
			response => {
				if (response.modeles_relances_emails) {
					this.prepareModelesRelanceFromServer(response.modeles_relances_emails);
				}
				return response;
			}
		));
	}

	public postModeleRelance(modele: ModeleRelance) {
		let prepared = this.prepareModeleRelance(modele);
		return this.http.post<any>(`${environment.api_url}/modeles_relances_emails`, prepared);
	}

	public putModeleRelance(modele: ModeleRelance) {
		let prepared = this.prepareModeleRelance(modele);
		return this.http.put<any>(`${environment.api_url}/modeles_relances_emails/${modele.mre_id}`, prepared);
	}

	public deleteModeleRelance(mre_id: number) {
		return this.http.delete<any>(`${environment.api_url}/modeles_relances_emails/${mre_id}`)
	}


	public prepareRelanceFromServer(relance: Relance) {
		let tmp = clone(relance);
		convertDateFieldsToDate(tmp, true);
		if (typeof tmp.requetes_contacts == 'undefined') {
			tmp.requetes_contacts = [];
		}
		return tmp;
	}

	public preparesRelanceFromServer(relances: Relance[]) {
		for (let i = 0; i < relances.length ; i++) {
			relances[i] = this.prepareRelanceFromServer(relances[i]);
		}
	}

	public prepareRelance(relance: Relance) {
		let tmp = clone(relance);
		tmp.destinataires = tmp.destinataires.map((one: DestinataireRelance) => { return one.dre_email; } );
		tmp.ree_date_planification = simpleUTCDateTimeToString(tmp.ree_date_planification, false);
		return tmp;
	}

	public getRelance(ree_id: number) {
		return this.http.get<any>(`${environment.api_url}/relances_emails/${ree_id}`)
			.pipe(map(response => {
				return this.prepareRelanceFromServer(response);
			}));
	}

	public getRelances(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/relances_emails`, tmpParams)
		.pipe(map(
			response => {
				if (response.relances_emails) {
					this.preparesRelanceFromServer(response.relances_emails);
				}
				return response;
			}
		));
	}

	public createRelanceFromModele(mre_id: number) {
		return this.http.post<any>(`${environment.api_url}/modeles_relances_emails/${mre_id}/relances_emails`, null);
	}

	public putRelance(relance: Relance) {
		let prepared = this.prepareRelance(relance);
		return this.http.put<any>(`${environment.api_url}/relances_emails/${relance.ree_id}`, prepared);
	}

	public deleteRelance(ree_id: number) {
		return this.http.delete<any>(`${environment.api_url}/relances_emails/${ree_id}`)
	}

	public getRequeteContactsRelance(rcr_id: number) {
		return this.http.get<any>(`${environment.api_url}/requetes_contacts_relances/${rcr_id}`);
	}

	public getRequetesContactsRelance(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/requetes_contacts_relances`, tmpParams);
	}

	public getCachedListRequetesContactsRelance(refresh?: boolean) {
		if (!refresh && !!this.requetesContactsRelanceValue.length) {
			return new Observable<any>(subscriber=> { subscriber.next(this.requetesContactsRelanceValue); subscriber.complete(); });
		}
		else {
			return this.getRequetesContactsRelance(null).pipe(map(response => {
				this.requetesContactsRelanceSubject.next(response.requetes_contacts_relances);
				return response.requetes_contacts_relances;
			}));
		}
	}

	public postRequeteContactsRelance(requete: RequeteContactsRelance) {
		return this.http.post<any>(`${environment.api_url}/requetes_contacts_relances`, requete);
	}

	public putRequeteContactsRelance(requete: RequeteContactsRelance) {
		return this.http.put<any>(`${environment.api_url}/requetes_contacts_relances/${requete.rcr_id}`, requete);
	}

	public deleteRequeteContactsRelance(rcr_id: number) {
		return this.http.delete<any>(`${environment.api_url}/requetes_contacts_relances/${rcr_id}`);
	}

	public runRequeteContactRelance(rcr_id: number) {
		return this.http.get<any>(`${environment.api_url}/requetes_contacts_relances/${rcr_id}/execution`);
	}

}
