import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { EtatDemandeReglement } from '@app/compta/etat-demande-reglement.model';

@Pipe({name: 'etatDemandeReglement'})
export class EtatDemandeReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(edr_code?: string): string {
		const etatsDemandeReglement = this.comptaService.etatsDemandeReglement;
		const etat = etatsDemandeReglement.find((one: EtatDemandeReglement) => {return one.edr_code == edr_code;});
		if (etat) {
			return  etat.edr_libelle;
		}
		return edr_code? edr_code.toString() : '';
	}
}
