import { NgModule } from '@angular/core';

import { DebounceDirective } from '@helpers/debounce.directive';
import { GZInputNumberFormatterDirective } from '@helpers/gz-input-number-formatter.directive';
import { ifHasRightDirective } from '@app/portail/if-has-right.directive';
import { IteqExportDirective } from '@helpers/iteq-export.directive';
import { ShowFormulaireNotifDirective } from '@app/notification/show-formulaire-notif.directive';

import { ActionFichierPipe } from '@app/document/action-fichier.pipe';
import { ArticleComptablePipe } from '@app/compta/article-comptable.pipe';
import { CasRistournePipe } from '@app/ristourne/cas-ristourne.pipe';
import { CategorieFichierFournisseurPipe } from '@app/document/categorie-fichier-fournisseur.pipe';
import { CodeInternePipe } from '@helpers/code-interne.pipe';
import { CodeSuiviPipe } from '@app/ca/code-suivi.pipe';
import { CommissionActivitePipe } from '@app/marque/commission-activite.pipe';
import { EtatConventionPipe } from '@app/convention/etat-convention.pipe';
import { EtatDemandeActionFichierPipe } from '@app/document/etat-demande-action-fichier.pipe';
import { EtatConventionDemandeReglementPipe } from '@app/compta/etat-convention-demande-reglement.pipe';
import { EtatDemandeReglementPipe } from '@app/compta/etat-demande-reglement.pipe';
import { EtatDistributionReglementPipe } from '@app/compta/etat-distribution-reglement.pipe';
import { EtatFacturePipe } from '@app/compta/etat-facture.pipe';
import { EtatSignatairePipe } from '@app/signature/etat-signataire.pipe';
import { EtatSignaturePipe } from '@app/signature/etat-signature.pipe';
import { FormatSizePipe } from '@helpers/format-size.pipe';
import { GroupeConditionsPipe } from '@app/convention/groupe-conditions.pipe';
import { InternalLinkPipe } from '@helpers/internal-link.pipe';
import { ModeCalculPipe } from '@app/ristourne/mode-calcul.pipe';
import { ModeCalculPrestationPipe } from '@app/convention/mode-calcul-prestation.pipe';
import { ModeProrataPrestationPipe } from '@app/convention/mode-prorata-prestation.pipe';
import { ModeSaisieCAPipe } from '@app/convention/mode-saisie-ca.pipe';
import { MonthPipe } from '@helpers/month.pipe';
import { OrigineFacturePipe } from '@app/compta/origine-facture.pipe';
import { PaysPipe } from '@app/pays/pays.pipe';
import { PeriodicitePipe } from '@helpers/periodicite.pipe';
import { PortailPipe } from '@app/portail/portail.pipe';
import { RequeteContactsRelancesPipe } from '@app/relance/requete-contacts-relance.pipe';
import { SegmentPipe } from '@app/convention/segment.pipe';
import { SourceDemandeReglementPipe } from '@app/compta/source-demande-reglement.pipe';
import { SwitchMultiCasePipe } from '@helpers/switch-multi-case.pipe';
import { TrameTarifsPipe } from '@app/document/trame-tarifs.pipe';
import { TypeAgencePipe } from '@app/agence/type-agence.pipe';
import { TypeConventionPipe } from '@app/convention/type-convention.pipe';
import { TypeNotificationAdminPipe } from '@app/notification/type-notification-admin.pipe';
import { TypePerformanceRistournePipe } from '@app/ristourne/type-performance-ristourne.pipe';
import { TypePrestationPipe } from '@app/convention/type-prestation.pipe';
import { TypeReglementPipe } from '@app/compta/type-reglement.pipe';
import { TypeSegmentPipe } from '@app/convention/type-segment.pipe';
import { TypeSignaturePipe } from '@app/signature/type-signature.pipe';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';
import { VarDirective } from '@helpers/ng-var.directive';

import { FileIconModule } from '@app/document/file-icon';
import { NumberDisplayModule } from '@app/components/number-display';

@NgModule({
	imports: [
		FileIconModule,
		NumberDisplayModule,
	],
	providers: [],
	declarations: [
		ActionFichierPipe,
		ArticleComptablePipe,
		CasRistournePipe,
		CategorieFichierFournisseurPipe,
		CodeInternePipe,
		CodeSuiviPipe,
		CommissionActivitePipe,
		DebounceDirective,
		EtatConventionPipe,
		EtatDemandeActionFichierPipe,
		EtatDemandeReglementPipe,
		EtatDistributionReglementPipe,
		EtatFacturePipe,
		EtatConventionDemandeReglementPipe,
		EtatSignatairePipe,
		EtatSignaturePipe,
		FormatSizePipe,
		GroupeConditionsPipe,
		GZInputNumberFormatterDirective,
		ifHasRightDirective,
		InternalLinkPipe,
		IteqExportDirective,
		ModeCalculPipe,
		ModeCalculPrestationPipe,
		ModeProrataPrestationPipe,
		ModeSaisieCAPipe,
		MonthPipe,
		OrigineFacturePipe,
		PaysPipe,
		PeriodicitePipe,
		PortailPipe,
		RequeteContactsRelancesPipe,
		SegmentPipe,
		ShowFormulaireNotifDirective,
		SourceDemandeReglementPipe,
		SwitchMultiCasePipe,
		TrameTarifsPipe,
		TypeAgencePipe,
		TypeConventionPipe,
		TypeNotificationAdminPipe,
		TypePerformanceRistournePipe,
		TypePrestationPipe,
		TypeReglementPipe,
		TypeSegmentPipe,
		TypeSignaturePipe,
		UtilisateurPipe,
		VarDirective,
	],
	entryComponents: [],
	exports: [
		ActionFichierPipe,
		ArticleComptablePipe,
		CasRistournePipe,
		CategorieFichierFournisseurPipe,
		CodeInternePipe,
		CodeSuiviPipe,
		CommissionActivitePipe,
		DebounceDirective,
		EtatConventionPipe,
		EtatDemandeActionFichierPipe,
		EtatDemandeReglementPipe,
		EtatDistributionReglementPipe,
		EtatFacturePipe,
		EtatConventionDemandeReglementPipe,
		EtatSignatairePipe,
		EtatSignaturePipe,
		FileIconModule,
		FormatSizePipe,
		GroupeConditionsPipe,
		GZInputNumberFormatterDirective,
		ifHasRightDirective,
		InternalLinkPipe,
		IteqExportDirective,
		ModeCalculPipe,
		ModeCalculPrestationPipe,
		ModeProrataPrestationPipe,
		ModeSaisieCAPipe,
		MonthPipe,
		NumberDisplayModule,
		OrigineFacturePipe,
		PaysPipe,
		PeriodicitePipe,
		PortailPipe,
		RequeteContactsRelancesPipe,
		SegmentPipe,
		ShowFormulaireNotifDirective,
		SourceDemandeReglementPipe,
		SwitchMultiCasePipe,
		TrameTarifsPipe,
		TypeAgencePipe,
		TypeConventionPipe,
		TypeNotificationAdminPipe,
		TypePerformanceRistournePipe,
		TypePrestationPipe,
		TypeReglementPipe,
		TypeSegmentPipe,
		TypeSignaturePipe,
		UtilisateurPipe,
		VarDirective,
	]
})
export class EqipModule { }
