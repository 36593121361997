import { Component } from '@angular/core';

import { MessageService } from 'primeng/api';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { PrimeNGConfig } from 'primeng/api';

import { uid } from '@helpers/utils';
import { localeFR } from '@helpers/locale-fr';

@Component({
	selector: 'app-root',
	template: `
		<p-toast [preventOpenDuplicates]="true"></p-toast>
		<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="col-12 md:col-6" appendTo="body"></p-confirmDialog>
		<ui-view></ui-view>
		<p-scrollTop></p-scrollTop>
	`,
	providers: [ MessageService ]
})
export class AppComponent implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private messageService: MessageService,
		private eventManager: EventManagerService,
		private primeNGConfig: PrimeNGConfig
	) {
		this.eventManager.registerEvent('toast', this, (args: any) => {
				this.toast(args);
			}
		);
	}

	ngOnInit() {
		this.primeNGConfig.setTranslation(localeFR);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
	}

	toast(toast: any) {
		if (toast instanceof Array) {
			toast.forEach(one => this.prepareToast(one));
		}
		else {
			this.prepareToast(toast);
		}
		this.messageService.add(toast);
	}

	prepareToast(toast: any) {
		toast.severity = toast.severity || 'info';
		if (toast.severity == 'error') toast.life = 10000;
	}

}
