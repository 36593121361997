import { HttpParams } from '@angular/common/http';

export function prepareQueryParams(rawParams?: any, forDownload?: boolean) {

	let params: HttpParams = new HttpParams();
	let options: {[key: string]: any} = {};

	function addIfNotNull(key: string, value: any) {
		if (typeof value != 'undefined' && value != null) {
			if (Array.isArray(value) && value.length) {
				if (value.length === 1) {
					params = params.append(key, value[0]);
				}
				else {
					let index: number = 0;
					for (let i = 0; i < value.length; i++) {
						if (typeof value[i] != 'undefined' && value[i] != null) {
							addIfNotNull(`${key}[${index}]`, value[i]);
							index++;
						}
					}
				}
			}
			else {
				params = params.append(key, value);
			}
		}
	}

	if (rawParams) {
		for (let prop in rawParams) {
			switch (prop) {
				case 'multiSortMeta':
				case 'globalFilter':
				case 'globalFilterFields':
					continue;
				case 'first':
					addIfNotNull('offset', rawParams[prop]);
					break;
				case 'rows':
					addIfNotNull('limit', rawParams[prop]);
					break;
				case 'sortField':
					addIfNotNull('order_by', rawParams[prop]);
					break;
				case 'sortOrder':
					let value: any;
					if (typeof rawParams[prop] != 'undefined') {
						value = (rawParams[prop] == 1)? 'ASC' : 'DESC';
					}
					addIfNotNull('order', value);
					break;

				case 'filters':
					for (let field in rawParams.filters) {
						if (field === 'global') continue;
						if (typeof rawParams.filters[field].value != 'undefined' && rawParams.filters[field].value != null) {
							addIfNotNull(field, rawParams.filters[field].value)
						}
					}
					break;
				default:
					addIfNotNull(prop, rawParams[prop]);
					break;
			}
		}
	}

	options.params = params;

	if (forDownload) {
		options['responseType'] = 'blob';
		options['observe'] = 'response';
	}
	return options;
}


export function prepareQueryParamsForDownload(rawParams?: any) {
	return prepareQueryParams(rawParams, true);
}
