import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { EtatConventionDemandeReglement } from '@app/compta/etat-convention-demande-reglement.model';

@Pipe({name: 'etatConventionDemandeReglement'})
export class EtatConventionDemandeReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(ecd_code?: string): string {
		const etatsConventionDemandeReglement = this.comptaService.etatsConventionDemandeReglement;
		const etat = etatsConventionDemandeReglement.find((one: EtatConventionDemandeReglement) => {return one.ecd_code == ecd_code;});
		if (etat) {
			return  etat.ecd_libelle;
		}
		return ecd_code? ecd_code.toString() : '';
	}
}
