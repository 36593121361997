import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { UIRouter, PathNode, Transition } from '@uirouter/core';

import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { PortailService } from '@app/portail/portail.service';
import { ParametreService } from '@app/parametre/parametre.service';

import { uid } from '@helpers/utils';

// Add `breadcrumb` to typescript definitions for StateObject and StateDeclaration
declare module "@uirouter/core/lib/state/stateObject" {
	interface StateObject {
		breadcrumb?: (trans: Transition) => string;
	}
}

declare module "@uirouter/core/lib/state/interface" {
	interface StateDeclaration {
		breadcrumb?: (trans: Transition) => string;
	}
}

interface Crumb {
	state: string;
	label: string|undefined;
}

@Component({
	selector: 'app-breadcrumbs',
	template: `
		<div class="p-breadcrumb p-component flex justify-content-between">
			<ul class="breadcrumbs">
				<ng-template ngFor let-item let-start="first" let-end="last" [ngForOf]="crumbs">
					<li [ngClass]="{'p-disabled':item.disabled}">
						<a
							*ngIf="item.state && !item.abstract"
							[uiSref]="item.state"
							[queryParams]="item.queryParams"
							class="p-menuitem-link"
							[attr.target]="item.target"
							[attr.title]="item.title"
							[attr.id]="item.id"
							[attr.tabindex]="item.disabled ? null : '0'"
							[fragment]="item.fragment"
							[queryParamsHandling]="item.queryParamsHandling"
							[preserveFragment]="item.preserveFragment"
							[skipLocationChange]="item.skipLocationChange"
							[replaceUrl]="item.replaceUrl"
							[state]="item.state"
							[uiOptions]="{ reload: '.' }"
						>
							<span *ngIf="start" class="p-menuitem-icon pi pi-home" ></span>
							<ng-container *ngIf="item.label && !start">
								<span *ngIf="item.escape !== false; else htmlRouteLabel" class="p-menuitem-text">{{item.label}}</span>
								<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							</ng-container>
						</a>
						<span *ngIf="item.state && item.abstract">
							<span *ngIf="start" class="p-menuitem-icon pi pi-home" ></span>
							<ng-container *ngIf="item.label && !start">
								<span *ngIf="item.escape !== false; else htmlRouteLabel" class="p-menuitem-text">{{item.label}}</span>
								<ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="item.label"></span></ng-template>
							</ng-container>
						</span>
					</li>
					<li class="p-breadcrumb-chevron pi pi-chevron-right" *ngIf="!end"></li>
				</ng-template>
			</ul>

			<button
				type="button"
				pButton
				icon="pi pi-bell"
				label="Demande changement administrateur"
				class="p-button-rounded p-button-danger p-button-outlined p-button-xs pr-2 pl-2"
				showFormulaireNotif="changement_admin"
				*ngIf="onDashboard && !onPortailEqip"
			></button>
		</div>
	`,
	styles: [`
		.p-breadcrumb ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		align-items: center;
				flex-wrap: wrap;
		}

		.p-breadcrumb .p-menuitem-text {
				line-height: 1;
		}

		.p-breadcrumb .p-menuitem-link {
				text-decoration: none;
		}
	`]
})
export class AppBreadcrumbsComponent implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private unsub: any;
	public crumbs: Crumb[] = [];

	onDashboard: boolean;
	onPortailEqip: boolean;

	baseTitle: string;

	constructor(
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private portailService: PortailService,
		private titleService: Title,
		private uiRouter: UIRouter,
	) {
		this.baseTitle = this.parametreService.getParam('instance_name', 'ITEQ');
		this.updateCrumbs(uiRouter.globals.successfulTransitions.peekTail())
		this.unsub = uiRouter.transitionService.onSuccess({}, trans => this.updateCrumbs(trans));
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.resetTitle();
			}
		);
	}

	ngOnDestroy() {
		this.unsub();
		this.eventManager.unregisterEvent('logout', this);
	}

	private updateCrumbs(trans: Transition) {
		this.crumbs = trans.treeChanges('to')
		.filter(node => node.state.breadcrumb)
		.map(node => {
			let state: string = (typeof node.state.redirectTo == 'string')? node.state.redirectTo : node.state.name;
			return {
				state: state,
				abstract: node.state.abstract,
				label: node.state.breadcrumb?.(trans)
			}
		});
		let crumbs = this.crumbs
			.filter((one: Crumb) => { return one.label != 'Dashboard' })
			.map((one: Crumb) => { return one.label; });
		let title = [...crumbs, this.baseTitle].join(' | ');
		this.titleService.setTitle(title);

		this.onDashboard = (this.crumbs.length == 1);
		this.onPortailEqip = this.portailService.onPortailEqip();
	}

	private resetTitle() {
		this.titleService.setTitle(this.baseTitle);
	}

}
