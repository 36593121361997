import { StateService, Transition, Resolvable } from '@uirouter/core';

import { AppMenuService } from '@global/app-menu.service';
import { CodesInternesService } from '@global/codes-internes.service';
import { ComptaService } from '@app/compta/compta.service';
import { ConventionService } from '@app/convention/convention.service';
import { DocumentService } from '@app/document/document.service';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { MarqueService } from '@app/marque/marque.service';
import { NotificationService } from '@app/notification/notification.service';
import { PaysService } from '@app/pays/pays.service';
import { PortailService } from '@app/portail/portail.service';
import { PrestationService } from '@app/convention/prestation.service';
import { PublicationService } from '@app/publication/publication.service';

import { isEmpty } from '@helpers/utils';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';

// empty the sidebar menu
export const resetMenuResolve = {
	token: 'resetMenu',
	deps: [AppMenuService, Transition],
	resolveFn: (appMenuService: AppMenuService, transition: Transition) => {
		// console.log('resetMenuResolve');
		appMenuService.setMenuItems([]);
		return true;
	}
}

export const getMeResolve = {
	token: 'me',
	deps: [StateService, UtilisateurService],
	resolveFn: (stateService: StateService, utilisateurService: UtilisateurService) => {
		// console.log('getMeResolve');
		return utilisateurService.getCurrentUtilisateur().toPromise()
		.then(
			(me: any) => {
				return me;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
}

// function to redirect the user (or not) on the relevant portail if there's just one accessible portail
export const portailsAccessiblesResolve = {
	token: 'portailsAccessibles',
	deps: [StateService, PortailService, 'me', 'portails'],
	resolveFn: (stateService: StateService, portailService: PortailService, me: Utilisateur, portails: any) => {
		// console.log('portailsAccessiblesResolve');
		const portailsAccessibles = portailService.portailsAccessibles;
		// if there's just the one portal, go there immediately
		if (portailsAccessibles.length === 1 ) {
			// console.log('Single portal', portailsAccessibles[0].por_code, 'go there immediately');
			portailService.setPortail(portailsAccessibles[0]);
			return stateService.go('portail-' + portailsAccessibles[0].por_code);
		}
		else {
			// console.log('Several or no portals', 'present the selection');
			return Promise.resolve(portailsAccessibles);
		}
	}
};

// function to redirect the user (or not) on the relevant contexte if there's just one accessible contexte
export const contextesAccessiblesResolve = {
	token: 'contextesAccessibles',
	deps: [StateService, PortailService, 'resetMenu'],
	resolveFn: (stateService: StateService, portailService: PortailService, transition: Transition, resetMenu: Resolvable) => {
		// console.log('contextesAccessiblesResolve');
		return portailService.getContextesAccessibles().toPromise().then(
			(response: any) => {
				// if there's no contexte, then we shouldn't be here. logout is the most simple way to go
				const contextesAccessibles = portailService.contextesAccessibles;
				if (contextesAccessibles.length === 0) {
					// console.log('no contexte to show', 'we should logout');
					stateService.go('portail');
				}
				// if there's just the one contexte, go there immediately
				else if (contextesAccessibles.length === 1 ) {
					console.log('single contexte', contextesAccessibles[0].ctx_id, 'go there immediately');
					portailService.setContexte(contextesAccessibles[0]);
					stateService.go(portailService.getPortailRootState(), {ctx_root_id: contextesAccessibles[0].ctx_id});
				}

				console.log('several contextes', 'present the selection', contextesAccessibles);
				// return Promise.resolve(contextesAccessibles);
				return contextesAccessibles;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
}

// function to determine where the user should be redirected
// after having been redirected to the login form
export const returnToResolve = {
	token: 'returnTo',
	deps: [StateService, Transition],
	resolveFn: (stateService: StateService, transition: Transition) => {
		// console.log('returnToResolve', transition.to().name, transition.from().name, transition.redirectedFrom());

		// The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate a state)
		if (transition.redirectedFrom() != null) {
			// Return to the original attempted target state (e.g., state.name)
			return transition.redirectedFrom().targetState();
		}

		// The user was not redirected to the login state; they directly activated the login state somehow.
		// Return them to the state they came from.
		if (transition.from().name !== '' && isEmpty(transition.options().custom)) {
			return stateService.target(transition.from(), transition.params('from'));
		}

		// If the fromState's name is empty, then this was the initial transition. Just return them to the home state
		return stateService.target('/portail');
	}
}

export const resetCurrentPortailResolve = {
	token: 'resetCurrentPortail',
	deps: [PortailService],
	resolveFn: (portailService: PortailService) => {
		// console.log('resetCurrentPortailResolve');
		portailService.resetCurrentPortail();
	}
};

export const resetContexteResolve = {
	token: 'resetContexte',
	deps: [PortailService],
	resolveFn: (portailService: PortailService) => {
		// console.log('resetContexteResolve');
		portailService.resetContexteAccessibles();
	}
};

export const getPortailsResolve = {
	token: 'portails',
	deps: [StateService, PortailService],
	resolveFn: (stateService: StateService, portailService: PortailService) => {
		// console.log('getPortailsResolve');
		return portailService.getPortails().toPromise()
		.then(
			(portails: any) => {
				return portails;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
};

export const getCommissionsActivitesResolve = {
	token: 'commissions',
	deps: [MarqueService],
	resolveFn: (marqueService: MarqueService) => {
		// console.log('getCommissionsActivitesResolve');
		return marqueService.getCommissionsActivites().toPromise();
	}
}

export const getTypesNotificationAdminResolve = {
	token: 'typesNotifications',
	deps: [NotificationService],
	resolveFn: (notificationService: NotificationService) => {
		// console.log('getTypesNotificationAdminResolve');
		return notificationService.getTypesNotificationAdmin().toPromise();
	}
}

export const getCategoriesFichierFournisseurResolve = {
	token: 'categoriesFichierFournisseur',
	deps: [StateService, DocumentService],
	resolveFn: (stateService: StateService, documentService: DocumentService) => {
		// console.log('getCategoriesFichierFournisseurResolve');
		return documentService.getCategoriesFichierFournisseur({order_by:'cff_libelle', order:'ASC'}).toPromise()
		.then(
			(categories: any) => {
				return categories;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
}

export const prepareMesCategoriesFichierFournisseurResolve = {
	token: 'mesCategoriesFichierFournisseur',
	deps: [DocumentService, 'categoriesFichierFournisseur'],
	resolveFn: (documentService: DocumentService, categoriesFichierFournisseur: Resolvable) => {
		// console.log('prepareMesCategoriesFichierFournisseurResolve');
		return documentService.prepareMesCategoriesFichierFournisseurEcriture().toPromise();
	}
}

export const getTypesPrestation = {
	token: 'typesPrestation',
	deps: [PrestationService],
	resolveFn: (prestationService: PrestationService) => {
		// console.log('getTypesPrestation');
		return prestationService.getCachedListTypesPrestation().toPromise();
	}
}

export const getArticlesComptables = {
	token: 'articlesComptables',
	deps: [ComptaService],
	resolveFn: (comptaService: ComptaService) => {
		// console.log('getArticlesComptables');
		return comptaService.getCachedListArticlesComptables().toPromise();
	}
}

export const getEmoticones = {
	token: 'emoticones',
	deps: [PublicationService],
	resolveFn: (publicationService: PublicationService) => {
		// console.log('getEmoticones');
		return publicationService.getEmoticones().toPromise();
	}
}

export const getListesGeneriquesResolve = {
	token: 'listesGeneriques',
	deps: [StateService, ListesGeneriquesService],
	resolveFn: (stateService: StateService, listesGeneriquesService: ListesGeneriquesService) => {
		// console.log('getListesGeneriquesResolve');
		return listesGeneriquesService.getListesGeneriques().toPromise()
		.then(
			(listesGeneriques: any) => {
				return listesGeneriques;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
}

export const getPaysResolve = {
	token: 'pays',
	deps: [StateService, PaysService],
	resolveFn: (stateService: StateService, paysService: PaysService) => {
		// console.log('getPaysResolve');
		return paysService.getCachedList().toPromise()
		.then(
			(pays: any) => {
				return pays;
			},
			(error: any) => {
				stateService.go('service_unavailable', {error: 'true'});
			}
		);
	}
}

