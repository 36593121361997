import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';

import { AnneeConventionSelectorModule } from '@app/convention/annee-convention-selector';
import { ConventionSelectorModule, ConventionSelectorComponent } from '@app/convention/convention-selector';
import { NotificationService } from '@app/notification/notification.service';
import { PortailService, PortailParam } from '@app/portail/portail.service';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { uid } from '@helpers/utils';

@Component({
	selector: 'alerte-rectification-ca-dialog',
	templateUrl: './alerte-rectification-ca-dialog.html',
	providers: []
})
export class AlerteRectificationCADialogComponent implements OnInit, IEventListener {

	@ViewChild('alerteRectificationCAForm', { static: true }) alerteRectificationCAForm: NgForm;
	@ViewChild('conventionSelector') conventionSelector: ConventionSelectorComponent;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	loading: boolean = false;
	loadingData: boolean = false;
	ctx_id: number;
	portail: PortailParam;
	con_id: number;
	annee: number;

	help: string = 'Vous avez remarqué des incohérences dans les CA saisis ou souhaitez rappeler à un fournisseur de saisir les CA de vos filiales ?<br>Utilisez le formulaire ci-dessous pour notifier les personnes concernées.';

	contacts: any[];
	intro: string;
	message: string; // contenu du bout de texte édité par l'adhérent
	error: string|null;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private notificationService: NotificationService,
		private portailService: PortailService,
	) {
		this.ctx_id = this.portailService.currentContexte.ctx_id;
		const today = new Date();
		this.annee = config.data.annee || today.getFullYear();
		this.portail = config.data.portail;
		this.con_id = config.data.con_id;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	loadData() {
		if (this.con_id) {
			this.loadingData = true;
			this.error = null;
			this.notificationService.getAlerteRectificationCA(this.ctx_id, this.con_id)
			.subscribe(
				(response: any) => {
					if (!response.contacts.length) {
						this.error = 'Le partenariat sélectionné n\'a pas de contact assigné aux saisies des CA. Nous ne savons donc pas qui doit être notifié. Veuillez contacter un administrateur pour signaler ce problème.';
						this.contacts = [];
						this.intro = '';
						this.message= '';
					}
					else {
						this.contacts = response.contacts;
						this.intro = response.intro;
						this.message = response.message;
					}
				}
			)
			.add(() => { this.loadingData = false; });
		}
	}

	submit(): void {
		this.loading = true;
		if (this.alerteRectificationCAForm.invalid) return;
		this.notificationService.postAlerteRectificationCA(this.ctx_id, this.con_id, this.message)
		.subscribe(
			(response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Notification transmise'});
				this.dialogRef.close();
			}
		)
		.add(() => { this.loading = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		ChipsModule,
		InputTextareaModule,
		AnneeConventionSelectorModule,
		ConventionSelectorModule,
		MessageModule,
	],
	exports: [AlerteRectificationCADialogComponent],
	declarations: [AlerteRectificationCADialogComponent]
})
export class AlerteRectificationCADialogModule { }

