import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { PrestationService } from '@app/convention/prestation.service';
import { TypePrestation } from '@app/convention/type-prestation.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'typePrestation'})
export class TypePrestationPipe implements PipeTransform {

	constructor(private prestationService: PrestationService) {}

	typesPrestation: TypePrestation[];

	transform(tpr_id: number|number[], separator: string = ', '): Promise<string>{
		return this.prestationService.getCachedListTypesPrestation()
		.toPromise().then(
			(response: any) => {
				this.typesPrestation = response;
				if (Array.isArray(tpr_id)) {
					let result: string[] = [];
					tpr_id.forEach((one: number) => {
						result.push(this.transformOne(one));
					});
					result.sort();
					return result.join(separator);
				}
				return this.transformOne(tpr_id);
			},
			err => {
				return tpr_id? tpr_id.toString() : '';
			}
		);
	}

	transformOne(tpr_id: number) {
		const typePerformance = this.typesPrestation.find((one: TypePrestation) => {
			return one.tpr_id == tpr_id;
		});
		if (typePerformance) {
			return  typePerformance.tpr_libelle;
		}
		return tpr_id? tpr_id.toString() : '';
	}
}
