import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';

import { Convention } from '@app/convention/convention.model';
import { ParametreService } from '@app/parametre/parametre.service';
import { Signature } from '@app/signature/signature.model';
import { SignatureService } from '@app/signature/signature.service';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { uid } from '@helpers/utils';

@Component({
	selector: 'signature-dialog',
	templateUrl: './signature-dialog.html',
})
export class SignatureDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	loading: boolean;

	convention: Convention;
	hasSignatureContrat: boolean = false;
	warningMessage: string = '';
	fonctionnaliteSignatureElectronique: boolean = false;
	signatureElectroniqueObligatoire: boolean = false;
	signatureElectroniqueDefaut: boolean = false;
	signature: Signature = new Signature();

	helpTypeSignatureContrat: string = `La signature <b>Contrat</b> régi l'état de la convention. Il ne peut y avoir qu'une seule signature Contrat par convention.`;
	helpTypeSignatureAvenant: string = `Les signatures <b>Avenant</b> n'influent pas sur l'état de la convention.`;

	helpSignatureManuelle: string = `En signature <b>manuelle</b>, l'état des signatures est de votre responsabilité.<br>L'ajout des documents signés est également à votre charge.`;
	helpSignatureElectronique: string = `En signature <b>électronique</b>, l'état des signatures est géré par le service de signature tiers.<br>Les documents signés seront rapatriés automatiquement lorsque tous les signataires auront signés.`;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private signatureService: SignatureService,
	) {
		this.convention = config.data.convention;
		this.hasSignatureContrat = config.data.hasSignatureContrat;
		this.warningMessage = config.data.warningMessage;
		this.fonctionnaliteSignatureElectronique = this.parametreService.getParam('signature_electronique');
		this.signatureElectroniqueObligatoire = this.parametreService.getParam('signature_electronique_obligatoire');
		this.signatureElectroniqueDefaut = this.parametreService.getParam('signature_electronique_defaut');

		this.signature.con_id = this.convention.con_id;

		this.signature.tsi_code = this.hasSignatureContrat? 'A' : 'C';
		if (
			this.fonctionnaliteSignatureElectronique
			&& (
				this.signatureElectroniqueObligatoire
				|| this.signatureElectroniqueDefaut
			)
		) {
			this.signature.sig_electronique = true;
		}
 	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit(sig_electronique: boolean) {
		this.loading = true;

		this.signatureService.post(this.signature)
		.subscribe(
			(response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Signature créée'});
				this.dialogRef.close(response);
			}
		).add(() => {
			this.loading = false ;
		});
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TooltipModule,
	],
	exports: [SignatureDialogComponent],
	declarations: [SignatureDialogComponent]
})
export class SignatureDialogModule { }

