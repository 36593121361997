import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortailService, PortailParam } from '@app/portail/portail.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { environment } from '@environments/environment';
import { prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import { uid } from '@helpers/utils';

export class CodeInterneContexte {
	cic_id: number;
	ctx_source_id: number;
	ctx_cible_id: number;
	ctx_id: number;
	cic_code: string|null;
}

export class CodeInterneGroupeContexte {
	cig_id: number;
	ctx_source_id: number;
	grc_cible_id: number;
	grc_id: number;
	cig_code: string|null;
}

export class CodesInternes {
	contextes: CodeInterneContexte[];
	groupes_contextes: CodeInterneGroupeContexte[];
}

export type CodesInternesOptions = {
	grc_id_attr?: string;
	grc_code_attr?: string;
	grc_libelle_attr?: string;
	grc_libelle_destination_attr?: string;
	ctx_id_attr?: string;
	ctx_code_attr?: string;
	ctx_libelle_attr?: string;
	ctx_libelle_destination_attr?: string;
}

export type CibleCodeInterne = 'contextes'|'groupes_contextes';


@Injectable({ providedIn: 'root' })
export class CodesInternesService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private codesInternesSubject = new BehaviorSubject<CodesInternes|null>(null);

	public get codesInternes() {
		return this.codesInternesSubject.getValue();
	}

	constructor(
		private http: HttpClient,
		private portailService: PortailService,
		private eventManager: EventManagerService,
	) {
		this.eventManager.registerEvent('resetContexte', this, (args: any) => {
				this.reset();
			}
		);
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('resetContexte', this);
	}

	reset() {
		this.codesInternesSubject.next(null);
	}

	public getUrlCodesInternes(target?: CibleCodeInterne, portail?: PortailParam) {
		const ctx_id = this.portailService.currentContexte.ctx_id;
		let endpoint = 'codes_internes';
		if (target) {
			endpoint = `${endpoint}_${target}`;
		}
		if (portail) {
			const por_id = this.portailService.getPortailId(portail);
			endpoint = `${endpoint}/${por_id}`;
		}
		let url = `${environment.api_url}/contextes/${ctx_id}/${endpoint}`;
		return url;
	}

	public getUrlImportCodesInternes(target: CibleCodeInterne, portail: PortailParam) {
		let url = this.getUrlCodesInternes(target, portail);
		return `${url}/import`;
	}

	public exportCodesInternes(params: any, target: CibleCodeInterne, portail: PortailParam) {
		const tmpParams = prepareQueryParamsForDownload(params);
		let url = this.getUrlCodesInternes(target, portail);
		return this.http.get<any>(`${url}/export`, tmpParams);
	}

	public getCodesInternes() {
		return this.http.get<any>(this.getUrlCodesInternes())
		.pipe(
			map(
				(response: any) => {
					this.codesInternesSubject.next(response);
					return response;
				}
			)
		);
	}

	public getCachedCodesInternes(refresh: boolean = false) {
		if (!refresh && !!this.codesInternes) {
			return new Observable<any>(subscriber => {
				subscriber.next(this.codesInternes);
				subscriber.complete();
			});
		}
		else {
			return this.getCodesInternes();
		}
	}

	public getCode(entity: any, cible: CibleCodeInterne, options?: CodesInternesOptions) {
		if (!this.codesInternes) return '';
		let found;
		switch (cible) {
			case	'contextes':
				found = this.codesInternes.contextes.find((one: CodeInterneContexte) => {
					return one.ctx_id == entity.ctx_id;
				});
				return (found && found.cic_code)? found.cic_code : '';

			case 'groupes_contextes':
				found = this.codesInternes.groupes_contextes.find((one: CodeInterneGroupeContexte) => {
					return one.grc_id == entity.grc_id;
				});
				return (found && found.cig_code)? found.cig_code : '';
		}
	}

	public setCode(entity: any, options?: CodesInternesOptions) {
		if (!this.codesInternes) return;

		const grc_id_attr: string = (options && options.grc_id_attr)? options.grc_id_attr : 'grc_id';
		const grc_libelle_attr: string = (options && options.grc_libelle_attr)? options.grc_libelle_attr : 'grc_libelle';
		const grc_libelle_destination_attr: string = (options && options.grc_libelle_destination_attr)? options.grc_libelle_destination_attr : 'grc_libelle_long';
		const ctx_id_attr: string = (options && options.ctx_id_attr)? options.ctx_id_attr : 'ctx_id';
		const ctx_libelle_attr: string = (options && options.ctx_libelle_attr)? options.ctx_libelle_attr : 'ctx_libelle';
		const ctx_libelle_destination_attr: string = (options && options.ctx_libelle_destination_attr)? options.ctx_libelle_destination_attr : 'ctx_libelle_long';

		if (entity[grc_id_attr] && typeof entity.cig_code != 'undefined' ) {
			const found = this.codesInternes.groupes_contextes.find((one: CodeInterneGroupeContexte) => {
				return one.grc_id == entity[grc_id_attr];
			});

			entity[grc_libelle_destination_attr] = entity[grc_libelle_attr];
			if (found && found.cig_code) {
				entity.cig_code = found.cig_code;
				 entity[grc_libelle_destination_attr] = `${entity[grc_libelle_attr]} (${entity.cig_code})`;
			}
		}
		if (entity[ctx_id_attr] && typeof entity.cic_code != 'undefined' ) {
			const found = this.codesInternes.contextes.find((one: CodeInterneContexte) => {
				return one.ctx_id == entity[ctx_id_attr];
			});
			entity[ctx_libelle_destination_attr] = entity[ctx_libelle_attr];
			if (found && found.cic_code) {
				entity.cic_code = found.cic_code;
				entity[ctx_libelle_destination_attr] = `${entity[ctx_libelle_attr]} (${entity.cic_code})`;
			}
		}
	}

	public setCodes(entity: any[], options?: CodesInternesOptions) {
		if (!this.codesInternes) return;
		entity.forEach((one: any) => {
			this.setCode(one, options);
		});
	}


	public formatLabel(entity: any, options?: CodesInternesOptions) {
		const grc_id_attr: string = (options && options.grc_id_attr)? options.grc_id_attr : 'grc_id';
		const grc_code_attr: string = (options && options.grc_code_attr)? options.grc_code_attr : 'cig_code';
		const grc_libelle_attr: string = (options && options.grc_libelle_attr)? options.grc_libelle_attr : 'grc_libelle';
		const grc_libelle_destination_attr: string = (options && options.grc_libelle_destination_attr)? options.grc_libelle_destination_attr : 'grc_libelle_long';
		const ctx_id_attr: string = (options && options.ctx_id_attr)? options.ctx_id_attr : 'ctx_id';
		const ctx_code_attr: string = (options && options.ctx_code_attr)? options.ctx_code_attr : 'cic_code';
		const ctx_libelle_attr: string = (options && options.ctx_libelle_attr)? options.ctx_libelle_attr : 'ctx_libelle';
		const ctx_libelle_destination_attr: string = (options && options.ctx_libelle_destination_attr)? options.ctx_libelle_destination_attr : 'ctx_libelle_long';

		if (entity[grc_id_attr]) {
			entity[grc_libelle_destination_attr] = entity[grc_libelle_attr];
			if (
				typeof entity[grc_code_attr] != 'undefined'
				&& entity[grc_code_attr] !== null
				&& entity[grc_code_attr].length
			) {
				entity[grc_libelle_destination_attr] = `${entity[grc_libelle_attr]} (${entity[grc_code_attr]})`;
			}
		}
		if (entity[ctx_id_attr]) {
			entity[ctx_libelle_destination_attr] = entity[ctx_libelle_attr];
			if (
				typeof entity[ctx_code_attr] != 'undefined'
				&& entity[ctx_code_attr] !== null
				&& entity[ctx_code_attr].length
			) {
				entity[ctx_libelle_destination_attr] = `${entity[ctx_libelle_attr]} (${entity[ctx_code_attr]})`;
			}
		}
	}

	public formatLabels(entities: any[], options?: CodesInternesOptions) {
		entities.forEach((one: any) => {
			this.formatLabel(one, options);
		});
	}



}


