import { Societe } from '@app/societe/societe.model';

export class Agence {
	age_id: number;
	age_actif: boolean = true;
	ctx_fil_id: number;
	tag_id: number = 1;
	societe: Societe;
	soc_id: number;
	age_date_referencement: Date;

	constructor() {
		this.age_date_referencement = new Date();
	}
}
