import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatSize'})
export class FormatSizePipe implements PipeTransform {

	constructor() {}

	transform(bytes?: number): string {
		if (typeof bytes == 'undefined' || bytes == 0) {
			return '0 B';
		}
		let k = 1024,
		dm = 3,
		sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
}
