export class Adresse {
	adr_id: number;
	soc_id: number;
	adr_adresse: string;
	adr_adresse2: string;
	adr_code_postal: string;
	adr_ville: string;
	adr_telephone: string;
	adr_telecopie: string;
	adr_siege: boolean = false;
	adr_facturation: boolean = false;
	adr_livraison: boolean = false;
	adr_correspondance: boolean = false;
	pay_id: number = 1;
}
