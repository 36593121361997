import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';

@Pipe({name: 'modeSaisieCA'})
export class ModeSaisieCAPipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(msc_code: string, short: boolean = false): string {
		const modesSaisieCA = this.conventionService.modesSaisieCA;
		const mode = modesSaisieCA.find(one => {return one.msc_code == msc_code;});
		if (mode) {
			if (short) {
				let tmp = mode.msc_libelle.split(' ').pop();
				if (tmp) {
					return tmp.charAt(0).toUpperCase() + tmp.slice(1);
				}
			}
			return mode.msc_libelle;
		}
		return msc_code.toString();
	}
}
