import { Pipe, PipeTransform } from '@angular/core';

import { RequeteContactsRelance } from '@app/relance/requete-contacts-relance.model';
import { RelanceService } from '@app/relance/relance.service';

@Pipe({name: 'requeteContactsRelance'})
export class RequeteContactsRelancesPipe implements PipeTransform {

	constructor(private relanceService: RelanceService) {}

	transform(rcr_id?: number): Promise<string> {
		return this.relanceService.getCachedListRequetesContactsRelance()
		.toPromise().then(
			response => {
				if (response) {
					const found = response.find((one: RequeteContactsRelance) => {return one.rcr_id == rcr_id;});
					if (found) return found.rcr_libelle;
				}
				return rcr_id? rcr_id.toString() : '';
			},
			err => {
				return rcr_id? rcr_id.toString() : '';
			}
		);
	}
}
