import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UIRouterModule, UIView } from '@uirouter/angular';

import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

import { GlobalModule } from '@global/global.module';
import { routerConfigFn } from '@helpers/router.config';

import { ApiErrorInterceptor } from '@helpers/api-error.interceptor';
import { ApiJwtInterceptor } from '@helpers/api-jwt.interceptor';
import { ApiContexteInterceptor, ApiPortailInterceptor } from '@helpers/api-portail.interceptor';
import { ApiResponseTypeToBlobInterceptor } from '@helpers/api-response-type-to-blob.interceptor';

import { APP_STATES } from '@app/app.states';

import { AppComponent } from '@app/app.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		HttpClientModule,
		GlobalModule,
		UIRouterModule.forRoot({
			states: APP_STATES,
			useHash: false,
			initial: { state: 'app' },
			otherwise: { state: 'app', params: {}, options: { reload: true }},
			config: routerConfigFn,
		}),
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: ApiJwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiPortailInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiContexteInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiResponseTypeToBlobInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'fr-FR'},
		ConfirmationService, DialogService
	],
	bootstrap: [UIView]
})
export class AppModule { }
