import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { CardModule } from 'primeng/card';

import { LogoDisplayModule } from '@app/logo/logo-display';
import { Portail } from '@app/portail/portail.model';

@Component({
	selector: 'selection-portail',
	templateUrl: 'selection-portail.html'
})
export class SelectionPortailComponent implements OnInit {

	@Input() portailsAccessibles: Portail[];

	constructor(private stateService: StateService) { }

	ngOnInit() { }

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		CardModule,
		LogoDisplayModule
	],
	exports: [SelectionPortailComponent],
	declarations: [SelectionPortailComponent]
})
export class SelectionPortailModule { }
