import { Component, OnInit } from '@angular/core';


@Component({
	selector: 'portail-root',
	template: `<ui-view></ui-view>`
})
export class PortailRootComponent implements OnInit {

	constructor() { }

	ngOnInit() {

	}

}
