import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import { ActionFichier } from '@app/document/action-fichier.model';
import { Archive } from '@app/document/archive.model';
import { CategorieFichierFournisseur } from '@app/document/categorie-fichier-fournisseur.model';
import { DemandeActionFichier } from '@app/document/demande-action-fichier.model';
import { EtatDemandeActionFichier } from '@app/document/etat-demande-action-fichier.model';
import { FichierFournisseur } from '@app/document/fichier-fournisseur.model';
import { FichierFournisseurCandidat } from '@app/document/fichier-fournisseur-candidat.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import {
	clone,
	convertDateFieldsToDate, convertDateFieldsToString,
	downloadUrl,
	ExtensibleObject,
	extractFileExtension,
	stringSort
} from '@helpers/utils';

class CategoriesParPortail {
	// fournisseur: CategorieFichierFournisseur[] = [];
	// frais_generaux: CategorieFichierFournisseur[] = [];
	[key: string]: CategorieFichierFournisseur[];
}

export type TrameTarifs = {
	trm_id: number;
	trm_libelle: string;
}

@Injectable({ providedIn: 'root' })
export class DocumentService {

	private categoriesFichierFournisseurSubject = new BehaviorSubject<CategorieFichierFournisseur[]>([]);
	readonly categoriesFichierFournisseur = this.categoriesFichierFournisseurSubject.asObservable();

	private mesCategoriesFichierFournisseurEcritureSubject = new BehaviorSubject<CategoriesParPortail>(new CategoriesParPortail());
	readonly mesCategoriesFichierFournisseurEcriture = this.mesCategoriesFichierFournisseurEcritureSubject.asObservable();

	private _tramesTarifs: TrameTarifs[];

	actionsFichier: ActionFichier[];
	// [
	// 	{tdf_code: 'A', tdf_libelle: 'Ajout'},
	// 	{tdf_code: 'M', tdf_libelle: 'Mise à jour'},
	// 	{tdf_code: 'S', tdf_libelle: 'Suppression'},
	// ];

	etatsDemandeActionFichier: EtatDemandeActionFichier[];
	// [
	// 	{edf_code: 'B', edf_libelle: 'Brouillon'},
	// 	{edf_code: 'S', edf_libelle: 'Validation en cours'},
	// 	{edf_code: 'T', edf_libelle: 'Terminée'},
	// ];

	private readonly regExpEvolutionTarifaire = new RegExp('\n+', 'g');

	constructor(
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private portailService: PortailService,
		private utilisateurService: UtilisateurService,
	) {
		this.actionsFichier = listesGeneriquesService.getListe('types_demandes_fichiers');
		this.etatsDemandeActionFichier = listesGeneriquesService.getListe('etats_demandes_fichiers');
		stringSort(this.etatsDemandeActionFichier, 'edf_code');
		this._tramesTarifs = listesGeneriquesService.getListe('trames_tarifs');
	}

	public get fileTypes(): {[key: string]: any} {
		return {
			doc: {
				extensions: ['doc', 'docx', 'odt'],
				mimetypes: [
					'application/msword',
					'application/vnd.oasis.opendocument.text',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				]
			},
			xls: {
				extensions: ['xls', 'xlsx', 'ods'],
				mimetypes: [
					'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.oasis.opendocument.spreadsheet',
				]
			},
			ppt: {
				extensions: ['ppt', 'pptx', 'odp'],
				mimetypes: [
					'application/vnd.ms-powerpoint',
					'application/vnd.openxmlformats-officedocument.presentationml.presentation',
					'application/vnd.oasis.opendocument.presentation',
				]
			},
			pdf: {
				extensions: ['pdf'],
				mimetypes: ['application/pdf'],
			},
			logo: {
				extensions: ['jpg', 'jpeg', 'png'],
				mimetypes: [
					'image/jpeg',
					'image/png',
				]
			},
			image: {
				extensions: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'tif', 'ico']
			},
			video: {
				extensions: ['mp4'],
				mimetypes: ['application/mp4', 'video/mp4']
			}
		}
	}

	public isImage(file: File|string): boolean {
		if (file instanceof File) {
			return /^image\//.test(file.type);
		}
		return this.fileTypes.image.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isExcel(file: File|string): boolean {
		if (file instanceof File) {
			return /ms-excel|spreadsheetml|spreadsheet/.test(file.type);
		}
		return this.fileTypes.xls.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isPdf(file: File|string): boolean {
		if (file instanceof File) {
			return /\/pdf/.test(file.type);
		}
		return this.fileTypes.pdf.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isVideo(file: File|string): boolean {
		if (file instanceof File) {
			return /^video\//.test(file.type);
		}
		return this.fileTypes.video.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isTarif(cff_id: number) {
		return this.isCategorieType(cff_id, 'tarif');
	}

	public trameTarifsObligatoire(cff_id: number) {
		const categorie = this.categoriesFichierFournisseurValue.find((one: CategorieFichierFournisseur) => { return one.cff_id == cff_id; });
		if (!categorie) return false;
		return categorie.cff_trame_obligatoire;
	}

	public evolutionTarifaireObligatoire(cff_id: number) {
		const categorie = this.categoriesFichierFournisseurValue.find((one: CategorieFichierFournisseur) => { return one.cff_id == cff_id; });
		if (!categorie) return false;
		return categorie.cff_evolution_tarifaire_obligatoire;
	}

	public isCategorieType(cff_id: number, categorieType: 'tarif'|'commercial'|'technique') {
		const categorie = this.categoriesFichierFournisseurValue.find((one: CategorieFichierFournisseur) => { return one.cff_id == cff_id; });
		return categorie && categorie[`cff_${categorieType}`];
	}

	public getFileType(file: File|string) {
		if (this.isImage(file)) return 'image';
		if (this.isExcel(file)) return 'excel';
		if (this.isPdf(file)) return 'pdf';
		if (this.isVideo(file)) return 'video';
		else return 'other';
	}

	public get tramesTarifs() {
		return [...this._tramesTarifs];
	}

	public get tramesWithLogo(): string[] {
		return ['edoni', 'eqip', 'etim', 'fabdis'];
	}

	public trameHasLogo(trm_libelle: string) {
		return this.tramesWithLogo.includes(trm_libelle.toLowerCase());
	}

	public getEtatsDemandeActionFichier() {
		return [...this.etatsDemandeActionFichier];
	}

	public getActionsFichier() {
		return [...this.actionsFichier];
	}

	public get categoriesFichierFournisseurValue() {
		return this.categoriesFichierFournisseurSubject.getValue();
	}

	public get mesCategoriesFichierFournisseurEcritureValue() {
		return this.mesCategoriesFichierFournisseurEcritureSubject.getValue();
	}

	public getDefaultAcceptedMimeTypes() {
		return [
			...this.fileTypes.doc.mimetypes,
			...this.fileTypes.xls.mimetypes,
			...this.fileTypes.pdf.mimetypes,
			...this.fileTypes.ppt.mimetypes,
			...this.fileTypes.video.mimetypes,
			'image/*',
		].join(',');
	}

	public getMimeTypesOf(fileType: string) {
		return [
			...this.fileTypes[fileType].mimetypes,
		].join(',');
	}

	public getExtensionsOf(fileType: string) {
		return [
			...this.fileTypes[fileType].extensions,
		].join(',');
	}

	public getDefaultAcceptedExtensions() {
		let tmp = [
			...this.fileTypes.doc.extensions,
			...this.fileTypes.xls.extensions,
			...this.fileTypes.pdf.extensions,
			...this.fileTypes.ppt.extensions,
			...this.fileTypes.video.extensions,
		].map(one => {return '*.' + one; }).join(', ');
		return `${tmp}, images`;
	}

	public getMesCategoriesFichierFournisseurEcriture(portail: number | string) {
		const por_code = this.portailService.getPortailCode(portail);
		const tmp: any = this.mesCategoriesFichierFournisseurEcritureSubject.getValue();
		return por_code? tmp[por_code] : [];
	}

	public prepareDemandeActionsFichiersFromServer(demande: DemandeActionFichier) {
		let tmp = clone(demande);
		convertDateFieldsToDate(tmp, true);
		if (tmp.fichiers_candidats)	{
			tmp.fichiers_candidats.forEach((one: any) => {
				one.ffc_mots_clefs = (one.ffc_mots_clefs)? one.ffc_mots_clefs.split(',') : [];
				one.validation = !!one.uti_validation;
				one.por_code = this.portailService.getPortailCodeFromId(one.por_id);
			});
		}
		return tmp;
	}

	public prepareDemandeActionsFichiers(demande: DemandeActionFichier) {
		let tmp = clone(demande);
		convertDateFieldsToString(tmp, false, true);
		tmp.fichiers_candidats.forEach((one: any) => {
			if (one.ffc_mots_clefs) {
				one.ffc_mots_clefs = one.ffc_mots_clefs.join(',');
			}
			// ajout -> mise à jour si un fichier cible a été sélectionné
			if (one.ffo_id && one.tdf_code == 'A') one.tdf_code = 'M';
			// mise à jour -> ajout si aucun fichier cible n'a été sélectionné
			if (!one.ffo_id && one.tdf_code == 'M') one.tdf_code = 'A';
		});
		return tmp;
	}

	public prepareFichierFournisseurFromServer(fichier: FichierFournisseur) {
		let tmp = clone(fichier);
		convertDateFieldsToDate(tmp, true);
		if (tmp.ffo_validite_fin && tmp.ffo_validite_fin < new Date()) {
			tmp.obsolete = true;
		}
		if (tmp.ffo_mots_clefs) {
			tmp.ffo_mots_clefs = (tmp.ffo_mots_clefs)? tmp.ffo_mots_clefs.split(',') : [];
			tmp.ffo_mots_clefs.map((one: string, index: number) => {
				tmp.ffo_mots_clefs[index] = one.trim();
			})
		}
		if (tmp.ffo_evolution_tarifaire) {
			tmp.ffo_evolution_tarifaire = this.formatEvolutionTarifaire(tmp.ffo_evolution_tarifaire);
		}
		return tmp;
	}

	public formatEvolutionTarifaire(str: string) {
		return str.replace(this.regExpEvolutionTarifaire, ' / ');
	}

	public prepareFichiersFournisseurFromServer(fichiers: FichierFournisseur[]) {
		for (let i = 0; i < fichiers.length ; i++) {
			fichiers[i] = this.prepareFichierFournisseurFromServer(fichiers[i]);
		}
	}

	public prepareFichierFournisseur(fichier: FichierFournisseur) {
		let tmp = clone(fichier);
		convertDateFieldsToString(tmp, false, true);
		if (tmp.ffo_mots_clefs) {
			tmp.ffo_mots_clefs = tmp.ffo_mots_clefs.join(',');
		}
		return tmp;
	}

	public determineTabListeFichier(cff_id: number, portail: PortailParam, canSeeDocCom?:boolean, canSeeDocTech?:boolean) {

		let droitsCanSeeDocCom = [];
		let droitsCanSeeDocTech = [];

		if (typeof canSeeDocCom == 'undefined' || typeof canSeeDocTech == 'undefined') {
			const typesCategories = this.getMesTypeCategoriesFichierFournisseurs(portail);
			canSeeDocTech = typesCategories.includes('technique');
			canSeeDocCom = typesCategories.includes('commercial');
		}

		if ((this.isCategorieType(cff_id, 'commercial') || this.isCategorieType(cff_id, 'tarif')) && canSeeDocCom) {
			if (this.isTarif(cff_id)) {
				return 'tarif';
			}
			else {
				return 'commercial';
			}
		}
		else if (this.isCategorieType(cff_id, 'technique') && canSeeDocTech) {
			return 'technique';
		}
		return '';
	}

	// typesCategories a pour valeur 'technique' ou 'commercial'
	public filterCategoriesFichierFournisseur(typesCategories?: string|string[], portail?: PortailParam) {
		let por_id: number;
		let categories: any[];
		if (portail) {
			por_id = this.portailService.getPortailId(portail);
			categories = this.categoriesFichierFournisseurValue.filter((one: CategorieFichierFournisseur) => {return one.por_id == por_id; });
		}
		else {
		 categories = clone(this.categoriesFichierFournisseurValue);
		}
		if (!!typesCategories) {
			let tmp: CategorieFichierFournisseur[] = [];
			if (!Array.isArray(typesCategories)) typesCategories = [typesCategories];
			typesCategories.forEach(type => {
				const attr = `cff_${type}`;
					tmp = tmp.concat(categories.filter((categorie: any) => {
					return categorie[attr] && categorie[attr] == true;
				}));
			});
			// dedup
			categories = [...new Set(tmp)];
		}
		return categories;
	}

	// déterminer le type de catégorie fournisseur disponible pour l'utilisateur, en lecture ou écriture
	// return 'commercial' | 'technique' | null
	public getMesTypeCategoriesFichierFournisseurs(portail: PortailParam, write?: boolean) {
		let typesCategories = [];
		const dru_niveau = (write)? 2 : 1;
		let droitsDocCom: any[] = [];
		let droitsDocTech: any[] = [];

		if (typeof portail == 'number') {
			portail = this.portailService.getPortailCodeFromId(portail);
		}

		if (portail == 'fournisseur') {
			droitsDocCom = [
				{por_code: 'eqip', dro_code: ['fournisseur'], dru_niveau: dru_niveau},
				{por_code: 'fournisseur', dro_code: ['doc_commerciaux'], dru_niveau: dru_niveau},
				{por_code: 'adherent', dro_code: ['doc_com_fournisseur'], dru_niveau: dru_niveau}
			];
			droitsDocTech = [
				{por_code: 'eqip', dro_code: ['fournisseur'], dru_niveau: dru_niveau},
				{por_code: 'fournisseur', dro_code: ['doc_techniques'], dru_niveau: dru_niveau},
				{por_code: 'adherent', dro_code: ['doc_tech_fournisseur'], dru_niveau: dru_niveau}
			];
		}
		else if (portail == 'frais_generaux') {
			droitsDocCom = [
				{por_code: 'eqip', dro_code: ['frais_generaux'], dru_niveau: dru_niveau},
				{por_code: 'frais_generaux', dro_code: ['doc_commerciaux'], dru_niveau: dru_niveau},
				{por_code: 'adherent', dro_code: ['doc_com_fg'], dru_niveau: dru_niveau}
			];
			droitsDocTech = [
				{por_code: 'eqip', dro_code: ['frais_generaux'], dru_niveau: dru_niveau},
				{por_code: 'frais_generaux', dro_code: ['doc_techniques'], dru_niveau: dru_niveau},
				{por_code: 'adherent', dro_code: ['doc_tech_fg'], dru_niveau: dru_niveau}
			];
		}

		const docCom = this.portailService.checkIfHasRight(droitsDocCom);
		const docTech = this.portailService.checkIfHasRight(droitsDocTech);
		if (docCom) {
			typesCategories.push('commercial');
			typesCategories.push('tarif');
		}
		if (docTech) {
			typesCategories.push('technique');
		}

		return typesCategories;
	}

	public prepareMesCategoriesFichierFournisseurEcriture() {
		return this.getCategoriesFichierFournisseur()
		.pipe(map(response => {
			let portails: number[] = [];
			let tmpCategories = new CategoriesParPortail();
			this.categoriesFichierFournisseurValue.forEach((categorie: CategorieFichierFournisseur) => {
				if (portails.indexOf(categorie.por_id)) {
					portails.push(categorie.por_id);
				}
			})
			portails.forEach(onePortail => {
				const por_code = this.portailService.getPortailCodeFromId(onePortail);
				const typesCategories = this.getMesTypeCategoriesFichierFournisseurs(por_code, true);
				const tmp = this.filterCategoriesFichierFournisseur(typesCategories, por_code);
				stringSort(tmp, 'cff_libelle');
				tmpCategories[por_code] = tmp;
			})
			this.mesCategoriesFichierFournisseurEcritureSubject.next(tmpCategories);
			return tmpCategories;
		}));
	}

	// typesCategories a pour valeur 'technique' ou 'commercial'
	public getCategoriesFichierFournisseur(params?: any, typesCategories?: string|string[], portail?: PortailParam, refresh?: boolean) {
		if (!refresh && this.categoriesFichierFournisseurValue && this.categoriesFichierFournisseurValue.length) {
			return new Observable<any>(subscriber=> {
				let tmp = this.filterCategoriesFichierFournisseur(typesCategories, portail);
			 	subscriber.next(tmp);
				subscriber.complete();
			});
		}
		else {
			let tmpParams = prepareQueryParams(params);
			return this.http.get<any>(`${environment.api_url}/categories_fichiers_fournisseurs`, tmpParams)
			.pipe(map(response => {
				this.categoriesFichierFournisseurSubject.next(response['categories_fichiers_fournisseurs']);
				return this.filterCategoriesFichierFournisseur(typesCategories, portail);
			}));
		}
	}

	public cleanCategorieFichierFournisseur(categorie: CategorieFichierFournisseur) {
		let tmp = clone(categorie);
		if (!tmp.cff_tarif) {
			tmp.cff_trame_obligatoire = false;
			tmp.cff_evolution_tarifaire_obligatoire = false;
		}
		return tmp;
	}

	public postCategorieFichierFournisseur(categorie: CategorieFichierFournisseur) {
		let tmp = this.cleanCategorieFichierFournisseur(categorie);
		return this.http.post<any>(`${environment.api_url}/categories_fichiers_fournisseurs`, tmp)
		.pipe(map(
			response => {
				this.categoriesFichierFournisseurSubject.next([]);
				return response;
			}
		));
	}

	public putCategorieFichierFournisseur(categorie: CategorieFichierFournisseur) {
		let tmp = this.cleanCategorieFichierFournisseur(categorie);
		return this.http.put<any>(`${environment.api_url}/categories_fichiers_fournisseurs/${categorie.cff_id}`, tmp)
		.pipe(map(
			response => {
				this.categoriesFichierFournisseurSubject.next([]);
				return response;
			}
		));
	}

	public deleteCategorieFichierFournisseur(cff_id: number) {
		return this.http.delete<any>(`${environment.api_url}/categories_fichiers_fournisseurs/${cff_id}`)
		.pipe(map(
			response => {
				this.categoriesFichierFournisseurSubject.next([]);
				return response;
			}
		));
	}

	public getDocumentsConvention(params: any, ctx_id: number, con_id: number) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents`, tmpParams);
	}

	public postDocumentConvention(ctx_id: number, con_id: number, data: any) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents`, data);
	}

	public getUrlUploadDocumentConvention(con_id: number, ctx_id?: number) {
		if (ctx_id) {
			return `${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents`;
		}
		else {
			return `${environment.api_url}/conventions_partenariats/${con_id}/documents`;
		}
	}

	public putDocumentConvention(ctx_id: number, con_id: number, dco_id: number, data: any) {
		return this.http.put<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents/${dco_id}`, data);
	}

	public deleteDocumentConvention(ctx_id: number, con_id: number, dco_id: number) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents/${dco_id}`);
	}

	public downloadDocumentConvention(ctx_id: number, con_id: number, dco_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/documents/${dco_id}`, tmpParams);
	}

	public postFichiersFournisseursCandidats(ctx_id: number, data: any) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/demandes_actions_fichiers`, data);
	}

	public getUrlUploadFichiersFournisseursCandidats(daf_id?: number, ctx_id?: number) {
		let url = `${environment.api_url}`;
		if (ctx_id) url += `/contextes/${ctx_id}`;
		url += '/demandes_actions_fichiers';
		if (daf_id) url += `/${daf_id}`;
		return url;
	}

	public getDemandesActionsFichiers(params: any, ctx_id?: number, myDemandsOnly?: boolean, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		if (myDemandsOnly) {
			url += '/mes_demandes_actions_fichiers';
		}
		else {
			url += '/demandes_actions_fichiers';
		}
		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				let tmp: DemandeActionFichier[] = [];
				response.demandes_actions.forEach((one: DemandeActionFichier) => {
					tmp.push(this.prepareDemandeActionsFichiersFromServer(one));
				});
				response.demandes_actions = [...tmp];
				return response;
			}
		));
	}

	public getBrouillonSuppressionFichiers(ctx_id: number) {
		let url = `${environment.api_url}/contextes/${ctx_id}/brouillon_suppressions_fichiers`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return (!!response)? this.prepareDemandeActionsFichiersFromServer(response) : null;
			}
		));
	}

	public getDemandeActionsFichiers(daf_id: number, ctx_id?: number) {
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		url += `/demandes_actions_fichiers/${daf_id}`;
		return this.http.get<any>(url)
		.pipe(map(
			response => {
				let tmp = this.prepareDemandeActionsFichiersFromServer(response);
				return tmp;
			}
		));
	}

	public deleteDemandeActionFichier(daf_id: number, ctx_id?: number|null) {
		if (this.portailService.onPortailEqip()) {
			ctx_id = null;
		}
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		url += `/demandes_actions_fichiers/${daf_id}`;
		return this.http.delete<any>(url);
	}

	public putDemandeActionFichier(demande: DemandeActionFichier) {
		let prepared = this.prepareDemandeActionsFichiers(demande);
		return this.http.put<any>(`${environment.api_url}/contextes/${demande.ctx_id}/demandes_actions_fichiers/${demande.daf_id}`, prepared);
	}

	public getFichierFournisseurCandidat(ffc_id: number, daf_id: number, ctx_id?: number) {
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		url += `/demandes_actions_fichiers/${daf_id}/fichiers_candidats/${ffc_id}`;
		return this.http.get<any>(url)
		.pipe(map(
			response => {
				return response;
			}
		));
	}

	// ctx_id = partenariat
	public downloadFichierFournisseurCandidat(ffc_id: number, daf_id: number, ctx_id?: number) {
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		url += `/demandes_actions_fichiers/${daf_id}/fichiers_candidats/${ffc_id}`;
		let urlToken = url + '/token';
		url += '/dl';
		return this.http.get<any>(urlToken)
		.subscribe(response => {
			const uti_id = this.utilisateurService.currentUtilisateurValue.uti_id;
			url += `?uti_id=${uti_id}&token=${response}`;
			downloadUrl(url);
		});
	}

	// ctx_id = partenariat
	public getFichiersFournisseur(params: any, typeDocument?: string, ctx_id?: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		switch (typeDocument) {
			case 'commercial':
				url += '/fichiers_fournisseurs_com';
				break;
			case 'technique':
				url += '/fichiers_fournisseurs_tech';
				break;
			case 'medias':
				url += '/fichiers_medias';
				break;
			case 'autres':
				url += '/fichiers_autres';
				break;
			case 'tarif':
				url += '/fichiers_tarifs';
				break;
			default:
				url += '/fichiers_fournisseurs';
		}
		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.fichiers_fournisseurs) {
					this.prepareFichiersFournisseurFromServer(response.fichiers_fournisseurs);
				}
				return response;
			}
		));
	}

	public getStatsTrames(portail?: PortailParam): any {
		let tmpParams = this.portailService.maybeAddPorIdToObject({}, portail);
		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(`${environment.api_url}/fichiers_tarifs_par_trame`, tmpParams);
	}

	// ctx_id = partenariat
	public getFichierFournisseur(ffo_id: number, ctx_id?: number) {
		let tmpParams = prepareQueryParams({});
		const url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.get<any>(`${url}/fichiers_fournisseurs/${ffo_id}`, tmpParams)
		.pipe(map(
			response => {
				return this.prepareFichierFournisseurFromServer(response);
			}
		));
	}

	// ctx_id = partenariat
	public downloadFichierFournisseur(ffo_id: number, ctx_id?: number) {
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		url += `/fichiers_fournisseurs/${ffo_id}`;
		let urlToken = url + '/token';
		url += '/dl';
		return this.http.get<any>(urlToken)
		.subscribe(response => {
			const uti_id = this.utilisateurService.currentUtilisateurValue.uti_id;
			url += `?uti_id=${uti_id}&token=${response}`;
			downloadUrl(url);
		});
	}

	public putFichierFournisseur(fichier: FichierFournisseur) {
		let tmp = this.prepareFichierFournisseur(fichier);
		return this.http.put<any>(`${environment.api_url}/fichiers_fournisseurs/${fichier.ffo_id}`, tmp);
	}

	public deleteFichierFournisseur(fichier: FichierFournisseur): Observable<any> {
		return this.http.delete<any>(`${environment.api_url}/contextes/${fichier.ctx_id}/fichiers_fournisseurs/${fichier.ffo_id}`);
	}

	public createFichierFournisseurZip(params: any, ctx_id?: number) {
		const url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.post<any>(`${url}/mes_telechargements`, params);
	}

	public getMesArchives(params: any, ctx_id?: number) {
		let tmpParams = prepareQueryParams(params);
		const url = `${environment.api_url}/mes_telechargements`;
		return this.http.get<any>(url, tmpParams);
	}

	public deleteArchive(tff_id: number) {
		const url = `${environment.api_url}/mes_telechargements/${tff_id}`;
		return this.http.delete<any>(url);
	}

	public prepareArchivesFromServer(archives: Archive[]) {
		archives.forEach(one => {
			this.prepareArchiveFromServer(one);
		})
	}

	public prepareArchiveFromServer(archive: Archive) {
		convertDateFieldsToDate(archive, true);
	}

	public getArchive(tff_id: number) {
		let tmpParams = prepareQueryParams({});
		let url = `${environment.api_url}/mes_telechargements/${tff_id}`;
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			response => {
				this.prepareArchiveFromServer(response);
				return response;
			}
		));
	}

	public downloadArchive(tff_id: number) {
		let url = `${environment.api_url}/mes_telechargements/${tff_id}`;
		let urlToken = url + '/token';
		url += '/dl';
		return this.http.get<any>(urlToken)
		.subscribe(response => {
			const uti_id = this.utilisateurService.currentUtilisateurValue.uti_id;
			url += `?uti_id=${uti_id}&token=${response}`;
			downloadUrl(url);
		});
	}

	public getHistoriqueAjoutsFichiersFournisseurs(params: any, ctx_id?: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);
		const url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.get<any>(`${url}/historique_ajouts_fichiers_fournisseurs`, tmpParams)
		.pipe(map(
			(response: any) => {
				return response;
			}
		));
	}

	public getEvolutionsTarifaires(params: any, ctx_id?: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParams(tmpParams);
		const url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.get<any>(`${url}/evolutions_tarifaires`, tmpParams)
		.pipe(map(
			(response: any) => {
				return response;
			}
		));
	}

	public exportEvolutionsTarifaires(params: any, ctx_id?: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParamsForDownload(tmpParams);
		const url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.get<any>(`${url}/evolutions_tarifaires/export`, tmpParams)
		.pipe(map(
			(response: any) => {
				return response;
			}
		));
	}

}
