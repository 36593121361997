<div class="p-fluid formgrid grid">

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_adresse">Adresse</label>
		<input
			type="text"
			pInputText
			name="adr_adresse"
			#adr_adresse
			[(ngModel)]="value.adr_adresse"
			[required]="required"
			[ngClass]="{ 'p-invalid': submitted && adr_adresse.errors }"
			placeholder="N° rue..."
		>
		<div *ngIf="submitted && adr_adresse.errors" class="invalid-feedback p-invalid">
			<small *ngIf="adr_adresse.errors.required" >Adresse requise</small>
		</div>
	</div>

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_adresse2">Complément d'adresse</label>
		<input
			type="text"
			pInputText
			name="adr_adresse2"
			#adr_adresse2
			[(ngModel)]="value.adr_adresse2"
			placeholder="Bâtiment, étage..."
		>
	</div>

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_code_postal">Code postal</label>
		<input
			type="text"
			pInputText
			name="adr_code_postal"
			#adr_code_postal
			[(ngModel)]="value.adr_code_postal"
			[required]="required"
			[ngClass]="{ 'p-invalid': submitted && adr_code_postal.errors }"
			placeholder="Code postal"
		>
		<div *ngIf="submitted && adr_code_postal.errors" class="invalid-feedback p-invalid">
			<small *ngIf="adr_code_postal.errors.required" >Code postal requis</small>
		</div>
	</div>

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_ville">Ville</label>
		<input
			type="text"
			pInputText
			name="adr_ville"
			#adr_ville
			[(ngModel)]="value.adr_ville"
			[required]="required"
			[ngClass]="{ 'p-invalid': submitted && adr_ville.errors }"
			placeholder="Ville"
		>
		<div *ngIf="submitted && adr_ville.errors" class="invalid-feedback p-invalid">
			<small *ngIf="adr_ville.errors.required" >Ville requise</small>
		</div>
	</div>


	<div class="field col-12 md:col-6 xl:col-4">
		<label for="pay_id">Pays</label>
		<p-dropdown
			name="pay_id"
			#pay_id
			[(ngModel)]="value.pay_id"
			[required]="required"
			[options]="pays"
			optionLabel="pay_nom"
			optionValue="pay_id"
			placeholder="Sélectionnez"
		></p-dropdown>
	</div>

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_telephone">Téléphone</label>
		<input
			type="text"
			pInputText
			name="adr_telephone"
			#adr_telephone
			[(ngModel)]="value.adr_telephone"
		>
	</div>

	<div class="field col-12 md:col-6 xl:col-4">
		<label for="adr_telecopie">Télécopie</label>
		<input
			type="text"
			pInputText
			name="adr_telecopie"
			#adr_telecopie
			[(ngModel)]="value.adr_telecopie"
		>
	</div>

</div>
