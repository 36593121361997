import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { EtatFacture } from '@app/compta/etat-facture.model';

@Pipe({name: 'etatFacture'})
export class EtatFacturePipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(efa_code?: string): string {
		const etatsFacture = this.comptaService.etatsFacture;
		const etat = etatsFacture.find((one: EtatFacture) => {return one.efa_code == efa_code;});
		if (etat) {
			return  etat.efa_libelle;
		}
		return efa_code? efa_code.toString() : '';
	}
}
