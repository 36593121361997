import { Pipe, PipeTransform } from '@angular/core';

import { MarqueService } from '@app/marque/marque.service';
import { CommissionActivite } from '@app/marque/commission-activite.model';

@Pipe({name: 'commissionActivite'})
export class CommissionActivitePipe implements PipeTransform {

	commissions: any[];

	constructor(private marqueService: MarqueService) {}

	transform(cma_id: number|number[], separator: string = ', '): Promise<string> {
		return this.marqueService.getCommissionsActivites()
		.toPromise().then(
			(response: any) => {
				this.commissions = response;
				if (Array.isArray(cma_id)) {
					let result: string[] = [];
					cma_id.forEach((one: number) => {
						result.push(this.transformOne(one));
					});
					return result.join(separator);
				}
				return this.transformOne(cma_id);
			},
			err => {
				return cma_id? cma_id.toString() : '';
			}
		)
	}

	transformOne(cma_id: number) {
		const commission = this.commissions.find((one: CommissionActivite) => {
			return one.cma_id == cma_id;
		});
		if (commission) {
			return  commission.cma_libelle;
		}
		return cma_id? cma_id.toString() : '';
	}


}
