import { NgModule, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { flatMap } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from '@app/primeng-overrides/table';

import { Contexte } from '@app/contexte/contexte.model';
import { PortailService } from '@app/portail/portail.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { EqipModule } from '@global/eqip.module';
import { EventManagerService } from '@global/event-manager.service';
import { clone, simpleComparison } from '@helpers/utils';

@Component({
	selector: 'utilisateur-droits-contexte',
	templateUrl: './utilisateur-droits-contexte.html'
})
export class UtilisateurDroitsContexteComponent {
	@Input() ctx_id: number;
	@Input() contexte: Contexte;
	@Input() uti_id: number;
	@Input() editMode: boolean;
	@Input() canEdit: boolean;
	@Output() updatedEvent = new EventEmitter<boolean>();

	pristine: any;
	droitsCanEditAccesGroupe: any[] = [
		{dro_code:'administration', dru_niveau: 2, por_code: 'eqip'},
		{dro_code:'administration', dru_niveau: 2, por_code: 'adherent'}
	];
	canEditAccesGroupe: boolean;
	droitsCanEditDroitAdmin: any[] = [{dro_code:'administration', dru_niveau: 2, por_code: 'eqip'}];
	canEditDroitAdmin: boolean;
	title: string = 'Droits sur le contexte';
	loading: boolean = false;
	submitted: boolean = false;
	droitsDisponibles: any[] = [];
	currentDroitsUtilisateur: any[] = [];
	droits: any[] = [];
	selfEditing: boolean;

	optionsNiveaux: any[] = [
		{label: 'Aucun', value: 0, icon: 'pi pi-times'},
		{label: 'Lecture', value: 1, icon: 'pi pi-eye'},
		{label: 'Ecriture', value: 2, icon: 'pi pi-pencil'},
	];

	optionsOuiNon: any[] = [
		{label: 'Non', value: 0, icon: 'pi pi-times'},
		{label: 'Oui', value: 1, icon: 'pi pi-check'},
	];

	optionsTrueFalse: any[] = [
		{label: 'Non', value: false, icon: 'pi pi-times'},
		{label: 'Oui', value: true, icon: 'pi pi-check'},
	];

	constructor(
		private stateService: StateService,
		private portailService: PortailService,
		private utilisateurService: UtilisateurService,
		private eventManager: EventManagerService
	){
	}

	ngOnInit() {
		this.selfEditing = this.uti_id == this.utilisateurService.currentUtilisateurValue.uti_id;
		this.canEditAccesGroupe = this.portailService.checkIfHasRight(this.droitsCanEditAccesGroupe) && !this.selfEditing;
		this.canEditDroitAdmin = this.portailService.checkIfHasRight(this.droitsCanEditDroitAdmin) && !this.selfEditing;

		if (!this.contexte) {
			this.loading = true;
			this.portailService.getContexte(this.ctx_id)
			.subscribe(contexte => {
				this.contexte = contexte;
				this.getCurrentDroits();
			});
		}
		else {
			this.getCurrentDroits();
		}
	}

	getCurrentDroits() {
		this.loading = true;
		this.title = `${this.title} ${this.contexte.ctx_libelle}`;
		this.portailService.getDroitsDisponibles(this.contexte.por_id, true)
		.pipe(
			flatMap( response => {
				this.droitsDisponibles = response.droits;
				return this.utilisateurService.getDroitsSurContexte(this.uti_id, this.contexte.ctx_id);
			})
		)
		.subscribe(
			currentDroits => {
				this.currentDroitsUtilisateur = currentDroits.droits_utilisateurs? currentDroits.droits_utilisateurs : [];
				this.prepare();
			},
			error => { console.log(error);  this.loading = false;},
			() => { this.loading = false;}
		)
	}

	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	onChange(event: any) {
		this.updatedEvent.emit(!simpleComparison(this.pristine, this.droits));
	}

	prepare() {
		this.droits = [];
		for (let droit of this.droitsDisponibles) {
			let tmpDroit = clone(droit);
			let current = this.currentDroitsUtilisateur.find(one => {return one.dro_code == droit.dro_code;});
			let dru_niveau = 0;

			if (
				['acces_groupe', 'acces_groupement'].indexOf(tmpDroit.dro_code) > -1 && !this.canEditAccesGroupe
				|| tmpDroit.dro_code == 'administration' && !this.canEditDroitAdmin
			) {
				continue;
			}
			if (['acces_groupe', 'acces_groupement'].indexOf(droit.dro_code) > -1 ) {
				dru_niveau = (current)? current.dru_niveau : false;
			}
			else if (droit.dro_type_booleen == true) {
				dru_niveau = (current)? current.dru_niveau : 0;
				if (dru_niveau === 2) dru_niveau = 1;
			}
			else if (current) {
				dru_niveau = current.dru_niveau;
			}

			tmpDroit.dru_niveau = dru_niveau;
			this.droits.push(tmpDroit);
		}
		this.pristine = clone(this.droits);
	}

	submit() {
		this.loading = true;
		this.utilisateurService.setDroitsSurContexte(this.uti_id, this.contexte.ctx_id, this.droits)
		.subscribe(
			response => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Modification effectuée'});
				this.pristine = clone (this.droits);
				this.updatedEvent.emit(!simpleComparison(this.pristine, this.droits));
				this.toggleEditMode(false);
				if (this.selfEditing) {
					this.eventManager.emit('resetContexte');
					this.stateService.go('.', undefined, {reload: true});
				}
			},
			error => {this.loading = false;},
			() => {this.loading = false;}
		)
	}

}

@NgModule({
	imports: [
		CommonModule,
		EqipModule,
		FormsModule,
		ButtonModule,
		PanelModule,
		SelectButtonModule,
		TableModule,
	],
	exports: [UtilisateurDroitsContexteComponent],
	declarations: [UtilisateurDroitsContexteComponent]
})
export class UtilisateurDroitsContexteModule { }

