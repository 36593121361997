import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { DialogService } from 'primeng/dynamicdialog';

import { ConventionService } from '@app/convention/convention.service';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { ModeCalculPrestation } from '@app/convention/mode-calcul-prestation.model';
import { OperationPrestation } from '@app/compta/operation-prestation.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { Prestation } from '@app/convention/prestation.model';
import { TypePrestation } from '@app/convention/type-prestation.model';

import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import {
	arrayOfMonths,
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	downloadFromHttpResponse,
} from '@helpers/utils';


@Injectable({providedIn: 'root'})
export class PrestationService {

	private typesPrestationSubject = new BehaviorSubject<TypePrestation[]>([]);

	private _modesCalculPrestation: ModeCalculPrestation[];
	// [
	// 	{mcp_code: 'M', mcp_libelle: 'Proportionnel'},
	// 	{mcp_code: 'P', mcp_libelle: 'Forfaitaire à opérations multiples'},
	// 	{mcp_code: 'Q', mcp_libelle: 'Forfaitaire à opération unique'},
	// ];

	private _modesProrataPrestation: any[] =
	[
		{mpp_code: 'M', mpp_libelle: 'Montant'},
		{mpp_code: 'Q', mpp_libelle: 'Quantité'},
		{mpp_code: 'S', mpp_libelle: 'Sans'}
	];

	constructor(
		private conventionService: ConventionService,
		private dialogService: DialogService,
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private portailService: PortailService,
	) {
		this._modesProrataPrestation = listesGeneriquesService.getListe('modes_prorata_prestations');
		this._modesCalculPrestation = listesGeneriquesService.getListe('modes_calculs_prestations');
	}


	public get modesCalculPrestation() {
		return [...this._modesCalculPrestation];
	}

	public get modesProrataPrestation() {
		return [...this._modesProrataPrestation];
	}

	public get typesPrestation(): TypePrestation[] {
		return this.typesPrestationSubject.getValue();
	}

	public getCachedListTypesPrestation(activeOnly: boolean = false, refresh: boolean = false): Observable<TypePrestation[]> {
		if (!refresh && !!this.typesPrestation.length) {
			return new Observable<any>(subscriber => {
				subscriber.next(this.filterTypesPrestation(this.typesPrestation, activeOnly));
				subscriber.complete();
			});
		}
		else {
			return this.getTypesPrestation({order_by:'tpr_libelle', order:'asc'}).pipe(map((response: any) => {
				return this.filterTypesPrestation(response.types_prestations, activeOnly);
			}));
		}
	}

	private filterTypesPrestation(types: TypePrestation[], activeOnly: boolean = false) {
		if (activeOnly) {
			return types.filter(one => {return one.tpr_actif == true});
		}
		return types;
	}

	public getTypesPrestation(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/types_prestations`, tmpParams)
		.pipe(map(
			response => {
				if (response.types_prestations) {
					this.typesPrestationSubject.next(response.types_prestations);
				}
				return response;
			}
		));
	}

	public postTypePrestation(typePrestation: TypePrestation) {
		return this.http.post<any>(`${environment.api_url}/types_prestations`, typePrestation)
		.pipe(map(
			response => {
				this.typesPrestationSubject.next([]);
				return response;
			}
		));
	}

	public putTypePrestation(typePrestation: TypePrestation) {
		return this.http.put<any>(`${environment.api_url}/types_prestations/${typePrestation.tpr_id}`, typePrestation)
		.pipe(map(
			response => {
				this.typesPrestationSubject.next([]);
				return response;
			}
		));
	}

	public deleteTypePrestation(tpr_id: number) {
		return this.http.delete<any>(`${environment.api_url}/types_prestations/${tpr_id}`)
		.pipe(map(
			response => {
				this.typesPrestationSubject.next([]);
				return response;
			}
		));
	}

	public getOperationsPrestations(pre_id: number) {
		let tmpParams = prepareQueryParams({pre_id: pre_id});
		let url = `${environment.api_url}/operations_prestations`;
		return this.http.get<any>(url, tmpParams)
			.pipe(map(
				(response: any) => {
					response.operations_prestations.forEach((one: OperationPrestation) => {
						convertDateFieldsToDate(one);
					})
					return response;
				}
			));
	}

	public prepareOperation(operation: OperationPrestation) {
		let tmp = clone(operation);
		convertDateFieldsToString(tmp, false, true);
		return tmp;
	}

	public postOperationPrestation(operation: OperationPrestation) {
		let tmp: OperationPrestation = this.prepareOperation(operation);
		let url = `${environment.api_url}/operations_prestations`;
		return this.http.post<any>(url, tmp);
	}

	public putOperationPrestation(operation: OperationPrestation) {
		let tmp: OperationPrestation = this.prepareOperation(operation);
		let url = `${environment.api_url}/operations_prestations/${operation.opp_id}`;
		return this.http.put<any>(url, tmp);
	}

	public deleteOperationPrestation(operation: OperationPrestation) {
		let url = `${environment.api_url}/operations_prestations/${operation.opp_id}`;
		return this.http.delete<any>(url);
	}


	public exportPrestationsOperationUnique(portail: PortailParam, annee: number, tpr_id: number) {
		let tmpParams: any = prepareQueryParams({
			por_id: this.portailService.getPortailId(portail),
			annee: annee,
			tpr_id: tpr_id
		}, true);
		return this.http.get<any>(`${environment.api_url}/prestations_operation_unique/export`, tmpParams);
	}

	public getUrlImportPrestationsOperationUnique() {
		return `${environment.api_url}/prestations_operation_unique/import`;
	}

	public getPrestations(params: any, ctx_id_reference?: number) {
		let tmpParams = prepareQueryParams(params);
		let url = this.portailService.getRoutePrefix(null, ctx_id_reference);
		url = `${url}/suivi_prestations`;
		return this.http.get<any>(url, tmpParams)
			.pipe(map(
				(response: any) => {
					this.conventionService.prepareConventionsFromServer(response.prestations);
					return response;
				}
			));
	}

	public exportPrestations(params: any, ctx_id_reference?: number) {
		const tmpParams = prepareQueryParamsForDownload(params);
		let url = this.portailService.getRoutePrefix(null, ctx_id_reference);
		url = `${url}/suivi_prestations/export`;
		return this.http.get<any>(url, tmpParams)
			.pipe(mergeMap(
				(response: any) => {
					return downloadFromHttpResponse(response);
				}
			));
	}

}
