import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Contexte } from '@app/contexte/contexte.model';
import { GroupeContexte } from '@app/groupe-contexte/groupe-contexte.model';
import { CodesInternesService, CibleCodeInterne } from '@global/codes-internes.service';
import { PortailService } from '@app/portail/portail.service';

import { addOrRemoveTrailingOrLeadingChars, urlType } from '@helpers/utils';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'codeInterne'})
export class CodeInternePipe implements PipeTransform {

	constructor(
		private codesInternesService: CodesInternesService,
		private portailService: PortailService
	) {}

	transform(entity: Contexte|GroupeContexte, cible: CibleCodeInterne, codeOnly: boolean = false): string {
		let result = '';
		const code = this.codesInternesService.getCode(entity, cible);

		if (codeOnly && code) {
			result = code;
		}
		else {
			switch (cible) {
				case	'contextes':
					result = (entity as Contexte).ctx_libelle;
					if (code) {
						result = `${result} (${code})`;
					}
					break;
				case 'groupes_contextes':
					result = (entity as GroupeContexte).grc_libelle;
					if (code) {
						result = `${result} (${code})`;
					}
					break;
			}
		}

		return result;
	}

}
