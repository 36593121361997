import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';

import { ContexteSelectorModule, ContexteSelectorComponent } from '@app/contexte/contexte-selector';
import { NotificationService } from '@app/notification/notification.service';
import { PortailService } from '@app/portail/portail.service';
import { TypeAgenceSelectorModule } from '@app/agence/type-agence-selector';
import { AdresseFormModule } from '@app/societe/adresse-form';


import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { clone, uid } from '@helpers/utils';

@Component({
	selector: 'demande-ajout-agence-dialog',
	templateUrl: './demande-ajout-agence-dialog.html',
	providers: []
})
export class DemandeAjoutAgenceDialogComponent implements OnInit, IEventListener {

	@ViewChild('demandeAjoutAgenceForm', { static: true }) demandeAjoutAgenceForm: NgForm;
	@ViewChild('filialeSelector') filialeSelector: ContexteSelectorComponent;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	submitted: boolean = false;
	loading: boolean = false;
	ctx_id: number;

	noa_description: string;
	noa_json_source = {
		ctx_fil_id: null,
		ctx_fil_libelle: null,
		tag_id: null,
		soc_raison_sociale: null,
		soc_siret: null,
		adresse: {
			adr_adresse: null,
			adr_adresse2: null,
			adr_code_postal: null,
			adr_ville: null,
			adr_telephone: null,
			adr_telecopie: null,
			adr_siege: null,
			adr_facturation: null,
			adr_livraison: null,
			adr_correspondance: null,
			pay_id: null,
		}
	};

	constructor(
		private notificationService: NotificationService,
		private dialogRef: DynamicDialogRef,
		private portailService: PortailService,
		private eventManager: EventManagerService,
	) {

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});

		this.ctx_id = this.portailService.currentContexte.ctx_id;
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit(): void {
		this.submitted = true;
		if (this.demandeAjoutAgenceForm.invalid) return;
		let tmpJson = clone(this.noa_json_source);
		tmpJson.ctx_fil_libelle = this.filialeSelector.getEntityName();
		this.loading = true;
		this.notificationService.demandeAjoutAgence(this.ctx_id, tmpJson, this.noa_description)
		.subscribe(
			response => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Demande transmise', detail: 'Nous traiterons votre demande dans les plus brefs délais.'});
				this.dialogRef.close();
			}
		)
		.add(() => { this.loading = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		InputTextModule,
		InputTextareaModule,
		AdresseFormModule,
		ContexteSelectorModule,
		TypeAgenceSelectorModule,
	],
	exports: [DemandeAjoutAgenceDialogComponent],
	declarations: [DemandeAjoutAgenceDialogComponent]
})
export class DemandeAjoutAgenceDialogModule { }

