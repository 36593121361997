import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { clone, isDefined, uid } from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class StorageService implements OnDestroy, IEventListener  {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	_useLocalStorage: boolean = false;
	set useLocalStorage(val: boolean) {
		this._useLocalStorage = val;
	}
	get useLocalStorage() {
		return this._useLocalStorage;
	}

	constructor(
		private stateService: StateService,
		private eventManager: EventManagerService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.reset();
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	reset() {
		const resetables: string[] = [
			'last_contexte',
			'last_portail',
			'paramSets',
			'rgpdDialogShown',
			'token',
		];

		resetables.forEach((identifier: string) => {
			localStorage.removeItem(identifier);
			sessionStorage.removeItem(identifier);
		});
	}

	get(name: string, defaultValue: any = undefined, sessionOnly: boolean = true) {
		let value;
		if (this._useLocalStorage && !!!sessionOnly) {
			value = localStorage.getItem(name);
		}
		else {
			value = sessionStorage.getItem(name);
		}
		if (value !== null) {
			value = JSON.parse(value);
			return value;
		}
		return (isDefined(defaultValue))? defaultValue : null;
	}

	set(name: string, value: any, sessionOnly?: boolean) {
		if (this._useLocalStorage && !!!sessionOnly) {
			localStorage.removeItem(name);
			if (isDefined(value)) {
				localStorage.setItem(name, JSON.stringify(value));
			}
		}
		else {
			sessionStorage.removeItem(name);
			if (isDefined(value)) {
				sessionStorage.setItem(name, JSON.stringify(value));
			}
		}
	}

	getForCurrentState(name: string, defaultValue: any = undefined, sessionOnly: boolean = true) {
		const paramSets = this.get('paramSets', defaultValue, sessionOnly);
		const currentState = this.stateService.current.name;
		const params = this.stateService.params;
		let value;

		let grc_id = (isDefined(params.grc_id))? `grc_id-${params.grc_id}` : undefined;
		let ctx_id = (isDefined(params.ctx_id))? `ctx_id-${params.ctx_id}` : undefined;

		if (currentState) {
			if (
				grc_id
				&& paramSets
				&& paramSets[currentState]
				&& paramSets[currentState][grc_id]
			) {
				if (
					ctx_id
					&& paramSets[currentState][grc_id][ctx_id]
					&& isDefined(paramSets[currentState][grc_id][ctx_id][name])
				) {
					value = paramSets[currentState][grc_id][ctx_id][name];
				}
				else if (paramSets[currentState][grc_id][name]) {
					value = paramSets[currentState][grc_id][name];
				}
			}
			else if (
				ctx_id
				&& paramSets
				&& paramSets[currentState]
				&& paramSets[currentState][ctx_id]
				&& isDefined(paramSets[currentState][ctx_id][name])
			) {
				value = paramSets[currentState][ctx_id][name];
			}
			else if (
				paramSets
				&& paramSets[currentState]
				&& isDefined(paramSets[currentState][name])
			) {
				value = paramSets[currentState][name];
			}
		}

		if (!isDefined(value)) {
			return defaultValue;
		}
		return value;
	}

	setForCurrentState(name: string, value: any, sessionOnly?: boolean) {
		let paramSets = this.get('paramSets', undefined, sessionOnly);
		const currentState = this.stateService.current.name;
		const params = this.stateService.params;

		let grc_id = (isDefined(params.grc_id))? `grc_id-${params.grc_id}` : undefined;
		let ctx_id = (isDefined(params.ctx_id))? `ctx_id-${params.ctx_id}` : undefined;

		if (currentState) {
			if (!!!paramSets) paramSets = {};
			if (!!!paramSets[currentState]) paramSets[currentState] = {};

			if (typeof grc_id != 'undefined') {
				if (!!!paramSets[currentState][grc_id]) {
					paramSets[currentState][grc_id] = {};
				}
				if (typeof ctx_id != 'undefined') {
					if (!!!paramSets[currentState][grc_id][ctx_id]) {
						paramSets[currentState][grc_id][ctx_id] = {};
					}
					paramSets[currentState][grc_id][ctx_id][name] = value;
				}
				else {
					paramSets[currentState][grc_id][name] = value;
				}
			}
			else if (typeof ctx_id != 'undefined') {
				if (!!!paramSets[currentState][ctx_id]) {
					paramSets[currentState][ctx_id] = {};
				}
				paramSets[currentState][ctx_id][name] = value;
			}
			else {
				paramSets[currentState][name] = value;
			}

			this.set('paramSets', paramSets, sessionOnly);
		}
	};

}
