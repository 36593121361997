import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { DemandeReglement } from '@app/compta/demande-reglement.model';

@Pipe({name: 'sourceDemandeReglement'})
export class SourceDemandeReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(entity: string|DemandeReglement): string {
		if (typeof entity == 'string') {
			return this.comptaService.transformSourceDemandeCodeToLibelle(entity);
		}
		else {
			return this.comptaService.transformSourceDemandeCodeToLibelle(entity.sdr_code, entity.mcp_code);
		}
	}
}
