import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Contexte } from '@app/contexte/contexte.model';
import { GroupeContexte } from '@app/groupe-contexte/groupe-contexte.model';
import { PortailService } from '@app/portail/portail.service';

import { addOrRemoveTrailingOrLeadingChars, urlType } from '@helpers/utils';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'internalLink'})
export class InternalLinkPipe implements PipeTransform {

	constructor(private portailService: PortailService) {}

	transform(url: string, entity?: Contexte|GroupeContexte): string {
		if (!entity) {
			entity = this.portailService.currentContexte;
		}

		let por_code = this.portailService.getPortailCode(entity.por_id);
		// on a des tirets plutôt que des undescore dans l'url du portail
		por_code = por_code.replace('_', '-');

		url = url.replace(/\{(portail|por_code)\}/, por_code);
		url = url.replace(/\{por_id\}/, entity.por_id.toString());
		if (typeof (entity as Contexte)['ctx_id'] != 'undefined') {
			url = url.replace(/\{ctx_id\}/, (entity as Contexte).ctx_id.toString());
		}
		url = url.replace(/\{grc_id\}/, entity.grc_id.toString());

		if (urlType(url) == 'internal') {
			if (url.startsWith(window.location.origin)) {
				url = url.replace(window.location.origin, '');
			}
			if (!url.match(/^\/?portail\/.*/)) {
				url = addOrRemoveTrailingOrLeadingChars(url, '/', true, true);
				url = `/portail/${por_code}/${url}`;
			}
		}

		return url;
	}
}
