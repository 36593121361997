import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '@app/document/document.service';

@Pipe({name: 'etatDemandeActionFichier'})
export class EtatDemandeActionFichierPipe implements PipeTransform {

	constructor(private documentService: DocumentService) {}

	transform(edf_code?: string): string {
		const etatsDemande = this.documentService.getEtatsDemandeActionFichier();
		const etat = etatsDemande.find(one => {return one.edf_code == edf_code;});
		if (etat) {
			return  etat.edf_libelle;
		}
		return edf_code? edf_code.toString() : '';
	}
}
