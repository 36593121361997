<form #signatureForm="ngForm">

	<div class="mb-4">
		<h5>Nature de la signature</h5>
		<div class="formgroup-inline">
			<div class="field-radiobutton">
				<p-radioButton
					inputId="contrat"
					name="tsi_code"
					label="Contrat"
					value="C"
					[(ngModel)]="signature.tsi_code"
					[disabled]="hasSignatureContrat"
				></p-radioButton>
			</div>
			<div class="field-radiobutton">
				<p-radioButton
					id="avenant"
					name="tsi_code"
					label="Avenant"
					value="A"
					[(ngModel)]="signature.tsi_code"
				></p-radioButton>
			</div>
		</div>

		<p-message
			styleClass="w-full justify-content-start"
			[escape]="false"
			severity="info"
			[text]="signature.tsi_code == 'C'? helpTypeSignatureContrat : helpTypeSignatureAvenant"
		></p-message>
	</div>

	<div *ngIf="fonctionnaliteSignatureElectronique">
		<h5>Type de signature</h5>
		<div class="formgroup-inline">
			<div class="field-radiobutton">
				<p-radioButton
					inputId="manuelle"
					label="Manuelle"
					name="sig_electronique"
					[value]="false"
					[(ngModel)]="signature.sig_electronique"
					[disabled]="fonctionnaliteSignatureElectronique && signatureElectroniqueObligatoire"
				></p-radioButton>

			</div>
			<div class="field-radiobutton">
				<p-radioButton
					id="electronique"
					label="Electronique"
					name="sig_electronique"
					[value]="true"
					[(ngModel)]="signature.sig_electronique"
					[disabled]="!fonctionnaliteSignatureElectronique || (fonctionnaliteSignatureElectronique && signatureElectroniqueObligatoire)"
				></p-radioButton>
			</div>
		</div>
		<p-message
			styleClass="w-full justify-content-start"
			[escape]="false"
			severity="info"
			[text]="signature.sig_electronique? helpSignatureElectronique : helpSignatureManuelle"
		></p-message>
	</div>

	<div class="mt-2" *ngIf="warningMessage">
		<p-message
			class="block"
			styleClass="w-full justify-content-start"
			[escape]="false"
			severity="warn"
			[text]="warningMessage"
		></p-message>
	</div>

	<div class="text-center grid flex justify-content-center mt-3">
		<button
			type="button"
			pButton
			class="p-button-outlined mr-2"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			label="Valider"
			[loading]="loading"
			(click)="submit(false)"
		></button>
	</div>

</form>
