import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from '@uirouter/core';

import { ConfirmationService } from 'primeng/api';

import { CodesInternesService } from '@global/codes-internes.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';

import { GroupeContexteService, GrcModule } from '@app/groupe-contexte/groupe-contexte.service';

import { environment } from '@environments/environment';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class UtilisateurService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private currentUtilisateurSubject = new BehaviorSubject<any>(null);
	public currentUtilisateur$ = this.currentUtilisateurSubject.asObservable();

	public civilites: any[] = [
		{long: 'Monsieur', short: 'M.'},
		{long: 'Madame', short: 'Mme'}
	];

	constructor(
		private codesInternesService: CodesInternesService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private groupeContexteService: GroupeContexteService,
		private http: HttpClient,
		private portailService: PortailService,
		private stateService: StateService,
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.currentUtilisateurSubject.next(null);
			}
		);

		this.eventManager.registerEvent('refreshCurrentUser', this, (args: any) => {
				this.getCurrentUtilisateur().subscribe(response => {
					if (this.currentUtilisateurValue.expiration_mot_de_passe != null && this.currentUtilisateurValue.expiration_mot_de_passe <= 0) {
						this.stateService.go('portail.change_password', undefined, { location: false });
					}
				});
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('refreshCurrentUser', this);
	}

	public getCivilites(format?: string) {
		let temp: any[]= [];
		for (let one of this.civilites) {
			if (format && one.hasOwnProperty(format))
				temp.push({label: one[format], value: one[format]});
			else temp.push(one);
		}
		return temp;
	}

	public getDummy() {
		return new Utilisateur();
	}

	public prepareUtilisateurFromServer(utilisateur: Utilisateur) {
		let tmp: Utilisateur = clone(utilisateur);
		convertDateFieldsToDate(tmp);
		tmp.label = `${tmp.uti_prenom} ${tmp.uti_nom}`;
		tmp.label_full = `${tmp.label} (${tmp.uti_email})`;
		return tmp;
	}

	public prepareUtilisateursFromServer(utilisateurs: Utilisateur[]) {
		for (let i = 0; i < utilisateurs.length ; i++) {
			utilisateurs[i] = this.prepareUtilisateurFromServer(utilisateurs[i]);
		}
	}

	public getUtilisateurs(params: any, ctx_id?: number, grc_id?: number) {
		const tmpParams = prepareQueryParams(params);

		let url = this.portailService.getRoutePrefix(grc_id, ctx_id);
		url = `${url}/utilisateurs`;

		return this.http.get<any>(url, tmpParams)
		.pipe(map((response: any) => {
			this.prepareUtilisateursFromServer(response.utilisateurs);
			return response;
		}));
	}

	public get(uti_id: number, ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}`)
		.pipe(map((utilisateur: Utilisateur) => {
			utilisateur = this.prepareUtilisateurFromServer(utilisateur);
			return utilisateur;
		}));
	}

	public post(utilisateur: Utilisateur, ctx_id: number) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs`, utilisateur);
	}

	public put(utilisateur: Utilisateur, ctx_id: number) {
		return this.http.put<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${utilisateur.uti_id}`, utilisateur);
	}

	public delete(uti_id: number, ctx_id: number) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}`);
	}

	public deleteAccesContexte(uti_id: number, ctx_id: number) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${ctx_id}/acces_utilisateurs/${uti_id}`);
	}

	public get currentUtilisateurValue(): Utilisateur {
		return this.currentUtilisateurSubject.value;
	}

	public getDroitsSurContexte(uti_id: number, ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}/droits_utilisateurs`);
	}

	public setDroitsSurContexte(uti_id: number, ctx_id: number, droits: any) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}/droits`, droits);
	}

	public getAbonnementsContact(uti_id: number, ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}/abonnements_contacts`);
	}

	public setAbonnementsContact(uti_id: number, ctx_id: number, abonnements: any) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/utilisateurs/${uti_id}/abonnements_contacts`, abonnements);
	}

	public getCurrentUtilisateur() {
		return this.http.get<any>(`${environment.api_url}/utilisateurs/moi`)
		.pipe(
			map(utilisateur => {
				this.currentUtilisateurSubject.next(utilisateur);
				return utilisateur;
			})
		);
	}

	public putCurrentUser(profil: Utilisateur) {
		return this.http.put<any>(`${environment.api_url}/utilisateurs/moi`, profil)
		.pipe(
			map(response => {
				this.currentUtilisateurSubject.next(profil);
				return response;
			})
		);
	}

	public emailExists(uti_email: string, grc_id?: number) {
		if (grc_id) { // pour les admin fournisseurs/adhérents/ffg
			return this.http.get<any>(`${environment.api_url}/groupes_contextes/${grc_id}/utilisateurs/${uti_email}`);
		}
		else {
			return this.http.get<any>(`${environment.api_url}/utilisateur/${uti_email}`);
		}
	}

	public addUtilisateurToContexte(uti_id: number, ctx_id: number) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/acces_utilisateurs/${uti_id}`, null);
	}

	public acceptRGPD() {
		return this.http.post<any>(`${environment.api_url}/utilisateurs/moi/accord_rgpd`, null);
	}

	public getAnnuaireEqip(params: any) {
		return this.http.get<any>(`${environment.api_url}/utilisateurs_eqip`, prepareQueryParams(params));
	}

	public exportAnnuaireEqip() {
		return this.http.get<any>(`${environment.api_url}/export_utilisateurs_eqip`, prepareQueryParamsForDownload());
	}

	public exportList(params: any, ctx_id?: number) {
		let tmpParams = prepareQueryParamsForDownload(params);
		let url = (ctx_id)? `${environment.api_url}/contextes/${ctx_id}` : `${environment.api_url}` ;
		url += '/export_utilisateurs';
		return this.http.get<any>(url, tmpParams);
	}

	public getAnnuaireContacts(params: any, portail: PortailParam, participantModule?: GrcModule): any{
		let tmpParams = this.groupeContexteService.maybeAddGrcModule(params, participantModule);
		let por_id = this.portailService.getPortailId(portail);
		let url = `${environment.api_url}/portails/${por_id}/annuaires`;
		return this.http.get<any>(url, prepareQueryParams(tmpParams))
		.pipe(map((response: any) => {
			this.codesInternesService.formatLabels(response.annuaires);
			return response;
		}));
	}

	public exportAnnuaireContacts(params: any, portail: PortailParam, participantModule?: GrcModule): any {
		let tmpParams = this.groupeContexteService.maybeAddGrcModule(params, participantModule);
		let por_id = this.portailService.getPortailId(portail);
		let url = `${environment.api_url}/portails/${por_id}/annuaires/export`;
		return this.http.get<any>(url, prepareQueryParams(tmpParams, true));
	}

	public getResponsablesNego(params: any, ctx_id?: number, grc_id?: number) {
		const tmpParams = prepareQueryParams(params);

		let url = this.portailService.getRoutePrefix(grc_id, ctx_id);
		url = `${url}/responsables_negociation`;

		return this.http.get<any>(url, tmpParams);
	}


	public disconnect(utilisateur?: Utilisateur) {
		let question: string = `Souhaitez-vous vraiment déconnecter toutes vos sessions ?`;
		if (utilisateur) {
			question = `Souhaitez-vous vraiment déconnecter toutes less sessions de ${utilisateur.label} ?`;
		}

		return new Observable<any>((sub: Subscriber<any>) => {
			this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: question,
				accept: () => {
					const url: string = `${environment.api_url}/auth/deconnecter_tout`;
					const payload: any = {
						uti_id: utilisateur? utilisateur.uti_id : this.currentUtilisateurValue.uti_id
					}
					this.http.post<any>(url, payload)
					.subscribe({
						next: () => {
							if (utilisateur) {
								this.eventManager.emit('toast', {severity: 'success', summary: 'Sessions déconnectées'});
							}
							else {
								this.eventManager.emit('logout');
							}
						}
					})
					.add(() => {
						sub.next(true);
						sub.complete();
					})
				},
				reject: () => {
					sub.next(true);
					sub.complete();
				}
			});
		});
	}

}
