import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';
import { periodicites } from '@helpers/utils';

@Pipe({name: 'periodicite'})
export class PeriodicitePipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(periodicite: string|number, typeLibelle?: string): string {
		let options;

		let found;
		if (typeof periodicite == 'string') {
			options = this.conventionService.periodicites;
			found = options.find((one: any) => {
				return one.per_code == periodicite;
			});
			if (found) {
				return found.per_libelle;
			}
		}
		else {
			options = periodicites;
			found = options.find((one: any) => {
				return one.value == periodicite;
			});
			if (found && typeLibelle){
				return found[typeLibelle];
			}
		}

		return periodicite? periodicite.toString() : '';
	}
}
