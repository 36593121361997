import { Pipe, PipeTransform } from '@angular/core';

import { AgenceService } from '@app/agence/agence.service';
import { TypeAgence } from '@app/agence/type-agence.model';

@Pipe({name: 'typeAgence'})
export class TypeAgencePipe implements PipeTransform {

	constructor(private agenceService: AgenceService) {}

	transform(tag_id: number): string {
		const list = this.agenceService.getTypesAgence;
		const found = list.find((one: TypeAgence) => {return one.tag_id == tag_id;});
		if (found) {
			return  found.tag_libelle;
		}
		return tag_id? tag_id.toString() : '';
	}
}
