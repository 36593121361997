import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PortailService } from '@app/portail/portail.service';


// hide an element if user doesn't have enough rights
@Directive({
	selector: '[ifHasRight]'
})
export class ifHasRightDirective {
	@Input() set ifHasRight(droits: any[]) {
		if (this.portailService.checkIfHasRight(droits) === true) {
			this.container.createEmbeddedView(this.templateRef);
		}
		else {
			this.container.clear();
		}
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private container: ViewContainerRef,
		private portailService: PortailService
	) {

	}
}
