import { Pipe, PipeTransform } from '@angular/core';

import { Pays } from '@app/pays/pays.model';
import { PaysService } from '@app/pays/pays.service';
import { isObject } from '@helpers/utils';

@Pipe({name: 'pays'})
export class PaysPipe implements PipeTransform {

	paysList: Pays[];

	constructor(private paysService: PaysService) {}

	transform(pays?: number|number[]|Pays|Pays[], asCode: boolean =  false, separator: string = ', '): Promise<string> {
		return this.paysService.getCachedList()
		.toPromise().then(
			(response: any[]) => {
				this.paysList = response;
				if (Array.isArray(pays)) {
					let result: string[] = [];
					pays.forEach((one: number|Pays) => {
						let tmp: string = this.transformOne(one);
						if (tmp) {
							result.push(tmp);
						}
					});
					result.sort();
					return result.join(separator);
				}
				return pays? this.transformOne(pays) : '';
			},
			(err: any) => {
				return pays? pays.toString() : '';
			}
		)
	}

	transformOne(pays: number|Pays, asCode:boolean = false) {
		let found: any;
		if (typeof pays == 'number') {
			found = this.paysList.find((one: Pays) => {return one.pay_id == pays;});
		}
		else if (isObject(pays)) {
			found = this.paysList.find((one: Pays) => {return one.pay_id == pays.pay_id;});
		}
		if (found) {
			return asCode? found.pay_code : found.pay_nom;
		}
		return '';
	}
}
