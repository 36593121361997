import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';

@Pipe({name: 'typeReglement'})
export class TypeReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(trg_code?: string): string {
		const typesReglement = this.comptaService.typesReglements;
		const etat = typesReglement.find(one => {return one.trg_code == trg_code;});
		if (etat) {
			return  etat.trg_libelle;
		}
		return trg_code? trg_code.toString() : '';
	}
}
