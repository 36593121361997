import { Pipe, PipeTransform } from '@angular/core';

import { CategorieFichierFournisseur } from '@app/document/categorie-fichier-fournisseur.model';
import { DocumentService } from '@app/document/document.service';

@Pipe({name: 'categorieFichierFournisseur'})
export class CategorieFichierFournisseurPipe implements PipeTransform {

	constructor(private documentService: DocumentService) {}

	transform(cff_id?: number): Promise<string> {
		return this.documentService.getCategoriesFichierFournisseur()
		.toPromise().then(
			categories => {
				const cat = categories.find((one: CategorieFichierFournisseur) => {return one.cff_id == cff_id;});
				if (cat) {
					return  cat.cff_libelle;
				}
				return cff_id? cff_id.toString() : '';
			},
			err => {
				return cff_id? cff_id.toString() : '';
			}
		)

	}
}
