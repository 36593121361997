<form class="mb-5" #demandeAjoutAgenceForm="ngForm">
	<div class="p-fluid formgrid grid">
		<div class="field col-12 md:col-6 xl:col-4">
			<label for="uti_email">Filiale concernée</label>
			<contexte-selector
				#filialeSelector
				name="ctx_fil_id"
				[(ngModel)]="noa_json_source.ctx_fil_id"
				portail="adherent"
				[ctx_id_reference]="ctx_id"
				[required]="true"
			></contexte-selector>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="uti_email">Type d'agence</label>
			<type-agence-selector
				name="tag_id"
				[(ngModel)]="noa_json_source.tag_id"
			></type-agence-selector>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="soc_siret">N° d'identifiant société (SIREN, SIRET, ...)</label>
			<input
				type="text"
				pInputText
				name="soc_siret"
				[(ngModel)]="noa_json_source.soc_siret"
				placeholder="Majuscules et chiffres uniquement"
				pattern="[A-Z0-9]+"
				oninput="this.value = this.value.toUpperCase()"
			>
		</div>

		<div class="field col-12 md:col-6 xl:col-4">
			<label for="soc_raison_sociale">Raison sociale</label>
			<input
				type="text"
				pInputText
				name="soc_raison_sociale"
				[(ngModel)]="noa_json_source.soc_raison_sociale"
				placeholder="Raison sociale"
			>
		</div>

		<div class="field col-12">
			<adresse-form
				name="adresse"
				[(ngModel)]="noa_json_source.adresse"
			></adresse-form>
		</div>

	</div>

	<div class="p-fluid formgrid grid">
		<div class="field col-12 md:col-12">
			<label for="commentaire">Commentaire</label>
			<textarea
				pInputTextarea
				name="commentaire"
				#commentaire
				[(ngModel)]="noa_description"
				[autoResize]="true"
				[rows]="9"
				[cols]="30"
				placeholder="Merci de préciser toute information susceptible d'aider au traitement de votre demande."
			></textarea>
		</div>
	</div>

	<div class="text-center">
		<button
			type="button"
			pButton
			class="p-button-outlined mr-2"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			label="Envoyer la demande"
			[loading]="loading"
			[disabled]="!demandeAjoutAgenceForm.form.valid"
			(click)="submit()"
		></button>
	</div>
</form>
