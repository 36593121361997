import { NgModule, Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TargetState } from '@uirouter/core';

import { ChangePasswordConnectedFormModule } from './change-password-connected-form';

@Component({
	selector: 'change-password-connected',
	templateUrl: 'change-password-connected.html'
})
export class ChangePasswordConnectedComponent implements OnInit {

	@Input() returnTo: TargetState;

	constructor() {

	}

	ngOnInit() {

	}


}

@NgModule({
	imports: [
		CommonModule,
		ChangePasswordConnectedFormModule,
	],
	exports: [ChangePasswordConnectedComponent],
	declarations: [ChangePasswordConnectedComponent]
})
export class ChangePasswordConnectedModule { }

