import { Pipe, PipeTransform } from '@angular/core';

import { DocumentService } from '@app/document/document.service';

@Pipe({name: 'actionFichier'})
export class ActionFichierPipe implements PipeTransform {

	constructor(private documentService: DocumentService) {}

	transform(tdf_code?: string): string {
		const actions = this.documentService.getActionsFichier();
		const type = actions.find(one => {return one.tdf_code == tdf_code;});
		if (type) {
			return  type.tdf_libelle;
		}
		return tdf_code? tdf_code.toString() : '';
	}
}
