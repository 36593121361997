<div *ngIf="!portailsAccessibles.length" class="text-center p-5">
	<h3>Vous n'avez pas les droits requis pour utiliser l'application. Veuillez contacter un administrateur.</h3>
</div>

<div *ngIf="portailsAccessibles.length">
	<h3 class="text-center p-5">Veuillez sélectionner un portail</h3>

	<div class="grid justify-content-center m-0">
		<a
			*ngFor="let portail of portailsAccessibles"
			class="col-fixed portail"
			[uiSref]="'portail-' + portail.por_code"
		>
			<p-card styleClass="flex flex-column">
				<p-header class="flex align-items-center">
					<logo-display
						[entity]="portail"
						[listing]="false"
						size="st"
						[placeholderIfNone]="true"
						[placeholderColor]="true"
					></logo-display>
				</p-header>
				<div class="text-center">
					{{portail.por_libelle}}
				</div>
			</p-card>
		</a>
	</div>
</div>
