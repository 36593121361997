import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';
import { StateService, TargetState } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { PasswordModule } from 'primeng/password';

import { AuthService } from '@app/auth/auth.service';
import { EqipModule } from '@global/eqip.module';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { MustMatch } from '@helpers/must-match.validator';
import { ParametreService } from '@app/parametre/parametre.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';


@Component({
	selector: 'change-password-connected-form',
	templateUrl: 'change-password-connected-form.html'
})
export class ChangePasswordConnectedFormComponent implements OnInit {

	@Input() returnTo: TargetState;

	changePasswordForm: FormGroup;
	submitted = false;
	loading = false;
	error: string = '';
	expires: number;

	criterias: any = {
		nb_letters: {pattern: /[a-zA-Z]+/, result: -1},
		nb_numbers: {pattern: /\d+/, result: -1},
		uppercase: {pattern: /[A-Z]+/, result: -1},
		lowercase: {pattern: /[a-z]+/, result: -1},
		nb_chars: {pattern: /.{8,}/, result: -1},
	}

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private formBuilder: FormBuilder,
		private parametreService: ParametreService,
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
	) {
		this.expires = this.utilisateurService.currentUtilisateurValue.expiration_mot_de_passe || 1;
	}

	ngOnInit() {
		const passwordPattern = new RegExp(this.parametreService.getParam('password_pattern'));
		this.changePasswordForm = this.formBuilder.group({
			currentPassword: [null, [Validators.required]],
			password: [null, [Validators.required, Validators.pattern(passwordPattern)] ],
			passwordConfirm: [null, Validators.required],
		},
		{
			validator: MustMatch('password', 'passwordConfirm')
		});
	}

	get f() { return this.changePasswordForm.controls; }

	onSubmit() {
		this.submitted = true;
		this.loading = true;
		this.error = '';
		this.authService.changePasswordConnected(this.f.currentPassword.value, this.f.password.value, this.f.passwordConfirm.value)
		.subscribe(
			ok => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Mot de passe mis à jour'});
				this.eventManager.emit('logout');
			},
			httpResponse => {
				if (httpResponse.error && httpResponse.error.data && httpResponse.error.data.essai) {
					if (httpResponse.error.data.bloque) {
						this.error = `Ce compte est bloqué suite à de multiples tentatives de connexion échouées. Veuillez contacter un administrateur.`;
					}
					else {
						this.error = `${httpResponse.error.data.essai} tentative(s) sur ${httpResponse.error.data.max}`;
					}
				}
				else if (httpResponse.error.message) {
					this.error = httpResponse.error.message;
				}
				else {
					this.error = httpResponse.error;
				}

			}
		).add(() => { this.loading = false; });
	}

	criteriaChecked(pattern: string): number {
		if (!this.f.password.value) {
			return -1;
		}
		else {
			if (this.f.password.value.match(pattern)) {
				return 1;
			}
			return 0;
		}
	}

	checkCriterias() {
		for (let criteria in this.criterias) {
			this.criterias[criteria].result = this.criteriaChecked(this.criterias[criteria].pattern);
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		GlobalModule,
		ReactiveFormsModule,
		ButtonModule,
		PasswordModule,
		InputTextModule,
		MessageModule,
		EqipModule,
	],
	exports: [ChangePasswordConnectedFormComponent],
	declarations: [ChangePasswordConnectedFormComponent]
})
export class ChangePasswordConnectedFormModule { }

