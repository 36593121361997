import { Pipe, PipeTransform } from '@angular/core';

import { SignatureService } from '@app/signature/signature.service';

@Pipe({name: 'typeSignature'})
export class TypeSignaturePipe implements PipeTransform {

	constructor(private signatureService: SignatureService) {}

	transform(tsi_code: string, short?: boolean): string {
		const typesSignature = this.signatureService.typesSignature;
		const type = typesSignature.find(one => {return one.tsi_code == tsi_code;});
		if (type) {
			if (short) {
				let tmp = type.tsi_libelle.split(' ').pop();
				if (tmp) {
					return tmp.charAt(0).toUpperCase() + tmp.slice(1);
				}
			}
			return  type.tsi_libelle;
		}
		return tsi_code.toString();
	}
}
