import { ExtensibleObject } from '@helpers/utils';

export class DestinataireSignature extends ExtensibleObject {

	sig_id: number = 0;

	des_id: number = 0;
	get id(): number { return this.des_id; }
	set id(value: number) { this.des_id = value; }

	des_email: string|null = null;
	get email(): string|null {return this.des_email;}
	set email(value: string|null) {this.des_email = value;}

	des_tel: string|null = null;
	get tel(): string|null { return this.des_tel; }
	set tel(value: string|null) { this.des_tel = value; }

	des_civilite: string = '';
	get civilite(): string { return this.des_civilite; }
	set civilite(value: string) { this.des_civilite = value; }

	des_identite: string = '';
	get identite(): string { return this.des_identite; }
	set identite(value: string) { this.des_identite = value; }

	des_fonction: string = '';
	get fonction(): string { return this.des_fonction; }
	set fonction(value: string) { this.des_fonction = value; }

	des_groupement: boolean = false;
	get groupement(): boolean { return this.des_groupement; }
	set groupement(value: boolean) { this.des_groupement = value; }

	des_relecteur: boolean = false;
	get relecteur(): boolean { return this.des_relecteur; }
	set relecteur(value: boolean) { this.des_relecteur = value; }

	des_validation_sms: boolean = false;
	get validation_sms(): boolean { return this.des_validation_sms; }
	set validation_sms(value: boolean) { this.des_validation_sms = value; }

	des_ordre: number = 0;
	get ordre(): number { return this.des_ordre; }
	set ordre(value: number) { this.des_ordre = value; }

	des_date_envoi: string|Date|null = null;
	get date_envoi(): string|Date|null { return this.des_date_envoi; }
	set date_envoi(value: string|Date|null) {
		this.des_date_envoi = value;
	}

	des_date_lecture: string|Date|null = null;
	get date_lecture(): string|Date|null { return this.des_date_lecture; }
	set date_lecture(value: string|Date|null) {
		this.des_date_lecture = value;
	}

	des_date_refus: string|Date|null = null;
	get date_refus(): string|Date|null { return this.des_date_refus; }
	set date_refus(value: string|Date|null) {
		this.des_date_refus = value;
	}

	des_date_signature: string|Date|null = null;
	get date_signature(): string|Date|null { return this.des_date_signature; }
	set date_signature(value: string|Date|null) {
		this.des_date_signature = value;
	}

	label: string = '';

	constructor(data?: DestinataireSignature) {
		super();
		if (data) {
			this.assign(data);
		}
	}

	assign(data: DestinataireSignature) {
		for (let prop in this) {
			if (typeof data[prop] != 'undefined') {
				this[prop as string] = data[prop];
			}
		}
		this.label = `${this.civilite} ${this.identite}`;
	}
}
