import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import { CodesInternesService } from '@global/codes-internes.service';
import { GroupeContexte } from '@app/groupe-contexte/groupe-contexte.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { SocieteService } from '@app/societe/societe.service';

import { clone, convertDateFieldsToDate } from '@helpers/utils';


// #8589 pour ne récupérer que les contextes dont les groupes participent à tel module. Ex. 'fournisseur' pour récupérer les filiales affectables à une convention FDQ; 'ca_groupe_adh' pour récupérer les filiales qui peuvent voir et saisir du CA pour leur groupe.
export type GrcModule = 'ca_groupe_adh'|'fournisseur'|'frais_generaux';

@Injectable({ providedIn: 'root' })
export class GroupeContexteService {

	constructor(
		protected http: HttpClient,
		protected codesInternesService: CodesInternesService,
		protected societeService: SocieteService,
		protected portailService: PortailService
	) {

	}

	public getDummy() {
		return new GroupeContexte();
	}

	public maybeAddGrcModule(obj: any, participantModule?: GrcModule) {
		let tmp = clone(obj);
		if (participantModule) {
			tmp[`grc_module_${participantModule}`] = true;
		}
		return tmp;
	}

	public prepare(obj: GroupeContexte) {
		const prepared = clone(obj);
		prepared.societe = this.societeService.prepareForSubmit(obj.societe);
		return prepared;
	}

	public prepareGroupeFromServer(groupe: GroupeContexte): GroupeContexte {
		let tmp = clone(groupe);
		convertDateFieldsToDate(tmp);
		tmp.societe = this.societeService.prepareFromServer(tmp.societe);
		this.codesInternesService.formatLabel(tmp);
		if (!tmp.ip_requises) tmp.ip_requises = [];
		return tmp;
	}

	public prepareGroupesFromServer(groupes: GroupeContexte[]): void {
		for (let i = 0; i < groupes.length ; i++) {
			groupes[i] = this.prepareGroupeFromServer(groupes[i]);
		}
	}

	public getList(
		params: any,
		portail?: PortailParam,
		grc_id_reference?: number,
		ctx_id_reference?: number,
		participantModule?: GrcModule,
	) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = this.maybeAddGrcModule(tmpParams, participantModule);

		let url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		if (grc_id_reference || ctx_id_reference) {
			// récupération des groupes_contextes liés à un groupe via des partenariats
			// ou des groupes_contextes liés à contextes via des partenariats
			url += `${url}/groupes_contextes_lies`;
		}
		else {
			url += '/groupes_contextes';
		}
		return this.http.get<any>(url, prepareQueryParams(tmpParams))
		.pipe(map((response: any) => {
			this.prepareGroupesFromServer(response.groupes_contextes);
			return response;
		}));
	}

	public get(grc_id: number) {
		return this.http.get<any>(`${environment.api_url}/groupes_contextes/${grc_id}`)
		.pipe(map(response => {
			response = this.prepareGroupeFromServer(response);
			return response;
		}));
	}

	public post(groupeContexte: GroupeContexte, portail?: PortailParam) {
		let prepared = this.prepare(this.portailService.maybeAddPorIdToObject(groupeContexte, portail));
		return this.http.post<GroupeContexte>(`${environment.api_url}/groupes_contextes`, prepared);
	}

	public put(groupeContexte: GroupeContexte) {
		let prepared = this.prepare(groupeContexte);
		return this.http.put<GroupeContexte>(`${environment.api_url}/groupes_contextes/${prepared.grc_id}`, prepared);
	}

	public delete(grc_id: number) {
		return this.http.delete<any>(`${environment.api_url}/groupes_contextes/${grc_id}`);
	}

	public exportList(params: any, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		tmpParams = prepareQueryParamsForDownload(tmpParams);
		return this.http.get<any>(`${environment.api_url}/groupes_contextes/export`, tmpParams);
	}

}
