<h4 class="text-center">Mot de passe oublié</h4>
<p class="mb-4">Veuillez saisir l'adresse email du compte pour lequel vous souhaitez réinitialiser le mot de passe. Vous recevrez un email contenant des instructions pour changer votre mot de passe.</p>
<form class="p-fluid mb-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
	<div class="form-group field">
		<label>Adresse email</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
			<input
				type="email"
				pInputText
				formControlName="email"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.email.errors }"
				placeholder="email@exemple.com"
			>
		</div>
		<div *ngIf="submitted && f.email.errors" class="invalid-feedback p-invalid">
			<small *ngIf="f.email.errors.required" >Email requis</small>
			<small *ngIf="f.email.errors.email">Adresse email invalide</small>
		</div>
	</div>

	<p-message severity="error" text="{{error}}" *ngIf="error && !loading"></p-message>

	<button
		type="submit"
		pButton
		label="Réinitialiser le mot de passe"
		[disabled]="!resetPasswordForm.valid || loading"
		*ngIf="!success"
	></button>
	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

	<p-message severity="success" text="{{messageSuccess}}" *ngIf="success"></p-message>

</form>

<p class="text-center">
	<a uiSref="auth.login">Retourner au formulaire de connexion</a>
</p>
