import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiOnExit, Transition, HookResult } from '@uirouter/angular';
import { Observable } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { Contexte } from '@app/contexte/contexte.model';
import { PortailService } from '@app/portail/portail.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurAbonnementsContactModule } from '@app/utilisateur/utilisateur-abonnements-contact';
import { UtilisateurDroitsContexteModule } from '@app/utilisateur/utilisateur-droits-contexte';
import { UtilisateurFormModule } from './utilisateur-form';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { localeFR } from '@helpers/locale-fr';

@Component({
	selector: 'utilisateur-form-admin',
	templateUrl: './utilisateur-form-admin.html'
})
export class UtilisateurFormAdminComponent implements OnInit, UiOnExit {

	@Input() utilisateur: Utilisateur;
	@Input() ctx_id: number;
	@Input() contexte: Contexte;

	droitsCanEdit: any[] = [
		{dro_code: 'administration', dru_niveau: 2},
	];
	canEdit: boolean = false;
	selfEditing: boolean;

	formDirtyStatus: {[key: string]: any} = {
		droits: false,
		utilisateur: false,
		abonnements: false
	}

	constructor(
		private portailService: PortailService,
		private utilisateurService: UtilisateurService,
		private confirmationService: ConfirmationService
	) { }

	ngOnInit(): void {

		this.selfEditing = this.utilisateurService.currentUtilisateurValue.uti_id == this.utilisateur.uti_id;

		this.droitsCanEdit.push(
			{
				por_code: 'adherent',
				dro_code: ['acces_fournisseurs', 'admin_partenariat_fournisseur'],
				targetEntityOrPortail: this.contexte,
				targetRequiredPortail: 'fournisseur',
			},
			{
				por_code: 'adherent',
				dro_code: ['acces_fg', 'admin_partenariat_fg'],
				targetEntityOrPortail: this.contexte,
				targetRequiredPortail: 'frais_generaux',
			},
		);

		this.canEdit = this.portailService.checkIfHasRight(this.droitsCanEdit);
		if (!this.contexte) {
			this.portailService.getContexte(this.ctx_id)
			.subscribe(contexte => this.contexte = contexte);
		}
	}

	uiCanExit(newTransition?: Transition): HookResult {
		let dirty = false;
		for(let prop in this.formDirtyStatus) {
			if (this.formDirtyStatus[prop]) dirty = true;
		}
		if (dirty) {
			return new Observable<any>(sub => {
				this.confirmationService.confirm({
					defaultFocus: 'reject',
					header: localeFR.leavingUnsavedFormConfirmHeader,
					message: localeFR.leavingUnsavedFormConfirmMessage,
					accept: () => {
						sub.next(true);
						sub.complete();
					},
					reject: () => {
						sub.next(false);
						sub.complete();
					}
				});
			}).toPromise();
		}
	}


}


@NgModule({
	imports: [
		CommonModule,
		UtilisateurFormModule,
		UtilisateurDroitsContexteModule,
		UtilisateurAbonnementsContactModule,
	],
	exports: [UtilisateurFormAdminComponent],
	declarations: [UtilisateurFormAdminComponent]
})
export class UtilisateurFormAdminModule { }

