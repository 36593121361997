import { NgModule, Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { TargetState } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';

import { InputOtpModule } from '@app/primeng-overrides/inputotp';

import { AuthService } from '@app/auth/auth.service';
import { StateUtilsService } from '@helpers/state-utils.service';
import { EventManagerService } from '@global/event-manager.service';

@Component({
	selector: 'login-2fa',
	templateUrl: 'login-2fa.html'
})
export class Login2faComponent implements OnInit {

	@Input() returnTo: TargetState;

	@ViewChild('login2faForm', { static: true }) login2faForm: NgForm;

	submitted = false;
	loading = false;

	code2fa: string;

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private stateUtils: StateUtilsService
	) {

	}

	ngOnInit(): void {

	}

	logout(): void {
		this.authService.logout({manual: true});
	}

	submit(): void {
		this.submitted = true;
		if (this.login2faForm.invalid) return;
		this.loading = true;
		this.authService.challenge2fa(this.code2fa)
		.subscribe({
			next: (response: any) => {
				if (!!response) {
					this.stateUtils.returnToState(this.returnTo);
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	resend(): void {
		this.loading = true;
		this.authService.resend2faCode()
		.subscribe({
			next: (response: any) => {
				if (!!response) {
					this.eventManager.emit('toast', {severity: 'success', summary: 'Nouveau code envoyé'});
				}
			}
		})
		.add(() => { this.loading = false; });
	}

}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		ProgressBarModule,
		InputOtpModule,
	],
	exports: [Login2faComponent],
	declarations: [Login2faComponent]
})
export class Login2faModule { }
