import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class ApiJwtInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const currentToken = this.authService.token;
		const hasToken = currentToken && currentToken.token;
		const isApiUrl = request.url.startsWith(environment.api_url);
		if (hasToken && isApiUrl) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentToken.token}`
				}
			});
		}
		return next.handle(request);
	}
}
