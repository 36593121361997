
<div class="text-center p-5">
	<h3>Changement de mot de passe</h3>
</div>

<div class="grid">
	<div class="col-12 sm:col-6 m-auto p-card p-5">
		<change-password-connected-form class="block" [returnTo]="returnTo"></change-password-connected-form>
	</div>
</div>
