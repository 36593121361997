import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { AppBreadcrumbsComponent } from '@global/app-breadcrumbs.component';
import { AppMenuModule } from '@global/app-menu';
import { AppTopbarModule } from '@global/app-topbar';

import { PortailRootComponent } from '@app/portail/portail-root.component';
import { ProfilDialogModule } from '@app/utilisateur/utilisateur-form';
import { SelectionPortailModule } from '@app/portail/selection-portail';

import { GzPanelModule } from '@app/components/gz-panel';

import { GlobalModule } from '@global/global.module';
import { EqipModule } from '@global/eqip.module';


@Component({
	selector: 'portail',
	template: `
		<app-topbar></app-topbar>
		<div class="layout-content">
			<ui-view [ngClass]="'portail-layout'">content</ui-view>
		</div>
	`
})
export class PortailComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}


@NgModule({
	declarations: [
		PortailComponent,
		PortailRootComponent,
		AppBreadcrumbsComponent,
	],
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		SelectionPortailModule,
		AppMenuModule,
		ProfilDialogModule,
		AppTopbarModule,
		EqipModule,
	],
	exports: [
		CommonModule,
		GlobalModule,
		PortailComponent,
		PortailRootComponent,
		AppBreadcrumbsComponent,
		ProfilDialogModule,
		AppMenuModule,
		SelectionPortailModule,
		EqipModule,
	]
})
export class PortailModule { }
