<div class="p-fluid mb-2">
	<p-message severity="info" [text]="help" [escape]="false"></p-message>
</div>

<br>

<form class="mb-5" #alerteRectificationCAForm="ngForm">
	<div class="p-fluid formgrid grid">
		<div class="field col-fixed">
			<label>Année</label>
			<annee-convention-selector
				name="annee"
				[(ngModel)]="annee"
				[ctx_id]="ctx_id"
				[autoSelectCurrent]="true"
				[nullIfInvalid]="true"
				mode="adherent"
				tco_type="P"
				[portail]="portail"
				[reverse]="true"
				[disabled]="loading"
				[required]="true"
			></annee-convention-selector>
		</div>
		<div class="field col-fixed">
			<label for="con_id">Partenariat concerné</label>
			<convention-selector
				name="con_id"
				[(ngModel)]="con_id"
				[ctx_id]="ctx_id"
				[annee]="annee"
				tco_type="P"
				[portail]="portail"
				[showPartenariatName]="true"
				[disabled]="loading"
				[autoSelectCurrent]="false"
				[required]="true"
				(ngModelChange)="loadData()"
			></convention-selector>
		</div>

	</div>

				<div class="p-fluid mb-2" *ngIf="error">
				<p-message severity="error" [text]="error" [escape]="false"></p-message>
			</div>
	<div class="p-fluid formgrid grid" *ngIf="con_id && contacts && contacts.length">
		<div class="field col-12">
			<label>Les contacts suivants seront notifiés</label>
			<p-chips
				name="contacts"
				[(ngModel)]="contacts"
				[disabled]="true"
			>
				<ng-template let-item pTemplate="item">
					{{ item.uti_prenom }} {{ item.uti_nom }} <{{ item.uti_email }}>
				</ng-template>
			</p-chips>

		</div>

		<div class="field col-12 md:col-12">
			<label for="message">Message <button type="button" pButton icon="pi pi-undo" class="p-button-xs p-button-text" (click)="loadData()" title="Remettre le message par défaut"></button></label>
			<textarea
				pInputTextarea
				name="message"
				[(ngModel)]="message"
				[autoResize]="true"
				[rows]="9"
				[cols]="30"
				[required]="true"
				[disabled]="!con_id || loading || loadingData"
				placeholder="Merci de préciser toute information susceptible d'aider au traitement de votre demande."
			></textarea>
		</div>
	</div>

	<div class="text-center">
		<button
			type="button"
			pButton
			class="p-button-outlined mr-2"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			label="Envoyer la notification"
			[loading]="loading"
			[disabled]="!alerteRectificationCAForm.form.valid || loadingData || error"
			(click)="submit()"
		></button>
	</div>
</form>
