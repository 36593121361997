import { ExtensibleObject } from '@helpers/utils';
import { DestinataireSignature } from "@app/signature/destinataire-signature.model";
import { DocumentConvention } from "@app/document/document-convention.model";

export class Signature extends ExtensibleObject {
	sig_id: number = 0;
	con_id: number = 0;

	tsi_code: string = 'C'; // Contrat / Avenant
	sig_electronique: boolean = false;
	sig_date_creation: string|Date|null = null;
	sig_date_soumission: string|Date|null = null;
	sig_date_envoi: string|Date|null = null;
	sig_date_lecture: string|Date|null = null;
	sig_date_refus: string|Date|null = null;
	sig_date_signature: string|Date|null = null;

	signataires: DestinataireSignature[] = [];
	documents: DocumentConvention[] = [];
	evenements: any[] = [];

	etat: string = '';
	label: string = 'Signature';
}
