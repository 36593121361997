import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Subject, Subscriber, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { stringSort } from '@helpers/utils';
import { environment } from '@environments/environment';

export type ChampFusion = {
	cfu_code: string;
	cfu_libelle: string;
	cfu_description: string;
	cfu_contrat: boolean;
	cfu_relance: boolean;
	cfu_type: string|null;
}

@Injectable({ providedIn: 'root' })
export class ListesGeneriquesService {

	private listesGeneriquesSubject = new BehaviorSubject<any>(null);
	private champsFusionSubject = new BehaviorSubject<any>(null);

	constructor(private http: HttpClient) {

	}

	public get listesGeneriques(): any {
		return this.listesGeneriquesSubject.getValue();
	}

	public get champsFusion(): any {
		return this.champsFusionSubject.getValue();
	}

	public getChampsFusionType(typeChampFusion: 'relance'|'contrat'): Observable<any> {

		if (!this.champsFusion) {
			return this.getChampsFusion()
			.pipe(
				map(
					(response: any) => {
					return this.filterChampsFusions(typeChampFusion);
				}
			));
		}
		return new Observable<any>((subscriber: Subscriber<any>) => {
			subscriber.next(this.filterChampsFusions(typeChampFusion));
			subscriber.complete();
		});
	}

	public filterChampsFusions(typeChampFusion: 'relance'|'contrat') {
		let tmp: any[]= [];
		this.champsFusion.forEach((one: ChampFusion) => {
			if (one[`cfu_${typeChampFusion}`]) {
				tmp.push(one);
			}
		});
		return tmp;
	}

	public getListe(name: string): any[] {
		if (typeof this.listesGeneriques[name] == 'undefined') {
			console.log(`La liste générique n'existe pas: ${name}`);
			return [];
		}
		return this.listesGeneriques[name];
	}

	public getListesGeneriques() {
		return this.http.get<any>(`${environment.api_url}/listes_generiques`)
		.pipe(
			map(
				(response: any) => {
					this.listesGeneriquesSubject.next(response);
					return response;
				}
			)
		);
	}

	public getChampsFusion() {
		return this.http.get<any>(`${environment.api_url}/champs_fusions`)
		.pipe(
			map(
				(response: any) => {
					stringSort(response, 'cfu_libelle');
					this.champsFusionSubject.next(response);
					return response;
				}
			)
		);
	}

}
