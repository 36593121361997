import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { CasRistournePerformance } from '@app/ristourne/cas-ristourne-performance.model';
import { CodesInternesService } from '@global/codes-internes.service';
import { Convention } from '@app/convention/convention.model';
import { EtatConvention } from '@app/convention/etat-convention.model';
import { FilialeConvention } from '@app/filiale/filiale.model';
import { GroupeConditions } from '@app/convention/groupe-conditions.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { Marque } from '@app/marque/marque.model';
import { MatricePerformanceConvention } from '@app/matrice/matrice-performance-convention.model';
import { ModeCalcul } from '@app/ristourne/mode-calcul.model';
import { ModeCalculPrestation } from '@app/convention/mode-calcul-prestation.model';
import { ModeleParagraphe } from '@app/convention/modele-paragraphe.model';
import { ModeSaisieCA } from '@app/convention/mode-saisie-ca.model';
import { ModeVision } from '@app/portail/mode-vision';
import { ParagrapheConvention } from '@app/convention/paragraphe-convention.model';
import { ParametreService } from '@app/parametre/parametre.service';
import { Pays } from '@app/pays/pays.model';
import { PaysService } from '@app/pays/pays.service';
import { PeriodiciteAcompte } from '@app/convention/periodicite-acompte.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';
import { Prestation } from '@app/convention/prestation.model';
import { Ristourne } from '@app/ristourne/ristourne.model';
import { Segment } from '@app/convention/segment.model';
import { TcoType } from '@app/convention/type-convention.model';
import { TypeConvention } from '@app/convention/type-convention.model';
import { TypePerformance } from '@app/ristourne/type-performance.model';
import { TypePrestation } from '@app/convention/type-prestation.model';
import { TypeSegment } from '@app/convention/type-segment.model';

import { SignataireConvention } from '@app/signature/signataire-convention.model';
import { DestinataireSignature } from '@app/signature/destinataire-signature.model';

import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import {
	capitalize,
	clone,
	convertDateFieldsToDate, convertDateFieldsToString,
	downloadFromHttpResponse,
	endOfMonth,
	ExtensibleObject,
	numericSort,
	startOfMonth,
	stringSort,
	stripHTMLTags,
	uid
} from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class ConventionService {

	private _typesConvention: TypeConvention[];
	// [
	// 	{tco_type: 'C', tco_libelle: 'Convention cadre'},
	// 	{tco_type: 'P', tco_libelle: 'Convention périodique'},
	// ];

	private _etatsConvention: EtatConvention[];
	// [
	// 	{etc_code: 'R',tco_type: 'C', etc_libelle: 'En cours de rédaction'},
	// 	{etc_code: 'S',tco_type: 'C', etc_libelle: 'En signature'},
	// 	{etc_code: 'V',tco_type: 'C', etc_libelle: 'Validée et signée par le fournisseur'},
	// 	{etc_code: 'A',tco_type: 'P', etc_libelle: 'Active'},
	// 	{etc_code: 'C',tco_type: 'P', etc_libelle: 'Cloturée'},
	// ];

	private _modesSaisieCA: ModeSaisieCA[] = [
		{msc_code: 'A', msc_libelle: 'Saisie par agence'},
		{msc_code: 'F', msc_libelle: 'Saisie par filiale'},
	];

	private _periodicites: PeriodiciteAcompte[];
	// [
	// 	{per_code: 'A', per_libelle: 'Année', per_valeur: 12},
	// 	{per_code: 'M', per_libelle: 'Mois', per_valeur: 1},
	// 	{per_code: 'S', per_libelle: 'Semestre', per_valeur: 6},
	// 	{per_code: 'T', per_libelle: 'Trimestre', per_valeur: 3},
	// ];

	private _typesSegment: TypeSegment[];
	// [
	// 	{tsc_type: 'C', tsc_libelle: 'Challenge'},
	// 	{tsc_type: 'S', tsc_libelle: 'Segment de CA standard'},
	// ];

	private _typesPerformance: TypePerformance[];
	// [
	// 	{tpe_code: 'C', tpe_libelle: 'Collective', tsc_type: ['C', 'S']},
	// 	{tpe_code: 'F', tpe_libelle: 'Individuelle Filiale', tsc_type: ['C', 'S']},
	// 	{tpe_code: 'G', tpe_libelle: 'Individuelle Groupe', tsc_type: ['C', 'S']},
	// 	{tpe_code: 'P', tpe_libelle: 'Partenariat', tsc_type: ['S']},
	// ];

	private _modesCalcul: ModeCalcul[];
	// [
	// 	{mca_code: 'M', mca_libelle: 'Montant'},
	// 	{mca_code: 'P', mca_libelle: 'Prime'},
	// 	{mca_code: 'Q', mca_libelle: 'Quantité'},
	// ];

	private _casRistournePerformance: CasRistournePerformance[];
	// [
	// 	{crp_id: 0, crp_libelle: 'Valeur fixe'},
	// 	{crp_id: 1, crp_libelle: 'Paliers'},
	// 	{crp_id: 2, crp_libelle: 'Progressions'},
	// 	{crp_id: 3, crp_libelle: 'Addition paliers + progressions'},
	// 	{crp_id: 4, crp_libelle: 'Croisement paliers / progressions'},
	// 	{crp_id: 5, crp_libelle: 'Cumul par paliers'},
	// ];

	private _modelesParagraphes: ModeleParagraphe[] = [];
	public readonly patternCodeFusion: string = '[a-z0-9_]{1,30}';

	constructor(
		private codesInternesService: CodesInternesService,
		private datePipe: DatePipe,
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private parametreService: ParametreService,
		private paysService: PaysService,
		private portailService: PortailService,
	) {
		this._typesConvention = listesGeneriquesService.getListe('types_conventions');
		this._etatsConvention = listesGeneriquesService.getListe('etats_conventions');
		// this._modesSaisieCA = listesGeneriquesService.getListe('modes_saisies_ca');
		this._periodicites = listesGeneriquesService.getListe('periodicites');
		this._typesSegment = listesGeneriquesService.getListe('types_segments_ca');
		this._typesPerformance = listesGeneriquesService.getListe('types_performances_ristournes');
		this.prepareTypesPerformances(this._typesPerformance);
		this._modesCalcul = listesGeneriquesService.getListe('modes_calculs');
		this._casRistournePerformance = listesGeneriquesService.getListe('cas_ristournes_performances');
	}

	// certains type de performance ne doivent pas être disponible pour certain type de segment
	private prepareTypesPerformances(types: TypePerformance[]) {
		types.map((type: TypePerformance) => {
			if (type.tpe_code === 'P') { // perf partenariat indisponible sur les challenges
				type.tsc_type = ['S'];
			}
			else {
				type.tsc_type = ['C', 'S'];
			}
		});

	}

	public getDummy(tco_type: TcoType, ctx_id: number) {
		let convention: Convention = new Convention(tco_type, ctx_id);
		return convention;
	}

	public get typesConvention() {
		return [...this._typesConvention];
	}

	public getEtatsConvention(tco_type?: TcoType) {
		let tmp: EtatConvention[] = [];
		if (tco_type) {
			tmp = this._etatsConvention.filter(one => one.tco_type == tco_type);
		}
		else {
			tmp = [...this._etatsConvention];
		}
		return tmp;
	}

	public findEtatConvention(etc_code: string) {
		return this._etatsConvention.find((one: EtatConvention) => {return one.etc_code == etc_code;});
	}

	public get modesSaisieCA() {
		return [...this._modesSaisieCA];
	}

	public get periodicites() {
		return [...this._periodicites];
	}

	public get typesSegment() {
		return [...this._typesSegment];
	}

	public get modesCalcul() {
		return [...this._modesCalcul];
	}

	public get typesPerformance() {
		return [...this._typesPerformance];
	}

	public get casRistournePerformance() {
		return [...this._casRistournePerformance];
	}

	public segmentHasModeCalcul(segment: Segment, modeCalcul: 'montant'|'quantite', segments?: Segment[]): boolean {
		let mocCodes = ['M'];
		if (modeCalcul == 'montant') {
			mocCodes = ['M', 'Q'];
		}
		if (modeCalcul == 'quantite') {
			mocCodes = ['P', 'Q'];
		}

		// pour les segments standards on teste tous les segments du même groupe
		if (segment.tsc_type == 'S' && segments && segments.length) {
			return segments.filter((one: Segment) => {
				return one.tsc_type == 'S' && one.sca_groupe_condition == segment.sca_groupe_condition;
			})
			.some((one: Segment) => {
				return this.segmentHasModeCalcul(one, modeCalcul);
			});
		}

		return segment.ristournes
			&& !!segment.ristournes.length
			&& segment.ristournes.some((ristourne: Ristourne) => {
			return mocCodes.includes(ristourne.mca_code);
		});
	}

	public prestationHasModeCalcul(prestation: Prestation, modeCalcul: 'montant'|'quantite'): boolean {
		let mocCodes = ['M'];
		if (modeCalcul == 'quantite') {
			mocCodes = ['Q'];
		}
		return mocCodes.includes(prestation.pre_mode_prorata);
	}

	public conventionHasModeCalcul(convention: Convention, modeCalcul: 'montant'|'quantite'): boolean {
		let resultSegment = convention.segments.some((segment: Segment) => {
			return this.segmentHasModeCalcul(segment, modeCalcul, convention.segments);
		});

		let resultPrestation = convention.prestations.some((prestation: Prestation) => {
			return this.prestationHasModeCalcul(prestation, modeCalcul);
		});

		return resultSegment || resultPrestation;
	}

	public splitSegments(convention: Convention) {
		convention['groupes_conditions'] = [];
		convention['segments_standards'] = [];
		convention['challenges'] = [];
		convention.segments.forEach((segment: Segment) => {
			if (!segment.ristournes) {
				segment.ristournes = [];
			}
			if (segment.tsc_type == 'S') {
				segment.uid = uid();
				convention['segments_standards'].push(clone(segment));
				// rangement des segments standards et leurs ristournes par groupe de condition.
				let sca_groupe_condition = segment.sca_groupe_condition;
				if (!convention['groupes_conditions'][sca_groupe_condition]) {
					convention['groupes_conditions'][sca_groupe_condition] = new GroupeConditions(sca_groupe_condition);
				}
				let ristournes = segment.ristournes;
				ristournes.forEach((one: Ristourne) => {
					one.uid = uid();
				});
				convention['groupes_conditions'][sca_groupe_condition].ristournes = [...convention['groupes_conditions'][sca_groupe_condition].ristournes, ...ristournes];
				convention['groupes_conditions'][sca_groupe_condition].segments.push(clone(segment));
			}
			else {
				convention['challenges'].push(clone(segment));
			}
		});
		// suppression des groupes conditions inexistants
		convention['groupes_conditions'] = convention['groupes_conditions'].filter((one: GroupeConditions) => {
			return !!one;
		});
		// réparation des numéros de groupe, on évite les trous.
		for (let i = 0; i < convention['groupes_conditions'].length; i++) {
			convention['groupes_conditions'][i].sca_groupe_condition = i;
		}
	}

	public prepareConventionFromServer(convention: Convention) {
		let tmp = clone(convention);
		convertDateFieldsToDate(tmp, true);
		this.codesInternesService.formatLabel(tmp);
		tmp.label = this.getConventionNicename(convention, true);
		tmp.label_short = this.getConventionNicename(convention, false);
		if (tmp.filiales_conventions) {
			stringSort(tmp.filiales_conventions, 'ctx_libelle');
		}

		if (tmp.segments) {
			// ordonnancement des matrices par mpc_id, pour faciliter la comparaison dans le formulaire
			this.reorderMatrices(tmp.segments);
			this.splitSegments(tmp);
		}
		if (tmp.paragraphes_conventions) {
			numericSort(tmp.paragraphes_conventions, false, 'pgc_ordre');
		}
		if (tmp.signataires_conventions) {
			tmp.signataires_conventions.forEach((one: SignataireConvention, index: number)=> {
				let signataire: SignataireConvention = new SignataireConvention(one);
				signataire.signataire_principal = signataire.sco_id == convention.sco_signataire_principal;
				tmp.signataires_conventions[index] = signataire;
			});
		}

		if (tmp.prestations) {
			// on duplique les con_date_debut et con_date_fin sur les prestations pour permettre de limiter les dates de créations d'opérations
			tmp.prestations.forEach((one: Prestation) => {
				one.con_date_debut = tmp.con_date_debut;
				one.con_date_fin = tmp.con_date_fin;
			})
		}

		return tmp;
	}

	public reorderMatrices(segments: Segment[]|GroupeConditions[]) {
		for (let segment of segments) {
			if (segment.ristournes) {
				for (let ristourne of segment.ristournes) {
					if (ristourne.matrices_performances) {
						ristourne.matrices_performances = ristourne.matrices_performances.sort((a: MatricePerformanceConvention, b: MatricePerformanceConvention) => {
							return a.mpc_id - b.mpc_id;
						});
					}
				}
			}
		}
	}

	public prepareConventionsFromServer(conventions: Convention[]) {
		for (let i = 0; i < conventions.length ; i++) {
			conventions[i] = this.prepareConventionFromServer(conventions[i]);
		}
	}

	public getConventionNicename(
		convention: Convention|any,
		showPartenariatName: boolean = false,
		attrLibelle: string = 'ctx_libelle',
		attrDateDebut: string = 'con_date_debut',
		attrDateFin: string = 'con_date_fin'
	) {
		let date_debut_string = capitalize(this.datePipe.transform(convention[attrDateDebut], 'MMM yyyy', '+0000'));
		let date_fin_string = capitalize(this.datePipe.transform(convention[attrDateFin], 'MMM yyyy', '+0000'));
		let label = (!!showPartenariatName)? `${convention[attrLibelle]} ` : '';
		return `${label}${date_debut_string} à ${date_fin_string}`
	}

	public prepareConvention(convention: Convention) {
		let tmp = clone(convention);
		// array de pays -> array de pay_id
		tmp.pays = tmp.pays.map((one: Pays) => { return one.pay_id; } );
		// array de marques -> array de mar_id
		tmp.marques_conventions = tmp.marques_conventions.map((marque: Marque) => { return marque.mar_id; } );
		// array de filiale -> array de filiales simplifiées
		tmp.filiales_conventions = tmp.filiales_conventions.map((filiale: FilialeConvention) => {
			return {
				fco_id: (filiale.fco_id)? filiale.fco_id : null,
				ctx_fil_id: filiale.ctx_id, //identifiant de la filiale
				fco_code: (filiale.fco_code)? filiale.fco_code: null,
				// TODO l'api devrait se charger de renseigner la date inclusion dans le cas d'une filiale retirée puis remise dans la liste durant l'édition du formulaire
				fco_date_inclusion: (filiale.fco_date_inclusion)? filiale.fco_date_inclusion : new Date(),
			}
		});

		// l'objet partenariat n'est pas nécessaire : on a déjà son ctx_id
		delete tmp.partenariat;
		tmp.uti_responsable_nego = tmp.responsable_nego.uti_id;

		// l'objet du responsable n'est plus nécessaire : on a déjà son uti_responsable_nego
		delete tmp.responsable_nego;

		// rassemblement des segments en un seul array
		if (tmp.groupes_conditions && tmp.challenges) {
			tmp.segments = [];

			// on commence par les groupes de conditions
			tmp.groupes_conditions.forEach((groupe: GroupeConditions) => {

				let ristournesAssignees: boolean = false;

				for (let i = 0; i < groupe.segments.length; i++) {
					let segment = groupe.segments[i];
					segment.sca_groupe_condition = groupe.sca_groupe_condition;
					// reset des ristournes
					segment.ristournes = [];
					// on ajoute les ristournes au premier segment bonifiable du groupe si on ne les a pas déjà assignées
					if (
						segment.sca_bonifiable === true
						&& groupe.ristournes && groupe.ristournes.length
						&& ristournesAssignees == false
					) {
						groupe.ristournes.forEach((ristourne: Ristourne) => {
							ristourne.sca_id = segment.sca_id;
						});
						segment.ristournes = groupe.ristournes;
						ristournesAssignees = true;
					}

					tmp.segments.push(segment);
				}

			});

			delete tmp.groupes_conditions;
			delete tmp.segments_standards;

			// les challenges
			tmp.challenges.forEach((segment: Segment) => {
				tmp.segments.push(segment);
			});
			delete tmp.challenges;

			tmp.segments.forEach((segment: Segment) => {
				// retrait de l'attribut sca_bonifiable pour l'édition : on ne peut pas modifier cet attribut sur un segement existant
				if (segment.sca_id) delete segment.sca_bonifiable;
				segment.ristournes.forEach((ristourne: Ristourne) => {
					// array de pays -> array de pay_id
					if (ristourne.exclusions_pays) {
						ristourne.exclusions_pays = this.paysService.paysArrayToPayIdArray(ristourne.exclusions_pays);
					}
					if (ristourne.exclusions_pays_reglements) {
						ristourne.exclusions_pays_reglements = this.paysService.paysArrayToPayIdArray(ristourne.exclusions_pays_reglements);
					}
				});
			});

			delete tmp.adherents;
			delete tmp.agences_conventions;
		}

		if (tmp.con_precisions_perimetre) {
			tmp.con_precisions_perimetre = tmp.con_precisions_perimetre.trim();
		}

		tmp.prestations.forEach((prestation: Prestation) => {
			// array de pays -> array de pay_id
			if (prestation.exclusions_pays) {
				prestation.exclusions_pays = this.paysService.paysArrayToPayIdArray(prestation.exclusions_pays);
			}
			// En mode "CA bonifiable", on ne tient compte que du CA bonifiable des segments standards, pas besoin de sélection.
			if (!prestation.pre_ca_global) prestation.segments_prestations = [];
		});

		// mettre la date de debut au début mois
		tmp.con_date_debut = startOfMonth(tmp.con_date_debut);
		// mettre la date de fin à la fin du mois
		tmp.con_date_fin = endOfMonth(tmp.con_date_fin);

		// reset du signataire principal, l'API déterminera lequel prendre à partir de l'array des signataires
		tmp.sco_signataire_principal = null;

		convertDateFieldsToString(tmp, false, true);
		return tmp;
	}

	public get(con_id: number, ctx_id?: number) {
		if (!!ctx_id) {
			return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}`)
			.pipe(map(response => {
				return this.prepareConventionFromServer(response);
			}));
		}
		else {
			return this.http.get<any>(`${environment.api_url}/conventions_partenariats/${con_id}`)
			.pipe(map(response => {
				return this.prepareConventionFromServer(response);
			}));
		}
	}

	public getConventions(
		params: any,
		ctx_id?: number|null,
		grc_id?: number|null,
		portail?: PortailParam,
		tco_type?: TcoType
	) {
		let tmpParams = clone(params);
		let endpoint = 'conventions_partenariats';

		if (typeof tmpParams.annee != 'undefined') {
			if (tco_type == 'C') {
				endpoint = 'conventions_cadres_annee';
			}
			else if (tco_type == 'P') {
				endpoint = 'conventions_periodiques_annee';
			}
		}

		let url = this.portailService.getRoutePrefix(grc_id, ctx_id);
		url += `/${endpoint}`;

		tmpParams = this.portailService.maybeAddPorIdToObject(tmpParams, portail);
		tmpParams = prepareQueryParams(tmpParams);
		return this.http.get<any>(url, tmpParams)
		.pipe(map(
			(response: any) => {
				if (response.conventions_partenariats) {
					this.prepareConventionsFromServer(response.conventions_partenariats);
				}
				else if (Array.isArray(response)) {
					this.prepareConventionsFromServer(response);
				}
				return response;
			}
		));
	}

	public exportConventions(params: any, ctx_id?: number, grc_id?: number, portail?: PortailParam, tco_type?: TcoType) {
		let tmpParams = clone(params);
		let endpoint = 'conventions_partenariats';

		if (typeof tmpParams.annee != 'undefined') {
			if (tco_type == 'C') {
				endpoint = 'conventions_cadres_annee';
			}
			else if (tco_type == 'P') {
				endpoint = 'conventions_periodiques_annee';
			}
		}
		let url = this.portailService.getRoutePrefix(grc_id, ctx_id);
		url += `/${endpoint}/export`;

		tmpParams = this.portailService.maybeAddPorIdToObject(tmpParams, portail);
		tmpParams = prepareQueryParamsForDownload(tmpParams);
		return this.http.get<any>(url, tmpParams);
	}

	public post(convention: Convention, ctx_id: number) {
		let prepared = this.prepareConvention(convention);
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats`, prepared);
	}

	public put(convention: Convention, ctx_id: number) {
		let prepared = this.prepareConvention(convention);
		return this.http.put<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${convention.con_id}`, prepared);
	}

	public delete(con_id: number, ctx_id: number) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}`);
	}

	public duplicate(convention: Convention, tco_type: TcoType, date_debut: string, date_fin: string) {
		return this.http.post<any>(`${environment.api_url}/contextes/${convention.ctx_id}/conventions_partenariats/${convention.con_id}/duplication`, {tco_type: tco_type, con_date_debut: date_debut, con_date_fin: date_fin});
	}

	public getModele(ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/modele`)
		.pipe(map(response => {
			return this.prepareConventionFromServer(response);
		}));
	}

	public copyRistournes(ctx_id: number, con_id: number) {
		return this.http.post<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/copie_ristournes`, {});
	}

	public exportAgences(con_id: number, ctx_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/export_agences_filiales`, tmpParams);
	}

	public exportFiliales(con_id: number, ctx_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/export_filiales`, tmpParams);
	}

	public getUrlImportAgencesFiliales(con_id: number, ctx_id: number, agence?: boolean, validation?: boolean) {
		let url = `${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}`;
		if (agence) {
			url = `${url}/import_agences_filiales`;
		}
		else {
			url = `${url}/import_filiales`;
		}
		if (validation) {
			url = `${url}/validation`;
		}
		return url;
	}

	public getUrlFusionWord(con_id: number, ctx_id?: number) {
		let url: string = `${environment.api_url}`;
		if (ctx_id) {
			url = `${url}/contextes/${ctx_id}`;
		}
		return `${url}/conventions_partenariats/${con_id}/modele_word`;
	}

	public getAnneesAvecConventions(
		portail?: PortailParam,
		grc_id?: number|null,
		ctx_id?: number|null,
		mode?: ModeVision,
		tco_type?: TcoType,
		ofCurrentPortail?: boolean
	) {
		let tmpParams = new ExtensibleObject();
		tmpParams = this.portailService.maybeAddPorIdToObject(tmpParams, portail);
		if (mode) tmpParams.mode = mode;
		if (tco_type) tmpParams.tco_type = tco_type;

		if (ofCurrentPortail) {
			const por_id = this.portailService.currentPortail.por_id;
			if (ctx_id) tmpParams.ctx_id = ctx_id;
			else if (grc_id) tmpParams.grc_id = grc_id;
			return this.http.get<any>(`${environment.api_url}/portails/${por_id}/annees_avec_conventions`, prepareQueryParams(tmpParams));
		}
		else {
			let url = this.portailService.getRoutePrefix(grc_id, ctx_id);
			return this.http.get<any>(`${url}/annees_avec_conventions`, prepareQueryParams(tmpParams));
		}
	}

	public getConditionsContrats(con_id: number, ctx_id?: number) {
		let endpoint = 'conditions_convention';
		let url = this.portailService.getRoutePrefix(null, ctx_id);
		return this.http.get<any>(`${url}/${endpoint}/${con_id}`);
	}

	public exportConditionsContratsSingle(ctx_id: number, con_id: number) {
		let tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/conventions_partenariats/${con_id}/export_conditions_pdf`, tmpParams);
	}

	public exportConditionsContrats(ctx_id: number, annee: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject({annee: annee}, portail)
		tmpParams = prepareQueryParamsForDownload(tmpParams);

		if (!!ctx_id) {
			return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/export_conditions_pdf`, tmpParams);
		}
		else {
			return this.http.get<any>(`${environment.api_url}/export_conditions_pdf`, tmpParams);
		}
	}

	public getParagrapheTrigramme(paragraphe: ModeleParagraphe|ParagrapheConvention) {
		return (typeof paragraphe.con_id == 'undefined')? 'mpg':'pgc';
	}

	public prepareParagrapheFromServer(paragraphe: ModeleParagraphe|ParagrapheConvention) {
		const trigramme = this.getParagrapheTrigramme(paragraphe);
		paragraphe[`${trigramme}_contenu_notag`] = stripHTMLTags(paragraphe[`${trigramme}_contenu`]);
		return paragraphe;
	}

	public prepareParagraphesFromServer(paragraphes: ModeleParagraphe[]|ParagrapheConvention[]) {
		for (let i = 0; i < paragraphes.length ; i++) {
			paragraphes[i] = this.prepareParagrapheFromServer(paragraphes[i]);
		}
	}

	public getModelesParagraphes(params?: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`${environment.api_url}/modeles_paragraphes`, tmpParams)
		.pipe(map((response: any)=>{
			if (response.modeles_paragraphes) {
				this.prepareParagraphesFromServer(response.modeles_paragraphes);
			}
			return response;
		}));
	}

	public getModelesParagraphesCached(refresh?: boolean) {
		if (!refresh && this._modelesParagraphes && this._modelesParagraphes.length) {
			return new Observable<any>((subscriber: any) => {
			 	subscriber.next(this._modelesParagraphes);
				subscriber.complete();
			});
		}
		else {
			return this.getModelesParagraphes()
			.pipe(map((response: any) => {
				this._modelesParagraphes = response['modeles_paragraphes'];
				return this._modelesParagraphes;
			}));
		}
	}

	public postModeleParagraphe(modeleParagraphe: ModeleParagraphe) {
		this._modelesParagraphes = [];
		return this.http.post<any>(`${environment.api_url}/modeles_paragraphes`, modeleParagraphe);
	}

	public putModeleParagraphe(modeleParagraphe: ModeleParagraphe) {
		this._modelesParagraphes = [];
		return this.http.put<any>(`${environment.api_url}/modeles_paragraphes/${modeleParagraphe.mpg_id}`, modeleParagraphe);
	}

	public deleteModeleParagraphe(mpg_id: number) {
		this._modelesParagraphes = [];
		return this.http.delete<any>(`${environment.api_url}/modeles_paragraphes/${mpg_id}`);
	}

	public getRistournes(params: any, ctx_id_reference?: number) {
		let tmpParams = prepareQueryParams(params);
		let url = this.portailService.getRoutePrefix(null, ctx_id_reference);
		url = `${url}/suivi_ristournes`;
		return this.http.get<any>(url, tmpParams)
			.pipe(map(
				(response: any) => {
					this.prepareConventionsFromServer(response.ristournes);
					return response;
				}
			));
	}

	public exportRistournes(params: any, ctx_id_reference?: number) {
		const tmpParams = prepareQueryParamsForDownload(params);
		let url = this.portailService.getRoutePrefix(null, ctx_id_reference);
		url = `${url}/suivi_ristournes/export`;
		return this.http.get<any>(url, tmpParams)
			.pipe(mergeMap(
				(response: any) => {
					return downloadFromHttpResponse(response);
				}
			));
	}


}
