import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExtensibleObject } from '@helpers/utils';

export interface IEventListener extends OnDestroy {
	ngOnDestroy(): void
	uuid: string;
}


@Injectable({ providedIn: 'root' })
export class EventManagerService {

	private listeners: ExtensibleObject = new ExtensibleObject();
	private eventSubject = new Subject();
	private eventObserver$ = this.eventSubject.asObservable();


	constructor() {
		this.eventObserver$.subscribe(
			({name, args}: {name: any, args: any}) => {
				if (this.listeners[name]) {
					for (let listener of this.listeners[name]) {
						listener.callback(args);
					}
				}
			}
		);
	}

	public registerEvent(eventName: string, eventListener: IEventListener, callback:any) {
		if (!this.listeners[eventName]) {
			this.listeners[eventName] = [];
		}

		let eventExist = false;

		for (let listener of this.listeners[eventName]) {
			if (eventListener.uuid) {
				if (listener.eventListener.uuid && eventListener.uuid == listener.eventListener.uuid) {
					eventExist = true;
					break;
				}
			}
			else {
				if (listener.eventListener.constructor.name == eventListener.constructor.name) {
					eventExist = true;
					break;
				}
			}
		}

		if (!eventExist) {
			this.listeners[eventName].push({eventListener, callback});
		}
	}

	public unregisterEvent(eventName: string, eventListener: IEventListener) {

		if (this.listeners[eventName]) {
			for (let i = 0; i<this.listeners[eventName].length; i++) {
				if (eventListener.uuid) {
					if (this.listeners[eventName][i].eventListener.uuid && this.listeners[eventName][i].eventListener.uuid == eventListener.uuid) {
						this.listeners[eventName].splice(i, 1);
						break;
					}
				}
				else {
					if (this.listeners[eventName][i].eventListener.constructor.name == eventListener.constructor.name) {
						this.listeners[eventName].splice(i, 1);
						break;
					}
				}
			}
		}
	}

	public emit (name: string, ...args: any[]) {
		this.eventSubject.next({name, args});
	}
}
