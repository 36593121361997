import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { PortailService, PortailParam } from '@app/portail/portail.service';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'portail'})
export class PortailPipe implements PipeTransform {

	constructor(private portailService: PortailService) {}

	transform(portail: PortailParam, short?: boolean): string {

		const portails = this.portailService.portails;
		const por_id = this.portailService.getPortailId(portail);
		const found = portails.find(one => {return one.por_id == por_id;});
		if (found) {
			let label = found.por_libelle;
			if (short) {
				label = label.replace(/ *Portail */, '');
			}
			return label;
		}
		return por_id? por_id.toString() : '';
	}
}
