import { CommentairePublication } from '@app/publication/commentaire-publication.model';
import { LienPublication } from '@app/publication/lien-publication.model';
import { PieceJointe } from '@app/publication/piece-jointe.model';

export class Publication {
	pub_id: number;
	tpu_code: string;
	pub_date_debut_diffusion: Date;
	pub_date_fin_diffusion: Date;
	pub_ordre: number = 0;
	pub_objet: string;
	pub_brouillon: boolean = false;
	pub_popup: boolean = false;
	pub_en_une: boolean = false;
	pub_resume: string;
	pub_html: string;
	med_illustration: number|null;
	uti_createur?: number;
	pub_date_creation: Date;
	pub_actif: boolean = true;
	pieces_jointes_publications: PieceJointe[];
	liens_publications: LienPublication[];
	media: any;
	itr_date_lecture: Date|null;
	itr_date_masquage: Date|null;
	emo_unicode?: string;
	commentaires: CommentairePublication[];
	nb_commentaires?: number;
	decompte_emoticones?: any;

	pub_date_modification: Date|null = null;

	constructor(tpu_code: string) {
		this.tpu_code = tpu_code;
		this.pub_date_debut_diffusion = new Date();
		this.pub_brouillon = true;
	}



}
