import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Contexte } from '@app/contexte/contexte.model';
import { GroupeContexteService, GrcModule } from '@app/groupe-contexte/groupe-contexte.service';
import { SocieteService } from '@app/societe/societe.service';

import { PortailService, PortailParam } from '@app/portail/portail.service';
import { CodesInternesService } from '@global/codes-internes.service';

import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class ContexteService {

	constructor(
		protected http: HttpClient,
		protected codesInternesService: CodesInternesService,
		protected groupeContexteService: GroupeContexteService,
		protected portailService: PortailService,
		protected societeService: SocieteService,
	) {

	}

	public getDummy() {
		return new Contexte();
	}

	public prepare(obj: Contexte) {
		let prepared = clone(obj);
		delete prepared.adherent;
		delete prepared.fournisseur;
		convertDateFieldsToString(prepared, false, true);
		return prepared;
	}

	public prepareContexteFromServer(contexte: Contexte): Contexte {
		let tmp = clone(contexte);
		tmp.societe = this.societeService.prepareFromServer(tmp.societe);
		convertDateFieldsToDate(tmp);
		this.codesInternesService.formatLabel(tmp);
		return tmp;
	}

	public prepareContextesFromServer(contextes: Contexte[]): void {
		for (let i = 0; i < contextes.length ; i++) {
			contextes[i] = this.prepareContexteFromServer(contextes[i]);
		}
	}

	public getList(
		params: any,
		grc_id?: number,
		portail?: PortailParam,
		ctx_id_reference?: number,
		grc_id_reference?: number,
		participantModule?: GrcModule,
	) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);

		if (grc_id) {
			tmpParams.grc_id = grc_id;
		}

		tmpParams = this.groupeContexteService.maybeAddGrcModule(tmpParams, participantModule);

		let url = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		if (ctx_id_reference || grc_id_reference && grc_id_reference != grc_id) {
			// récupération des contextes liés à un groupe via des partenariats
			// ou des contextes liés à un contexte via des partenariats
			url += '/contextes_lies';
		}
		else {
			url += '/contextes';
		}

		return this.http.get<any>(url, prepareQueryParams(tmpParams))
		.pipe(map((response: any) => {
			this.prepareContextesFromServer(response.contextes);
			return response;
		}));
	}

	public get(ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}`)
		.pipe(map((contexte: Contexte) => {
			contexte = this.prepareContexteFromServer(contexte);
			return contexte;
		}));
	}

	public post(contexte: Contexte) {
		let prepared = this.prepare(contexte);
		return this.http.post<any>(`${environment.api_url}/groupes_contextes/${contexte.grc_id}/contextes`, prepared);
	}

	public put(contexte: Contexte) {
		let prepared = this.prepare(contexte);
		return this.http.put<any>(`${environment.api_url}/contextes/${prepared.ctx_id}`, prepared);
	}

	public delete(ctx_id: number) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${ctx_id}`);
	}

	public exportList(params: any, grc_id?: number, portail?: PortailParam) {
		let tmpParams = this.portailService.maybeAddPorIdToObject(params, portail);
		if (grc_id) {
			tmpParams.grc_id = grc_id;
		}
		tmpParams = prepareQueryParamsForDownload(tmpParams);
		return this.http.get<any>(`${environment.api_url}/contextes/export`, tmpParams);
	}

}
