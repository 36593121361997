import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';

import { LazyLoadImgDirective } from '@helpers/lazy-load-img.directive';

@NgModule({
	imports: [
		ButtonModule,
		CardModule,
		CheckboxModule,
		CommonModule,
		ConfirmDialogModule,
		DividerModule,
		DynamicDialogModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		MessagesModule,
		OverlayPanelModule,
		ProgressBarModule,
		ScrollTopModule,
		SidebarModule,
		ToastModule,
	],
	providers: [DatePipe],
	declarations: [LazyLoadImgDirective],
	entryComponents: [],
	exports: [
		ButtonModule,
		CardModule,
		CheckboxModule,
		CommonModule,
		ConfirmDialogModule,
		DividerModule,
		DynamicDialogModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		MessagesModule,
		OverlayPanelModule,
		ProgressBarModule,
		ScrollTopModule,
		SidebarModule,
		ToastModule,
		LazyLoadImgDirective
	]
})
export class GlobalModule { }
