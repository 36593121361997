import { Pipe, PipeTransform } from '@angular/core';

import { SignatureService } from '@app/signature/signature.service';

@Pipe({name: 'etatSignataire'})
export class EtatSignatairePipe implements PipeTransform {

	constructor(private signatureService: SignatureService) {}

	transform(code: string): string {
		const etat = this.signatureService.findEtatSignataire(code);
		if (etat) {
			return  etat.libelle;
		}
		return code? code.toString() : '';
	}
}
