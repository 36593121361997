import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { ArticleComptable } from '@app/compta/article-comptable.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'articleComptable'})
export class ArticleComptablePipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	articlesComptables: ArticleComptable[];

	transform(arc_id: number|number[], separator: string = ', '): Promise<string>{
		return this.comptaService.getCachedListArticlesComptables()
		.toPromise().then(
			(response: any) => {
				this.articlesComptables = response;
				if (Array.isArray(arc_id)) {
					let result: string[] = [];
					arc_id.forEach((one: number) => {
						result.push(this.transformOne(one));
					});
					result.sort();
					return result.join(separator);
				}
				return this.transformOne(arc_id);
			},
			err => {
				return arc_id? arc_id.toString() : '';
			}
		);
	}

	transformOne(arc_id: number) {
		const articleComptable = this.articlesComptables.find((one: ArticleComptable) => {
			return one.arc_id == arc_id;
		});
		if (articleComptable) {
			return  articleComptable.arc_libelle;
		}
		return arc_id? arc_id.toString() : '';
	}
}
