import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { ConfirmDemandeAjoutAgenceDialogComponent, DemandeAjoutAgenceDialogComponent } from '@app/notification/demande-ajout-agence-dialog';
import { DemandeChangementAdminDialogComponent } from '@app/notification/demande-changement-admin-dialog';
import { AlerteRectificationCADialogComponent } from '@app/notification/alerte-rectification-ca-dialog';

import { PortailParam } from '@app/portail/portail.service';

@Directive({
	selector: '[showFormulaireNotif]'
})
export class ShowFormulaireNotifDirective {

	@Input() showFormulaireNotif: string;
	@Input() portail: PortailParam;
	@Input() ctx_id: number;
	@Input() con_id: number;
	@Input() annee: number;

	constructor(
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private elementRef: ElementRef,
		) {

	}

	@HostListener('click')
	onClick() {
		switch(this.showFormulaireNotif) {
			case 'alerte_ajout_pdv':
				this.showDemandeAjoutAgenceForm();
				break;
			case 'changement_admin':
				this.showDemandeChangementAdminForm();
				break;
			case 'alerte_rectification_ca':
				this.showAlerteRectificationCAForm();
				break;
		}
	}

	showForm() {
		switch(this.showFormulaireNotif) {
			case 'alerte_ajout_pdv':
				this.showDemandeAjoutAgenceForm();
				break;
			case 'changement_admin':
				this.showDemandeChangementAdminForm();
				break;
			case 'alerte_rectification_ca':
				this.showAlerteRectificationCAForm();
				break;
		}
	}

	showDemandeAjoutAgenceForm() {
		const refConfirm = this.dialogService.open(ConfirmDemandeAjoutAgenceDialogComponent, {
			data: {
				con_id: this.con_id,
				ctx_id:  this.ctx_id
			},
			header: 'Confirmation',
			width: '50%'
		});

		refConfirm.onClose.subscribe(dialogResult => {
			if (!!dialogResult) {
				const ref = this.dialogService.open(DemandeAjoutAgenceDialogComponent, {
					header: 'Demande d\'ajout d\'agence',
					width: '70%'
				});
				ref.onClose.subscribe(dialogResult => { });
			}
		});

	}

	showDemandeChangementAdminForm() {
		const ref = this.dialogService.open(DemandeChangementAdminDialogComponent, {
			header: 'Demande de changement d\'administrateur',
			width: '70%'
		});
		ref.onClose.subscribe(dialogResult => { });
	}

	showAlerteRectificationCAForm() {
		const ref = this.dialogService.open(AlerteRectificationCADialogComponent, {
			header: 'Notification pour rectification CA',
			width: '70%',
			data: {
				portail: this.portail,
				ctx_id: this.ctx_id,
				con_id: this.con_id,
				annee: this.annee
			}
		});
		ref.onClose.subscribe(dialogResult => { });
	}

}
