export class ModeleRelance {
	mre_id: number;
	mre_libelle: string;
	mre_objet: string;
	mre_corps: string;
	mre_expediteur_email: string;
	mre_expediteur_identite: string;
	mre_json: {[key: string]: any} = {
		jours: [],
		destinataires: []
	};
	mre_actif: boolean = true;
	requetes_contacts: number[] = [];

	constructor() {

	}
}
