import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { clone } from '@helpers/utils';

import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';

@Injectable({ providedIn: 'root' })
export class ParametreService {

	private serverParamsSubject = new BehaviorSubject<any>(null);
	private appParamsSubject = new BehaviorSubject<any>(null);

	constructor(private http: HttpClient) {

	}

	public get serverParams(): any {
		return this.serverParamsSubject.getValue();
	}

	public get appParams(): any {
		return this.appParamsSubject.getValue();
	}

	public resetParams(): void {
		this.serverParamsSubject.next(null);
		this.appParamsSubject.next(null);
	}

	public getParam(param: string, defaultValue: any = undefined ): any {
		if (this.appParams && typeof this.appParams[param] != 'undefined') {
			return this.appParams[param];
		}
		return defaultValue;
	}

	public getParametres() {
		return this.http.get<any>(`${environment.api_url}/parametres`)
		.pipe(
			map(
				(arrayOfParams: any) => {
					this.serverParamsSubject.next(arrayOfParams);
					let tmp = clone(environment);
					arrayOfParams.forEach((param: any) => {
						tmp[param.prm_code] = param.prm_valeur;
					});

					this.appParamsSubject.next(tmp);
					return arrayOfParams;
				}
			)
		);
	}

	public getPublicKey() {
		return this.http.get<any>(`${environment.api_url}/public_key`);
	}

	public getModeleWordDemandeAvoir() {
		return this.http.get<any>(`${environment.api_url}/modeles_word/demande_avoir`);
	}

	public downloadModeleWordDemandeAvoir() {
		const tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(`${environment.api_url}/modeles_word/demande_avoir/dl`, tmpParams);
	}

	public getUrlUploadModeleWordDemandeAvoir() {
		return `${environment.api_url}/modeles_word/demande_avoir`;
	}


}
