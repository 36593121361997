import { Ng2StateDeclaration } from '@uirouter/angular';
import { Transition } from '@uirouter/core';

import { AppComponent } from '@app/app.component';
import { ArchiveViewComponent } from '@app/document/archive';
import { AuthComponent } from '@app/auth/auth.component';
import { ChangePasswordComponent } from '@app/auth/change-password';
import { ChangePasswordConnectedComponent } from '@app/auth/change-password';
import { DocumentService } from '@app/document/document.service';
import { LoginComponent } from '@app/auth/login';
import { Login2faComponent } from '@app/auth/login-2fa';
import { PortailComponent } from '@app/portail';
import { ResetPasswordComponent } from '@app/auth/reset-password';
import { SelectionPortailComponent } from '@app/portail/selection-portail';
import { ServiceUnavailableComponent } from'@global/service-unavailable.component';

import * as resolves from '@helpers/router.resolves';

export const APP_STATES: Ng2StateDeclaration[] = [
	{
		name: 'app',
		component: AppComponent,
		redirectTo: 'portail',
		data: {
			parametresGuard: true,
		}
	},

	{
		parent: 'app',
		name: 'auth',
		url: '/auth',
		redirectTo: 'auth.login',
		component: AuthComponent,
		resolve: [
			resolves.returnToResolve
		]
	},
	{
		name: 'auth.login',
		url: '/login',
		component: LoginComponent,
		data: { forbiddenWhenAuth: true }
	},
	{
		name: 'auth.reset_password',
		url: '/mot-de-passe-oublie?{email:string}',
		params: {
			email: { dynamic: true, squash: true }
		},
		component: ResetPasswordComponent
	},
	{
		name: 'auth.change_password',
		url: '/changement-mot-de-passe/:resetPasswordToken',
		component: ChangePasswordComponent
	},
	{
		name: 'auth.challenge_2fa',
		url: '/2fa',
		data: {
			requiresAuth: true,
			forbiddenWhen2faPassed: true
		},
		component: Login2faComponent
	},


	{
		name: 'service_unavailable',
		url: '/?{error:string}',
		component: ServiceUnavailableComponent
	},
	{
		parent: 'app',
		name: 'portail',
		url: '/portail',
		redirectTo: 'portail.selection',
		component: PortailComponent,
		data: {
			requiresAuth: true,
			auth2faPassed: true,
		},
		resolve: [
			resolves.getMeResolve,
			resolves.getPortailsResolve,
			resolves.getListesGeneriquesResolve
		],
		resolvePolicy: { when: "EAGER", async: "WAIT" } // we want to wait for the resolves to complete before going in the substates
	},
	{
		name: 'portail.change_password',
		url: '/changement-mot-de-passe',
		component: ChangePasswordConnectedComponent,
		resolve: [
			resolves.returnToResolve
		],
		data: {
			forbiddenWhenPasswordNotExpired: true
		}
	},
	{
		name: 'portail.download_archive',
		url: '/telechargement-archive/{tff_id:int}',
		component: ArchiveViewComponent,
		resolve: [
			{
				token: 'archive',
				deps: [DocumentService, Transition],
				resolveFn: (documentService: DocumentService, transition: Transition) => {
					return documentService.getArchive(transition.params().tff_id).toPromise();
				}
			}
		],
	},

	{
		name: 'portail.selection',
		url: '/',
		component: SelectionPortailComponent,
		data: {
			passwordExpiredGuard: true,
			portailsAccessiblesGuard: true
		},
		resolve: [
			resolves.resetCurrentPortailResolve,
			resolves.resetContexteResolve,
			resolves.portailsAccessiblesResolve
		]
	},

	// portail EQIP
	{
		parent: 'portail',
		name: 'portail-eqip.**',
		url: '/eqip',
		loadChildren: () => import('./portail-eqip/portail-eqip.module').then(m => m.PortailEqipModule),
		data: {
			passwordExpiredGuard: true,
			portailsAccessiblesGuard: true,
			portailGuard: true,
		}
	},

	// portail adherent
	{
		parent: 'portail',
		name: 'portail-adherent.**',
		url: '/adherent',
		loadChildren: () => import('./portail-adherent/portail-adherent.module').then(m => m.PortailAdherentModule),
		data: {
			passwordExpiredGuard: true,
			portailsAccessiblesGuard: true,
			portailGuard: true,
		}
	},

	// portail fournisseur
	{
		parent: 'portail',
		name: 'portail-fournisseur.**',
		url: '/fournisseur',
		loadChildren: () => import('./portail-fournisseur/portail-fournisseur.module').then(m => m.PortailFournisseurModule),
		data: {
			passwordExpiredGuard: true,
			portailsAccessiblesGuard: true,
			portailGuard: true,
		}
	},

	// portail frais generaux
	{
		parent: 'portail',
		name: 'portail-frais_generaux.**',
		url: '/frais-generaux',
		loadChildren: () => import('./portail-frais_generaux/portail-frais_generaux.module').then(m => m.PortailFraisGenerauxModule),
		data: {
			passwordExpiredGuard: true,
			portailsAccessiblesGuard: true,
			portailGuard: true,
		}
	},

];
