import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ConventionService } from '@app/convention/convention.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { createDownloadFromHttpResponse, uid } from '@helpers/utils';

@Component({
	selector: 'confirm-demande-ajout-agence',
	template: `
		<p>Vous souhaitez demander l'ajout d'une agence que vous n'avez pas retrouvée dans le <button type="button" pButton [loading]="exportLoading" icon="pi pi-download" class="p-button-inline p-button-link" (click)="export()" label="listing de codes agences"></button> ?</p>
		<div class="text-right">
			<button type="button" pButton icon="pi pi-times" class="p-confirm-dialog-reject mr-1" (click)="reject()" label="Non"></button>
			<button type="button" pButton icon="pi pi-check" class="p-confirm-dialog-accept" (click)="accept()" label="Oui"></button>
		</div>
	`,
	providers: []
})
export class ConfirmDemandeAjoutAgenceDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	con_id: number;
	ctx_id: number;
	exportLoading: boolean;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private conventionService: ConventionService,
	) {
		this.con_id = config.data.con_id;
		this.ctx_id = config.data.ctx_id;
 	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	export() {
		this.exportLoading = true;
		this.conventionService.exportAgences(this.con_id, this.ctx_id)
		.subscribe(
			(response: any) => {
				createDownloadFromHttpResponse(response);
			}
		).add(() => {this.exportLoading = false;});
	}

	reject() {
		this.dialogRef.close();
	}

	accept() {
		this.dialogRef.close({confirm: true});
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
	],
	exports: [ConfirmDemandeAjoutAgenceDialogComponent],
	declarations: [ConfirmDemandeAjoutAgenceDialogComponent]
})
export class ConfirmDemandeAjoutAgenceDialogModule { }

