import { NgModule, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Subscription } from 'rxjs';

import { TagModule } from 'primeng/tag';

import { SignatureService } from '@app/signature/signature.service';

import { EqipModule } from '@global/eqip.module';
import { ParametreService } from '@app/parametre/parametre.service';

@Component({
	selector: 'signature-reminder',
	providers: [],
	template: `
		<p-tag severity="danger" title="Signature en cours" *ngIf="countNonTraitees"><i class="pi pi-bell"></i></p-tag>
	`,
})
export class SignatureReminderComponent implements OnInit {

	@Input() menuItem: any;

	countNonTraitees: number = 0;
	subscriptionCountNonTraitee: Subscription;
	loaded: boolean = false;

	constructor(
		private cdRef: ChangeDetectorRef,
		private signatureService: SignatureService,
	) {
		this.subscriptionCountNonTraitee = signatureService.countNonTraitees$.subscribe((value: number) => {
			if (this.loaded) {
				this.countNonTraitees = value;
				if (this.menuItem) {
					if (value) {
						this.menuItem.uiParams = {tab: 'signatures'};
					}
					else {
						this.menuItem.uiParams = {};
					}
				}
				this.cdRef.detectChanges(); // force change detection
			}
			else {
				this.loaded = true;
			}
		});
	}

	ngOnInit() {
		this.signatureService.signatureReminderRefresh();
	}

}


@NgModule({
	imports: [
		CommonModule,
		EqipModule,
		TagModule,
	],
	exports: [SignatureReminderComponent],
	declarations: [SignatureReminderComponent]
})
export class SignatureReminderModule { }
