import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';

import { Convention } from '@app/convention/convention.model';
import { ConventionService } from '@app/convention/convention.service';
import { DestinataireSignature } from '@app/signature/destinataire-signature.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { PortailService } from '@app/portail/portail.service';
import { SignataireConvention } from '@app/signature/signataire-convention.model';
import { Signature } from '@app/signature/signature.model';
import { SignatureDialogComponent } from '@app/signature/signature-dialog';
import { TypeSignature } from '@app/signature/type-signature.model';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { environment } from '@environments/environment';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import { clone, convertDateFieldsToDate, uid } from '@helpers/utils';

@Injectable({providedIn: 'root'})
export class SignatureService implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private _typesSignature: TypeSignature[];
	// [
	// 	{tsi_type: 'C', tsi_libelle: 'Contrat'},
	// 	{tsi_type: 'A', tsi_libelle: 'Avenant'},
	// ];

	private _etatsSignature: any[] = [
		{code: 'draft', libelle: 'En préparation', date_field: null},
		{code: 'created', libelle: 'Prête pour signature', date_field: 'sig_date_soumission', selector: true},
		{code: 'sent', libelle: 'En attente de signature', date_field: 'sig_date_envoi', selector: true},
		{code: 'delivered', libelle: 'Vue par tous les signataires', date_field: 'sig_date_lecture'},
		{code: 'signing', libelle: 'En attente de signature', date_field: 'sig_date_envoi'},
		{code: 'declined', libelle: 'Refusée', date_field: 'sig_date_refus', selector: true},
		{code: 'completed', libelle: 'Signée', date_field: 'sig_date_signature', selector: true},
	];

	private _etatsSignataire: any[] = [
		{code: 'draft', libelle: 'En attente', date_field: null},
		{code: 'sent', libelle: 'Notifié(e)', date_field: 'date_envoi'},
		{code: 'delivered', libelle: 'A vu les documents', date_field: 'date_lecture'},
		// {code: 'signing', libelle: 'En signature', date_field: ''},
		{code: 'declined', libelle: 'Refus', date_field: 'date_refus'},
		{code: 'completed', libelle: 'Signé', date_field: 'date_signature', relecteur: false},
		{code: 'completed', libelle: 'Relu', date_field: 'date_signature', relecteur: true},
	];


	initialDelay:  number = 500;
	delays: number[] = [30000, 60000, 120000, 300000, 600000];

	private signatureReminderAutoRefresh: boolean = false;
	private signatureReminderAttempts: number = 0;
	private signatureReminderStopTimer$: Subject<any> = new Subject;
	private signatureReminderLoading: boolean = false;
	private countNonTraiteesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public countNonTraitees$ = this.countNonTraiteesSubject.asObservable();


	constructor(
		private dialogService: DialogService,
		private http: HttpClient,
		private conventionService: ConventionService,
		private eventManager: EventManagerService,
		private listesGeneriquesService: ListesGeneriquesService,
		private portailService: PortailService,
	) {
		this._typesSignature = this.listesGeneriquesService.getListe('types_signatures');

	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.signatureReminderStopPolling();
		});
		this.eventManager.registerEvent('contexte_reseted', this, (args: any) => {
			this.signatureReminderStopPolling();
		});
		this.eventManager.registerEvent('portail_reseted', this, (args: any) => {
			this.signatureReminderStopPolling();
		});
		this.eventManager.registerEvent('refresh_notif_signature_reminder', this, (args: any) => {
			this.signatureReminderRefresh(false, true);
		});
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('refresh_notif_signature_reminder', this);
		this.eventManager.unregisterEvent('logout', this);
		this.signatureReminderStopPolling();
	}

	signatureReminderStartPolling() {
		const initialDelay = (this.signatureReminderAttempts)? this.delays[this.signatureReminderAttempts] : this.initialDelay;
		timer(initialDelay, this.delays[this.signatureReminderAttempts])
		.pipe(
			takeUntil(this.signatureReminderStopTimer$),
			switchMap(
			(count: number) => {
				return this.currentContexteHasActiveSignature();
			}
		))
		.subscribe(
			(response: any) => {
				if (response) {
					this.countNonTraiteesSubject.next(1);
				}
				else {
					this.countNonTraiteesSubject.next(0);
				}
				this.signatureReminderAttempts = 0;
				if (!this.signatureReminderAutoRefresh) {
					this.signatureReminderStopPolling();
				}
			},
			(error: any) => {
				this.signatureReminderRefresh(true);
			}
		)
		.add(() => { this.signatureReminderLoading = false; });
	}

	signatureReminderStopPolling() {
		this.signatureReminderStopTimer$.next(true);
	}

	signatureReminderRefresh(increaseAttempts?: boolean, resetAttempts?: boolean) {
		this.signatureReminderStopPolling();
		if (increaseAttempts && this.signatureReminderAttempts < this.delays.length -1) {
			this.signatureReminderAttempts++;
		}
		else if (resetAttempts) {
			this.signatureReminderAttempts = 0;
		}
		this.signatureReminderStartPolling();
	}

	public get typesSignature() {
		return [...this._typesSignature];
	}

	public get etatsSignature() {
		return [...this._etatsSignature];
	}

	public getRoutePrefix(sig_id?: number|null, con_id?: number|null) {
		let url: string = `${environment.api_url}`;
		if (con_id) {
			url = `${url}/conventions_partenariats/${con_id}`;
		}
		url = `${url}/signatures`;
		if (sig_id) {
			url = `${url}/${sig_id}`;
		}
		return url;
	}

	public getUrlUploadDocumentSignature(sig_id: number, con_id?: number): string {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/documents`;
		return url;
	}

	public downloadDocumentSignature(dos_id: number, sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/documents/${dos_id}`;
		let tmpParams = prepareQueryParamsForDownload();
		return this.http.get<any>(url, tmpParams);
	}

	public post(signature: any) {
		let tmp = clone(signature);
		return this.http.post<any>(`${environment.api_url}/conventions_partenariats/${tmp.con_id}/signatures`, tmp);
	}

	public delete(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		return this.http.delete<any>(url);
	}

	public getSignatures(con_id?: number) {
		let url: string = this.getRoutePrefix(null, con_id);
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				this.prepareSignaturesFromServer(response);
				return response;
			}
		))
	}

	public get(sig_id: number, con_id: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				return this.prepareSignatureFromServer(response);
			}
		))
	}

	public prepareSignatureFromServer(signature: Signature) {
		let tmp = clone(signature);
		convertDateFieldsToDate(tmp, true);
		if (tmp.signataires) {
			tmp.signataires.forEach((one: DestinataireSignature, index: number)=> {
				tmp.signataires[index] = new DestinataireSignature(one);
			});
		}
		if (tmp.destinataires) {
			tmp.destinataires.forEach((one: DestinataireSignature, index: number)=> {
				tmp.destinataires[index] = new DestinataireSignature(one);
			});
		}
		tmp.etat = this.getEtatSignatureCode(tmp);
		tmp.label = this.getSignatureLabel(tmp);
		tmp.label_convention = this.conventionService.getConventionNicename(tmp, true);
		return tmp;
	}

	public prepareSignaturesFromServer(signatures: Signature[]) {
		for (let i = 0; i < signatures.length ; i++) {
			signatures[i] = this.prepareSignatureFromServer(signatures[i]);
		}
	}

	public findEtatSignature(code: string) {
		return this._etatsSignature.find((one: any) => one.code == code);
	}

	public getEtatSignature(signature: Signature) {
		if (signature.sig_date_signature) {
			return this.findEtatSignature('completed');
		}
		else if (signature.sig_date_refus) {
			return this.findEtatSignature('declined');
		}
		// else if (signature.sig_date_lecture) {
		// 	return this.findEtatSignature('delivered');
		// }
		else if (signature.sig_date_envoi) {
			return this.findEtatSignature('signing');
		}
		else if (signature.sig_date_soumission) {
			return this.findEtatSignature('created');
		}
		else {
			return this.findEtatSignature('draft');
		}
	}

	public getEtatSignatureCode(signature: Signature) {
		const etat = this.getEtatSignature(signature);
		return (etat)? etat.code : '';
	}

	public findEtatSignataire(code: string, relecteur: boolean = false) {
		return this._etatsSignataire.find((one: any) => {
			return one.code == code && (!!one.relecteur == relecteur)
		});
	}

	public getEtatSignataire(signataire: DestinataireSignature) {
		if (signataire.des_date_signature) {
			return this.findEtatSignataire('completed', signataire.relecteur);
		}
		else if (signataire.des_date_refus) {
			return this.findEtatSignataire('declined');
		}
		else if (signataire.des_date_lecture) {
			return this.findEtatSignataire('delivered');
		}
		else if (signataire.des_date_envoi) {
			return this.findEtatSignataire('sent');
		}
		else if (signataire.des_date_soumission) {
			return this.findEtatSignataire('created');
		}
		else {
			return this.findEtatSignataire('draft');
		}
	}

	public getEtatSignataireCode(signataire: DestinataireSignature) {
		const etat = this.getEtatSignataire(signataire);
		return (etat)? etat.code : '';
	}

	public deleteDocument(dos_id: number, sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/documents/${dos_id}`;
		return this.http.delete<any>(url);
	}

	public showSignatureDialog(convention: Convention, hasSignatureContrat: boolean, warningMessage?: string) {
		let header: string = 'Nouvelle signature ';
		const ref = this.dialogService.open(SignatureDialogComponent, {
			header: header,
			width: '50%',
			data: {
				convention: convention,
				hasSignatureContrat: hasSignatureContrat,
				warningMessage: warningMessage
			}
		});
		return ref.onClose;
	}

	public getSignatureLabel(signature: Signature) {
		const typeSignature: TypeSignature|undefined = this.typesSignature.find(one => {return one.tsi_code == signature.tsi_code;});
		let label: string = 'Signature';
		if (typeSignature) {
			label = `${label} ${typeSignature.tsi_libelle}`;
		}
		label += (signature.sig_electronique)? ` électronique` : ` manuelle`;
		return label;
	}

	public submitSignature(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/soumission`;
		return this.http.post<any>(url, {});
	}

	public sendSignature(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/envoi`;
		return this.http.post<any>(url, {});
	}

	public cancelSignature(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/annulation`;
		return this.http.post<any>(url, {});
	}

	public completeSignature(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/completion`;
		return this.http.post<any>(url, {});
	}

	public resendNotification(sig_id: number, con_id?: number) {
		let url: string = this.getRoutePrefix(sig_id, con_id);
		url = `${url}/renvoi`;
		return this.http.post<any>(url, {});
	}

	public getListSuiviSignatures(params: any, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParams(params);
		let url: string = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		url = `${url}/suivi_signatures`;
		return this.http.get<any>(url, tmpParams)
			.pipe(map(
				(response: any) => {
					this.prepareSignaturesFromServer(response.signatures);
					return response;
				}
			));
	}

	public exportListSuiviSignatures(params: any, grc_id_reference?: number|null, ctx_id_reference?: number|null) {
		let tmpParams = prepareQueryParamsForDownload(params);
		let url: string = this.portailService.getRoutePrefix(grc_id_reference, ctx_id_reference);
		url = `${url}/suivi_signatures/export`;
		return this.http.get<any>(url, tmpParams);
	}

	public currentContexteHasActiveSignature() {
		let url: string = this.portailService.getRoutePrefix(null, 'current');
		return this.http.get<any>(`${url}/signatures_en_cours`);
	}

}
