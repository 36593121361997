import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from '@uirouter/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Contexte } from '@app/contexte/contexte.model';
import { Convention } from '@app/convention/convention.model';
import { Prestation } from '@app/convention/prestation.model';
import { DemandeActionFichier } from '@app/document/demande-action-fichier.model';
import { FilialeAdminPartenariat } from '@app/filiale/filiale.model';
import { GroupeContexte } from '@app/groupe-contexte/groupe-contexte.model';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { Partenariat } from '@app/partenariat/partenariat.model';
import { Portail } from './portail.model';
import { StorageService } from '@global/storage.service';

import {
	clone,
	ExtensibleObject,
	isObject,
	stringSort,
	uid
} from '@helpers/utils';
import { prepareQueryParams } from '@helpers/prepare-query-params';
import { environment } from '@environments/environment';


export type PortailParam = number|string;
export type EntityWithPortail =	Contexte
	|Convention
	|GroupeContexte
	|DemandeActionFichier
	|Prestation
;

export class Droit {
	por_code?: string;
	dro_code: string[]|string|undefined;
	dru_niveau?: number;
	targetEntityOrPortail?: EntityWithPortail|PortailParam;
	targetRequiredPortail?: PortailParam;
}

@Injectable({ providedIn: 'root' })
export class PortailService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private portailsSubject = new BehaviorSubject<Portail[]>([]);

	private portailsAccessiblesSubject = new BehaviorSubject<Portail[]>([]);

	private currentPortailSubject = new BehaviorSubject<any>(null);

	private contextesAccessiblesSubject = new BehaviorSubject<Contexte[]>([]);

	private contextesOfCurrentGroupeSubject = new BehaviorSubject<Contexte[]>([]);

	private currentContexteSubject = new BehaviorSubject<any>(null);
	readonly $currentContexte = this.currentContexteSubject.asObservable();

	private currentDroitsSubject = new BehaviorSubject<any[]>([]);

	private droitsDisponiblesSubject = new BehaviorSubject<any>(null);

	private contactsDisponiblesSubject = new BehaviorSubject<any>(null);

	private partenariatsAdministrablesSubject = new BehaviorSubject<null|any[]>(null);

	constructor(
		private http: HttpClient,
		private eventManager: EventManagerService,
		private storageService: StorageService,
		private stateService: StateService
	) {
		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.resetPortail();
			}
		);
		this.eventManager.registerEvent('resetPortail', this, (args: any) => {
				this.resetPortail();
			}
		);
		this.eventManager.registerEvent('resetContexte', this, (args: any) => {
				this.resetContexte();
			}
		);
		this.eventManager.registerEvent('redirectToContexteSelection', this, (args: any) => {
				this.redirectToContexteSelection();
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('resetPortail', this);
		this.eventManager.unregisterEvent('resetContexte', this);
		this.eventManager.unregisterEvent('redirectToContexteSelection', this);
	}

	public resetCurrentPortail() {
		this.currentPortailSubject.next(null);
	}

	public resetPortail() {
		this.resetCurrentPortail();
		this.portailsAccessiblesSubject.next([]);
		this.resetContexteAccessibles();
		this.eventManager.emit('portail_reseted');
	}

	public resetContexteAccessibles() {
		this.contextesAccessiblesSubject.next([]);
		this.resetContexte();
		this.eventManager.emit('contexte_reseted');
	}

	public resetContexte() {
		this.currentContexteSubject.next(null);
		this.contextesOfCurrentGroupeSubject.next([]);
		this.currentDroitsSubject.next([]);
		this.droitsDisponiblesSubject.next(null);
		this.contactsDisponiblesSubject.next(null);
		this.partenariatsAdministrablesSubject.next(null);
	}

	private redirectToContexteSelection() {
		let target: string = this.getPortailMainState();
		target += '.selection_contexte';
		this.stateService.go(target, {autoselect: false});
	}

	public get portails(): Portail[] {
		return this.portailsSubject.getValue();
	}

	public get portailsAccessibles(): Portail[] {
		return this.portailsAccessiblesSubject.getValue();
	}

	public get currentPortail(): Portail {
		return this.currentPortailSubject.getValue();
	}

	public get contextesAccessibles(): Contexte[] {
		return this.contextesAccessiblesSubject.getValue();
	}

	public get currentContexte(): Contexte {
		return this.currentContexteSubject.getValue();
	}

	public get contextesOfCurrentGroupe(): Contexte[] {
		return this.contextesOfCurrentGroupeSubject.getValue();
	}

	public get currentDroits(): any[] {
		return this.currentDroitsSubject.getValue();
	}

	public get partenariatsAdministrables(): null|any[] {
		return this.partenariatsAdministrablesSubject.getValue();
	}

	public getPortailId(portail: PortailParam) {
		if (typeof portail == 'string') {
			return this.getPortailIdFromCode(portail);
		}
		else return portail;
	}

	public getPortailCode(portail: PortailParam) {
		if (typeof portail == 'number') {
			return this.getPortailCodeFromId(portail);
		}
		else return portail;
	}

	public getPortail(identifier: PortailParam) {
		let found: Portail|undefined;
		if (typeof identifier == 'number') {
			found = this.portails.find((one: Portail) => { return one.por_id == identifier; });
		}
		else {
			found = this.portails.find((one: Portail) => { return one.por_code == identifier; });
		}
		if (!found) {
			throw new Error('Invalid identifier for portail: ' + identifier);
		}
		return found;
	}

	public getPortailIdFromCode(por_code: string) {
		const found = this.getPortail(por_code);
		return found.por_id;
	}

	public getPortailCodeFromId(por_id: number) {
		const found = this.getPortail(por_id);
		return found.por_code;
	}

	public maybeAddPorIdToObject(obj: any, portail?: PortailParam) {
		let tmp = clone(obj);
		if (portail) {
			portail = this.getPortailId(portail);
			tmp = Object.assign(tmp, {por_id: portail});
		}
		return tmp;
	}

	public getPorIdOrCurrentPorId(portail: PortailParam = this.currentPortail.por_id) {
		return this.getPortailId(portail);
	}

	public getPorCodeOrCurrentPorCode(portail: PortailParam = this.currentPortail.por_id) {
		return this.getPortailCode(portail);
	}

	public onPortail(portail: PortailParam) {
		let por_code = this.getPortailCode(this.getPorIdOrCurrentPorId(portail));
		return this.stateService.includes(`portail-${por_code}`);
	}

	public onPortailEqip() {
		return this.onPortail('eqip');
	}

	public onPortailAdherent() {
		return this.onPortail('adherent');
	}

	public onPortailFournisseur() {
		return this.onPortail('fournisseur') || this.onPortail('frais_generaux');
	}

	public getPortailMainState(portail?: PortailParam) {
		let por_code = this.getPortailCode(this.getPorIdOrCurrentPorId(portail));
		return 'portail-' + por_code;
	}

	public getPortailRootState(portail?: PortailParam) {
		let por_code = this.getPortailCode(this.getPorIdOrCurrentPorId(portail));
		let state = 'portail-' + por_code;
		if (por_code != 'eqip') {
			state += '.root';
		}
		return state;
	}

	public getPartenariatsState(portail: PortailParam) {
		let statePartenariats: string;
		switch (this.currentPortail.por_code) {
			case 'adherent':
				statePartenariats = (this.getPortailCode(portail) == 'frais_generaux')? 'adherent_partenariats-fg' : 'adherent_partenariats';
				break;
			default: // case 'eqip':
				statePartenariats = (this.getPortailCode(portail) == 'frais_generaux')? 'eqip_partenariats_fg' : 'eqip_partenariats';
		}
		return statePartenariats;
	}

	public getConventionState(portail: PortailParam) {
		let statePartenariats: string = this.getPartenariatsState(portail);
		return `${statePartenariats}.single.conventions.single`;
	}

	getPortails() {
		return this.http.get<any>(`${environment.api_url}/portails`)
		.pipe(map(response => {
			this.portailsSubject.next(response.portails);
			return response;
		}));
	}

	getPortailsAccessibles() {
		return this.http.get<any>(`${environment.api_url}/mes_portails`)
		.pipe(map(response => {
			// console.log('Portails accessibles', response);
			this.portailsAccessiblesSubject.next(response.portails);
			return response;
		}));
	}

	canAccessPortail(por_code: string) {
		const portailsAccessibles = this.portailsAccessibles;
		const inList = portailsAccessibles.find(one => one.por_code === por_code);
		return !!inList;
	}

	setPortail(portail: Portail|string|number) {
		let found: any;
		if (typeof(portail) === 'string') {
			found = this.portailsAccessibles.find(one => one.por_code === portail);
		}
		else if (Number.isInteger(portail)) {
			found = this.portailsAccessibles.find(one => one.por_id === portail);
		}
		else {
			found = portail;
		}
		if (!found) {
			throw new Error(`Le portail ${portail} n'est pas dans la liste des portails accessibles: ` + JSON.stringify(this.portailsAccessibles));
		}
		this.currentPortailSubject.next(found);
		this.storageService.set('last_portail', found.por_id);
	}

	getContexte(ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}`);
	}

	setContexte(contexte: Contexte) {
		this.currentContexteSubject.next(contexte);
		this.storageService.set('last_contexte', contexte.ctx_id);
	}

	setContexteById(ctx_id: number) {
		const found = this.contextesAccessibles.find(one => {return one.ctx_id == ctx_id; });
		if (found) {
			this.setContexte(found);
		}
	}

	getContextesAccessibles() {
		const currentPortail = this.currentPortail.por_id;
		let tmpParams = prepareQueryParams({
			order_by: 'ctx_libelle',
			order: 'ASC'
		});
		return this.http.get<any>(`${environment.api_url}/portails/${currentPortail}/mes_contextes`, tmpParams)
		.pipe(map(response => {
			// console.log('Contextes accessibles', response);
			this.contextesAccessiblesSubject.next(response.contextes);
			return response;
		}));
	}

	canAccessContexte(ctx_id: number) {
		const contextesAccessibles = JSON.parse(this.storageService.get('contextes', '[]', true));
		const inList = contextesAccessibles.find((one: Contexte) => one.ctx_id === ctx_id);
		return inList;
	}

	getDroitsPortail(por_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${por_id}/droits`)
		.pipe(map(droits => {
			console.log('Droits sur le portail', por_id);
			return droits;
		}));
	}

	getDroitsCurrentContexte() {
		if (!this.currentContexte) {
			return new Observable<any>((subscriber: any) => {
				subscriber.error('CurrentContexte is empty. No permissions');
				subscriber.complete();
			});
		}
		const currentContexte = this.currentContexte.ctx_id;
		return this.http.get<any>(`${environment.api_url}/contextes/${currentContexte}/mes_droits`)
		.pipe(map(droits => {
			// console.log('Mes droits sur le contexte', currentContexte, droits);
			this.currentDroitsSubject.next(droits.droits? droits.droits : droits);
			return droits;
		}));
	}

	getContextesOfCurrentGroupe() {
		const params = prepareQueryParams({
			ctx_actif: true,
			order_by: 'ctx_libelle',
			order: 'ASC'
		});
		return this.http.get<any>(`${environment.api_url}/groupes_contextes/${this.currentContexte.grc_id}/contextes`, params)
		.pipe(map((response: any) => {
			// console.log('Contextes of current group', response);
			this.contextesOfCurrentGroupeSubject.next(response.contextes);
		}))
	}

	checkIfHasRight(droits: Droit[]|Droit, requiresAll: boolean = false) {
		let result = false;
		if (droits instanceof Array) {
			if (requiresAll) {
				return droits.every((item: Droit) => {
					return this.hasRight(item.dro_code, item.dru_niveau, item.por_code, item.targetEntityOrPortail, item.targetRequiredPortail);
				});
			}
			else {
				return droits.some((item: Droit) => {
					return this.hasRight(item.dro_code, item.dru_niveau, item.por_code, item.targetEntityOrPortail, item.targetRequiredPortail);
				});
			}
		}
		else {
			result = this.hasRight(droits.dro_code, droits.dru_niveau, droits.por_code, droits.targetEntityOrPortail, droits.targetRequiredPortail);
		}
		return result;
	}

	hasRight(
		dro_code: string[]|string|undefined,
		dru_niveau?: number|boolean,
		por_code?: string,
		targetEntityOrPortail?: EntityWithPortail|PortailParam, // objet à tester
		targetRequiredPortail?: PortailParam // le portail requis pour l'objet testé
	): boolean {

		// si le droit concerne un portail est qu'on est sur un portail différent, pas besoin d'aller plus loin
		if (por_code && por_code != this.currentPortail.por_code) {
			return false;
		}

		// si le droit concerne le portail d'un objet est que l'objet n'est pas du bon portail, pas besoin d'aller plus loin
		if (targetRequiredPortail) {
			if (!targetEntityOrPortail) {
				return false;
			}
			else {
				const entityPorCode: string = (
					isObject(targetEntityOrPortail) && (targetEntityOrPortail as EntityWithPortail).por_id
				)?
					this.getPortailCode((targetEntityOrPortail as EntityWithPortail).por_id)
					: this.getPortailCode(targetEntityOrPortail as PortailParam)
				;
				if (entityPorCode != this.getPortailCode(targetRequiredPortail)) {
					return false;
				}
			}
		}

		let result = false;
		// array of codes, check if user has right on every one of them
		if (Array.isArray(dro_code)) {
			return dro_code.every((item: string) => {
				return this.hasRightSingle(item, dru_niveau);
			})
		}
		else if (typeof dro_code != 'undefined') {
			// check a single dro_code
			result = this.hasRightSingle(dro_code, dru_niveau);
		}
		else if (typeof dro_code == 'undefined') {
			// apparently, we just wanted to be on a certain portail
			return true;
		}

		return result;
	}

	hasRightSingle(dro_code: string, dru_niveau?: number|boolean): boolean {
		const currentDroits = this.currentDroits;

		// special case of user having access to several portails
		if (dro_code == 'multiple_portails') {
			return this.portailsAccessibles.length > 1;
		}

		// special case of user having access to several contextes
		if (dro_code == 'multiple_contextes') {
			return this.contextesAccessibles.length > 1;
		}

		const found = currentDroits.find(one => one.dro_code === dro_code);
		if (found) {
			if (dru_niveau === undefined) {
				dru_niveau = 1;
			}

			if (
				found.dru_niveau !== 0
				&& (
					found.dru_niveau >= dru_niveau
					|| found.dru_niveau === true
					|| found.dro_type_booleen && dru_niveau > 0
				)
			) {
				return true;
			}
		}
		return false;
	}

	getDroitsDisponibles(por_id: number, refresh?: boolean) {
		let current = this.droitsDisponiblesSubject.getValue();
		if (!refresh && current && current[por_id]) {
			return new Observable(subscriber => {
				subscriber.next(current[por_id]);
				subscriber.complete();
			});
		}
		else {
			return this.http.get<any>(`${environment.api_url}/portails/${por_id}/droits`)
			.pipe(map(response => {
				stringSort(response.droits, 'dro_libelle');
				let temp: {[key: string]: any} = {};
				temp[por_id] = response;
				let so = (current)? Object.assign(current, temp) : Object.assign({}, temp);
				this.droitsDisponiblesSubject.next(so);
				return response;
			}));
		}
	}

	getContactsDisponibles(portail: PortailParam) {
		let por_id = this.getPortailId(portail);
		let current = this.contactsDisponiblesSubject.getValue();
		if (current && current[por_id]) {
			return new Observable(subscriber => {
				subscriber.next(current[por_id]);
				subscriber.complete();
			});
		}
		else {
			return this.http.get<any>(`${environment.api_url}/portails/${por_id}/contacts`)
			.pipe(map(response => {
				let temp: {[key: string]: any} = {};
				temp[por_id] = response.contacts;
				let so = (current)? Object.assign(current, temp) : Object.assign({}, temp);
				this.contactsDisponiblesSubject.next(so);
				return response.contacts;
			}));
		}
	}

	getRoutePrefix(
		grc_id?: number|null|'current',
		ctx_id?: number|null|'current',
		portail?: PortailParam|'current'
	) {
		let url: string = `${environment.api_url}`;

		let por_id: number|null = null;
		if (portail) {
			if (portail == 'current') {
				por_id = this.currentPortail.por_id;
			}
			else {
				por_id = this.getPortailId(portail);
			}
		}

		if (ctx_id) { // pour un contexte
			if (ctx_id == 'current') {
				ctx_id = this.currentContexte.ctx_id;
			}
			url = `${url}/contextes/${ctx_id}`;
		}
		else if (grc_id) { // pour un groupe
			if (grc_id == 'current') {
				grc_id = this.currentContexte.grc_id;
			}
			url = `${url}/groupes_contextes/${grc_id}`;
		}
		else if (por_id) { // pour un portail
			url = `${url}/portails/${por_id}`;
		}
		return url;
	}

	hasAccesGroupe(por_code: any = this.currentPortail.por_code) {
		return this.hasRight('acces_groupe', true, por_code);
	}

	hasAccesGroupement() {
		return this.hasRight('acces_groupement');
	}

	getAccesGroupeParams(grc_id?: number|null, ctx_id?: number|null, groupementModifier: boolean = false) {
		let hasAccesGroupement: boolean = false;
		let droitHasAccesGroupementAdherent = {por_code: 'adherent', dro_code: 'acces_groupement'};
		let hasAccesGroupementAdherent: boolean = false;

		let hasAccesGroupe: boolean = false;
		let droitHasAccesGroupeAdherent = {por_code: 'adherent', dro_code: 'acces_groupe'};
		let hasAccesGroupeAdherent: boolean = false;;
		let droitHasAccesGroupeFournisseur = [
			{por_code: 'fournisseur', dro_code: 'acces_groupe'},
			{por_code: 'frais_generaux', dro_code: 'acces_groupe'}
		];
		let hasAccesGroupeFournisseur: boolean = false;

		hasAccesGroupementAdherent = this.checkIfHasRight(droitHasAccesGroupementAdherent);
		hasAccesGroupeAdherent = this.checkIfHasRight(droitHasAccesGroupeAdherent);
		hasAccesGroupeFournisseur = this.checkIfHasRight(droitHasAccesGroupeFournisseur);

		if (groupementModifier && hasAccesGroupementAdherent) {
			hasAccesGroupement = true;
		}

		if (hasAccesGroupeAdherent || hasAccesGroupeFournisseur) {
			hasAccesGroupe = true;
		}

		return {
			ctx_id: (!hasAccesGroupement && !hasAccesGroupe)? ctx_id : undefined,
			grc_id: (!hasAccesGroupement && hasAccesGroupe)? grc_id : undefined,
		};
	}

	getPartenariatsAdministrables(ctx_id: number|null|'current' = 'current') {
		let url: string = this.getRoutePrefix(null, ctx_id);
		url = `${url}/partenariats_administrables`;
		return this.http.get<any>(url)
		.pipe(map(
			(response: any) => {
				this.partenariatsAdministrablesSubject.next(response);
				return response;
			}
		));
	}

	isAdminPartenariat(entity: Contexte|Convention) {
		const droitsGroupement = [
			{
				por_code: 'eqip',
				dro_code: ['fournisseur', 'contrats'],
				targetEntityOrPortail: entity,
				targetRequiredPortail: 'fournisseur'
			},
			{
				por_code: 'eqip',
				dro_code: ['frais_generaux', 'contrats'],
				targetEntityOrPortail: entity,
				targetRequiredPortail: 'frais_generaux',
			},
		];

		const droitsAdherent = [
			{
				por_code: 'adherent',
				dro_code: ['acces_fournisseurs', 'admin_partenariat_fournisseur'],
				targetEntityOrPortail: entity,
				targetRequiredPortail: 'fournisseur',
			},
			{
				por_code: 'adherent',
				dro_code: ['acces_fg', 'admin_partenariat_fg'],
				targetEntityOrPortail: entity,
				targetRequiredPortail: 'frais_generaux',
			},
		];

		let result: boolean = false
		if (this.onPortailEqip()) {
			result = this.checkIfHasRight(droitsGroupement);
		}
		else {
			result = this.checkIfHasRight(droitsAdherent)
				&& Array.isArray(this.partenariatsAdministrables)
				&& this.partenariatsAdministrables.some((item: FilialeAdminPartenariat) => {
					return item.ctx_par_id == entity.ctx_id
				})
			;
		}
		return result;
	}

	isSiblingOfCurrentContexte(contexte: Contexte|number) {
		if (this.contextesOfCurrentGroupe.length === 1) {
			return (typeof contexte == 'number')? this.currentContexte.ctx_id == contexte : this.currentContexte.ctx_id == contexte.ctx_id;
		}
		if (typeof contexte == 'number') {
			return this.contextesOfCurrentGroupe.some((one: Contexte) => {
				return one.ctx_id == contexte;
			});
		}
		else {
			return this.currentContexte.grc_id == contexte.grc_id;
		}
	}

}
