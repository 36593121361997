import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommissionActivite } from '@app/marque/commission-activite.model';
import { Marque } from '@app/marque/marque.model';
import { PortailService, PortailParam } from '@app/portail/portail.service';

import { prepareQueryParams } from '@helpers/prepare-query-params';
import { environment } from '@environments/environment';
import { clone } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class MarqueService {

	private commissionsActiviteSubject = new BehaviorSubject<any[]>([]);
	readonly commissionsActivite = this.commissionsActiviteSubject.asObservable();

	constructor(
		private http: HttpClient,
		private portailService: PortailService
	) {

	}

	public get commissionsActiviteValue() {
		return this.commissionsActiviteSubject.getValue();
	}

	public filterCommissions(portail?: PortailParam) {
		let commissions = clone(this.commissionsActiviteValue);
		if (portail) {
			portail = this.portailService.getPortailId(portail);
			commissions = this.commissionsActiviteValue.filter(one => { return one.por_id == portail; });
		}
		return commissions;
	}

	public getCommissionsActivites(params?: any, portail?: PortailParam, refresh?: boolean) {
		if (!refresh && this.commissionsActiviteValue && this.commissionsActiviteValue.length) {
			return new Observable<any>(subscriber=> {
			 	subscriber.next(this.filterCommissions(portail));
				subscriber.complete();
			});
		}
		else {
			let tmpParams = params? params : {order_by: 'cma_libelle', order: 'ASC'};
			tmpParams = prepareQueryParams(tmpParams);
			return this.http.get<any>(`${environment.api_url}/commissions_activites`, tmpParams)
			.pipe(map((response: any) => {
				this.commissionsActiviteSubject.next(response['commissions_activites']);
				return this.filterCommissions(portail);
			}));
		}
	}

	public postCommissionActivite(commission: CommissionActivite) {
		return this.http.post<any>(`${environment.api_url}/commissions_activites`, commission)
		.pipe(map(
			response => {
				this.commissionsActiviteSubject.next([]);
				return response;
			}
		));
	}

	public putCommissionActivite(commission: CommissionActivite) {
		return this.http.put<any>(`${environment.api_url}/commissions_activites/${commission.cma_id}`, commission)
		.pipe(map(
			response => {
				this.commissionsActiviteSubject.next([]);
				return response;
			}
		));
	}

	public deleteCommissionActivite(cma_id: number) {
		return this.http.delete<any>(`${environment.api_url}/commissions_activites/${cma_id}`)
		.pipe(map(
			response => {
				this.commissionsActiviteSubject.next([]);
				return response;
			}
		));
	}

	public getMarques(params: any, grc_id?: number) {
		let url = this.portailService.getRoutePrefix(grc_id);
		return this.http.get<any>(`${url}/marques_fournisseurs`, prepareQueryParams(params))
	}

	public get(mar_id: number, grc_id: number) {
		return this.http.get<any>(`${environment.api_url}/groupes_contextes/${grc_id}/marques_fournisseurs/${mar_id}`);
	}

	public post(marque: Marque) {
		return this.http.post<any>(`${environment.api_url}/groupes_contextes/${marque.grc_id}/marques_fournisseurs`, marque);
	}

	public put(marque: Marque) {
		return this.http.put<any>(`${environment.api_url}/groupes_contextes/${marque.grc_id}/marques_fournisseurs/${marque.mar_id}`, marque);
	}

	public delete(mar_id: number, grc_id: number) {
		return this.http.delete<any>(`${environment.api_url}/groupes_contextes/${grc_id}/marques_fournisseurs/${mar_id}`);
	}

}
