import { Adresse } from '@app/societe/adresse.model';

export class Societe {
	soc_id?: number;
	soc_siret: string;
	soc_raison_sociale: string;
	soc_type_societe: string = 'SAS';
	soc_site_web: string;
	soc_tva_intracommunautaire?: string;
	soc_capital: number;
	soc_date_debut_activite?: string;
	soc_date_cessation_activite?: string;
	soc_dirigeant: string;
	soc_ville_enregistrement: string;
	soc_numero_enregistrement: string;
	adresses?: Adresse[];
}
