<p-panel header="{{title}}">
	<ng-template pTemplate="icons" *ngIf="canEdit">
		<p-button class="label-md-none" label="Editer" icon="pi pi-pencil" (click)="toggleEditMode()" *ngIf="!editMode">
		</p-button>
		<p-button class="label-md-none" label="Enregistrer" icon="pi pi-save" (click)="submit()" *ngIf="!!editMode">
		</p-button>
	</ng-template>

	<form name="abonnementsForm">
		<p-table
			[value]="[1]"
			styleClass="p-datatable-sm mb-2"
			responsiveLayout="scroll"
		>
			<ng-template pTemplate="body">
				<ng-container *ngFor="let typeContact of abonnements">
					<tr>
						<td class="col-header col-libelle">
							{{typeContact.ctc_libelle}}
						</td>
						<td class="nowrap">
							<p-checkbox
								name="{{typeContact.ctc_id}}"
								inputId="{{typeContact.ctc_id}}"
								[(ngModel)]="typeContact.abonnement"
								[binary]="true"
								[disabled]="!editMode"
								(onChange)="onChange($event)"
							></p-checkbox>
						</td>
						<td class="text-sm">
							{{typeContact.ctc_description}}
						</td>

					</tr>
				</ng-container>

			</ng-template>
		</p-table>

	</form>
</p-panel>
