import { NgModule, Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { Observable, Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';

import { AppMenuService } from '@global/app-menu.service';
import { GzPanelMenuModule } from '@app/components/gz-panelmenu';
import { ParametreService } from '@app/parametre/parametre.service';
import { StorageService } from '@global/storage.service';

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {

	private subscriptionMenuOpened: Subscription;
	private subscriptionToggleMobileMenu: Subscription;
	private subscriptionMenuItems: Subscription;
	private body = document.getElementsByTagName('body')[0];

	private breakpointMobile = 768;

	displayAppLogo: boolean = false;

	items: MenuItem[];

	constructor(
		private appMenuService: AppMenuService,
		private parametreService: ParametreService,
		private stateService: StateService,
		private storageService: StorageService
	) {
		this.subscriptionMenuOpened = appMenuService.toggleMenu$.subscribe(toggle => this.toggle());
		this.subscriptionToggleMobileMenu = appMenuService.toggleMobileMenu$.subscribe(toggle => this.toggleMobileMenu(toggle));
		this.subscriptionMenuItems = appMenuService.menuItems$.subscribe(items => {
			this.items = items;
			// this.toggleMenu((items.length && this.body.offsetWidth > this.breakpointMobile)? true : false);
			const sidebar_hidden = this.storageService.get('sidebar_hidden', false);
			// display if menu has items and if it wasn't hidden before
			const hideIt = (items.length && !!!sidebar_hidden)? false : true;
			this.toggleDesktopMenu(hideIt);
		});

		this.displayAppLogo = this.parametreService.getParam('display_app_logo');
	}

	toggle() {
		if (this.body.offsetWidth < this.breakpointMobile) {
			this.toggleMobileMenu();
		}
		else {
			this.toggleDesktopMenu(!this.body.classList.contains('sidebar-hidden'), true);
		}
	}

	toggleDesktopMenu(hidden?: boolean, manual?: boolean) {
		this.body.classList.toggle('sidebar-hidden', hidden);
		if (manual) {
			this.storageService.set('sidebar_hidden', this.body.classList.contains('sidebar-hidden'));
		}
	}

	toggleMobileMenu(visible?: boolean) {
		this.body.classList.toggle('mobile-sidebar-visible', visible);
	}

	ngOnInit() {
		// this.items = []
	}

	ngOnDestroy() {
		this.subscriptionMenuOpened.unsubscribe();
		this.subscriptionMenuItems.unsubscribe();
		this.subscriptionToggleMobileMenu.unsubscribe();
	}
}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GzPanelMenuModule
	],
	exports: [AppMenuComponent],
	declarations: [AppMenuComponent]
})
export class AppMenuModule { }

