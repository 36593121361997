<utilisateur-form
	[utilisateur]="utilisateur"
	[contexte]="contexte"
	[canEdit]="canEdit"
	[ngClass]="'block mb-2'"
	(updatedEvent)="formDirtyStatus.utilisateur = $event"
	*ngIf="contexte"
></utilisateur-form>

<utilisateur-droits-contexte
	[uti_id]="utilisateur.uti_id"
	[contexte]="contexte"
	[canEdit]="canEdit && !selfEditing"
	*ngIf="utilisateur.uti_id && contexte"
	(updatedEvent)="formDirtyStatus.droits = $event"
	[ngClass]="'block mb-2'"
></utilisateur-droits-contexte>

<utilisateur-abonnements-contact
	[uti_id]="utilisateur.uti_id"
	[contexte]="contexte"
	[canEdit]="canEdit"
	(updatedEvent)="formDirtyStatus.abonnements = $event"
	*ngIf="utilisateur.uti_id && contexte"
></utilisateur-abonnements-contact>
