import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { ConventionService } from '@app/convention/convention.service';
import { ModeVision } from '@app/portail/mode-vision';
import { PortailParam, PortailService } from '@app/portail/portail.service';
import { TcoType } from '@app/convention/type-convention.model';

import { getCurrentPivotedDate } from '@helpers/utils';

@Component({
	selector: 'annee-convention-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AnneeConventionSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			required
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class AnneeConventionSelectorComponent implements OnInit, ControlValueAccessor {

	@Input() name: string;
	@Input('value') innerValue: number|null|undefined;
	@Input() grc_id: number;
	@Input() ctx_id: number;
	@Input() portail: PortailParam;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() autoSelectCurrent: boolean;
	@Input() disabled: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() mode: ModeVision;
	@Input() tco_type: TcoType;
	@Input() useAccesGroupementModifier: boolean = false;
	@Input() useAccesGroupeModifier: boolean = false;
	@Input() silentInitialChangeIfSame: boolean = false;
	@Input() reverse: boolean = false;
	@Input() modifier: number = 0;

	options: any[] = [];

	constructor(
		private conventionService: ConventionService,
		private portailService: PortailService
	) {

	}

	ngOnInit() {
		let ctx_id: number|null|undefined = this.ctx_id;
		let grc_id: number|null|undefined = this.grc_id;

		if (this.useAccesGroupeModifier) {
			let tmp = this.portailService.getAccesGroupeParams(grc_id, ctx_id, this.useAccesGroupementModifier);
			ctx_id = tmp.ctx_id;
			grc_id = tmp.grc_id;
		}

		this.conventionService.getAnneesAvecConventions(
			this.portail,
			grc_id,
			ctx_id,
			this.mode,
			this.tco_type,
			this.useAccesGroupementModifier
		)
		.subscribe(
			(response: any) => {
				let tmp = response.map((one: any) => {
					one = one + this.modifier;
					return { value: one, label: one.toString() };
				});

				if (this.reverse) {
					tmp.reverse();
				}
				this.options = tmp;
				this.setInitialValue();
				this.placeholder = (this.options.length)? 'Sélectionnez' : 'Aucune année avec conventions';
			}
		);
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (this.autoSelectCurrent && !this.innerValue && this.options.length) {
			const today = getCurrentPivotedDate();
			const index = this.options.findIndex((one: any) => {
				return one.value == today.getFullYear();
			});
			if (index >= 0) {
				this.innerValue = this.options[index].value;
			}
			else {
				// select greatest
				const greatest = this.getGreatest();
				this.innerValue = greatest.value;
			}
		}
		else {
			if (this.options.findIndex((one: any) => { return one.value == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				else if (this.options.length){
					// select greatest
					const greatest = this.getGreatest();
					this.innerValue = greatest.value;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

	getGreatest() {
		return this.options.reduce((prev: any, current: any) => {
			return (prev.value > current.value) ? prev : current;
		});
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: number|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [AnneeConventionSelectorComponent],
	declarations: [AnneeConventionSelectorComponent]
})
export class AnneeConventionSelectorModule { }
