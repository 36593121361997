import { Segment } from './segment.model';
import { Ristourne } from '@app/ristourne/ristourne.model';

export class GroupeConditions {
	sca_groupe_condition: number = 0;
	segments: Segment[] = [];
	ristournes: Ristourne[] = [];

	constructor(sca_groupe_condition?: number) {
		if (typeof sca_groupe_condition != 'undefined') {
			this.sca_groupe_condition = sca_groupe_condition;
		}
	}
}
