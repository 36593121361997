<p>Je donne mon accord à {{instanceName}} pour l’utilisation de mes informations personnelles dans le cadre des utilisations de cette application.</p>
<p>Données confidentielles à usage unique des adhérents, des fournisseurs et de {{instanceName}}.
</p>

<div class="flex justify-content-center">
	<div class="field-checkbox">
		<p-checkbox
			name="accord"
			inputId="accord"
			[(ngModel)]="accord"
			[binary]="true"
			label="J'accepte"
		></p-checkbox>
	</div>
</div>

<div class="text-center">
	<button
		pButton
		type="button"
		label="Valider"
		[loading]="loading"
		(click)="submit()"
		[disabled]="!accord"
	></button>
</div>
