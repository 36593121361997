import { Societe } from '@app/societe/societe.model';

import { IPRequise } from '@helpers/ip-requise.model';

export class GroupeContexte {
	grc_id: number;
	grc_libelle: string;
	grc_logo_print?: string;
	grc_logo_listing?: string;
	grc_actif: boolean = true;
	grc_date_refencement: string;
	grc_2fa: boolean;
	por_id: number;
	soc_id: number;
	societe: Societe;
	cig_code?: string;

	ip_requises: IPRequise[];
}
