import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ModeProrataPrestation } from '@app/convention/mode-prorata-prestation.model';
import { PrestationService } from '@app/convention/prestation.service';

@Injectable({providedIn: 'root'})
@Pipe({name: 'modeProrataPrestation'})
export class ModeProrataPrestationPipe implements PipeTransform {

	constructor(private prestationService: PrestationService) {}

	transform(mpp_code?: string): string {
		const modesProrata = this.prestationService.modesProrataPrestation;
		if (typeof mpp_code != 'undefined') {
			const mode = modesProrata.find((one: ModeProrataPrestation) => {return one.mpp_code == mpp_code;});
			if (mode) {
				return mode.mpp_libelle;
			}
			return mpp_code.toString();
		}
		return '';
	}
}
