import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Agence } from '@app/agence/agence.model';
import { ListesGeneriquesService } from '@global/listes-generiques.service';
import { SocieteService } from '@app/societe/societe.service';
import { TypeAgence } from '@app/agence/type-agence.model';

import { clone } from '@helpers/utils';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@helpers/prepare-query-params';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AgenceService {

	typesAgence: TypeAgence[];
	// [
	// 	{ tag_id: 1, tag_libelle: 'Point de vente'},
	// 	{ tag_id: 2, tag_libelle: 'Entrepôt'},
	// 	{ tag_id: 3, tag_libelle: 'Administration'},
	// ];

	constructor(
		private http: HttpClient,
		private listesGeneriquesService: ListesGeneriquesService,
		private societeService: SocieteService
	) {
		this.typesAgence = listesGeneriquesService.getListe('types_agences');
	}

	public getDummy() {
		return new Agence();
	}

	public prepare(obj: Agence) {
		let prepared = clone(obj);
		prepared.societe = this.societeService.prepareForSubmit(obj.societe);
		return prepared;
	}

	public get getTypesAgence() {
		return [...this.typesAgence];
	}

	// todo type this
	public getAgences(params: any, ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/agences_filiales`, prepareQueryParams(params));
	}

	public get(age_id: any, ctx_id: number) {
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/agences_filiales/${age_id}`)
		.pipe(map(obj => {
			obj.societe = this.societeService.prepareFromServer(obj.societe);
			return obj;
		}));
	}

	public post(agence: Agence) {
		let prepared = this.prepare(agence);
		return this.http.post<any>(`${environment.api_url}/contextes/${agence.ctx_fil_id}/agences_filiales`, prepared);
	}

	public put(agence: Agence) {
		let prepared = this.prepare(agence);
		return this.http.put<any>(`${environment.api_url}/contextes/${prepared.ctx_fil_id}/agences_filiales/${prepared.age_id}`, prepared);
	}

	public delete(agence: Agence) {
		return this.http.delete<any>(`${environment.api_url}/contextes/${agence.ctx_fil_id}/agences_filiales/${agence.age_id}`);
	}

	public exportList(params: any, ctx_id: number) {
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/contextes/${ctx_id}/export_agences_filiales`, tmpParams);
	}

	public getUrlUploadAgencesFiliales(ctx_id: number) {
		return `${environment.api_url}/contextes/${ctx_id}/import_agences_filiales`;
	}

	public exportAgencesFiliales() {
		return this.http.get<any>(`${environment.api_url}/agences_filiales/export`, prepareQueryParams(null, true));
	}

}
