import { NgModule, Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable, Subject, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { TagModule } from 'primeng/tag';

import { NotificationService } from '@app/notification/notification.service';

import { EqipModule } from '@global/eqip.module';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'compteur-notifications-admin',
	providers: [],
	template: `
		<ng-container *ngIf="!fetchError">
			<p-tag severity="danger" [value]="countNonTraitees" title="Non-traitées" *ngIf="countNonTraitees"></p-tag>
			<p-tag [value]="countNonLues" title="Non-lues" *ngIf="countNonLues"></p-tag>
		</ng-container>
		<ng-container *ngIf="fetchError">
			<p-tag severity="danger" [value]="'?'" title="Non-traitées" *ngIf="countNonTraitees"></p-tag>
			<p-tag [value]="'?'" title="Non-lues" *ngIf="countNonLues"></p-tag>
		</ng-container>
	`,
})
export class CompteurNotificationsAdminComponent implements OnInit, OnDestroy, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	@Input() initialLoad: boolean = false;

	initialDelay:  number = 500;
	delays: number[] = [30000, 60000, 120000, 300000, 600000];
	autoRefresh: boolean = false;
	attempts: number = 0;
	stopTimer$: Subject<any> = new Subject;
	loading: boolean = false;
	fetchError: boolean = false;

	countNonTraitees: number = 0;
	countNonLues: number = 0;

	constructor(
		private cdRef: ChangeDetectorRef,
		private eventManager: EventManagerService,
		private notificationService: NotificationService,
		private parametreService: ParametreService,
	) {
		this.autoRefresh = this.parametreService.getParam('auto_refresh_notif_admin_counter');
	}

	ngOnInit() {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.stopPolling();
		});
		this.eventManager.registerEvent('refresh_notif_admin_counter', this, (args: any) => {
			this.refresh(false, true);
		});

		if (this.autoRefresh) {
			this.startPolling();
		}
	}

	ngOnDestroy() {
		this.eventManager.unregisterEvent('refresh_notif_admin_counter', this);
		this.eventManager.unregisterEvent('logout', this);
		this.stopPolling();
	}

	startPolling() {
		const initialDelay = (this.attempts)? this.delays[this.attempts] : this.initialDelay;
		timer(initialDelay, this.delays[this.attempts])
		.pipe(
			takeUntil(this.stopTimer$),
			switchMap(
			(count: number) => {
				return this.load();
			}
		))
		.subscribe(
			(response: any) => {
				this.countNonTraitees = response.total_non_traitees;
				this.countNonLues = response.total_non_lues;
				this.fetchError = false;
				this.attempts = 0;
				this.cdRef.detectChanges(); // force change detection
				if (!this.autoRefresh) {
					this.stopPolling();
				}
			},
			(error: any) => {
				this.fetchError = true;
				this.cdRef.detectChanges(); // force change detection
				this.refresh(true);
			}
		)
		.add(() => { this.loading = false; });
	}

	load() {
		this.loading = true;
		return this.notificationService.getCountNotificationsAdmin();
	}

	stopPolling() {
		this.stopTimer$.next(true)
	}

	refresh(increaseAttempts?: boolean, resetAttempts?: boolean) {
		this.stopPolling();
		if (increaseAttempts && this.attempts < this.delays.length -1) {
			this.attempts++;
		}
		else if (resetAttempts) {
			this.attempts = 0;
		}
		this.startPolling();
	}

}


@NgModule({
	imports: [
		CommonModule,
		EqipModule,
		TagModule,
	],
	exports: [CompteurNotificationsAdminComponent],
	declarations: [CompteurNotificationsAdminComponent]
})
export class CompteurNotificationsAdminModule { }
