import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({name: 'groupeConditions'})
export class GroupeConditionsPipe implements PipeTransform {

	constructor() {}

	transform(sca_groupe_condition: number, short: boolean = false): string {
		let tmp: string = '';
		if (!short) tmp = 'Groupe de conditions';
		if (sca_groupe_condition === 0) {
			tmp = `${tmp} par défaut`;
		}
		else {
			tmp = `${tmp} n°${sca_groupe_condition}`;
		}
		return tmp;
	}
}
