import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import { AgenceService } from '@app/agence/agence.service';

import { createDownloadFromHttpResponse } from '@helpers/utils';

@Directive({
	selector: '[iteqExport]',
	providers: [],
})
export class IteqExportDirective {

	@Input() iteqExport: string;
	@Input() exportLoading: boolean;
	@Output() exportLoadingChange: EventEmitter<boolean> = new EventEmitter();

	constructor(
		private agenceService: AgenceService,
		private elementRef: ElementRef,
		) {

	}

	@HostListener('click')
	onClick() {

		let exportCode: string;
		let pos = this.iteqExport.indexOf(':');
		pos = (pos == -1)? 0 : pos +1;
		exportCode = this.iteqExport.substring(pos);
		let apiCall: Observable<any>|null = null;

		switch(exportCode) {
			case 'agences':
				apiCall = this.agenceService.exportAgencesFiliales()
				break;
		}

		if (apiCall) {
			this.exportLoadingChange.emit(true);
			apiCall.subscribe(
				(response: any) => {
					createDownloadFromHttpResponse(response);
				}
			)
			.add(() => { this.exportLoadingChange.emit(false); });
		}
	}
}
