<p-panel>
	<ng-template pTemplate="header">
		<div>
			<div>
				<span>{{archive.tff_nom}} </span>
			</div>
			<small><span>({{archive.tff_taille_archive | formatSize}})</span></small>
		</div>
	</ng-template>
	<ng-template pTemplate="icons">
		<div class="grid">
			<div class="col">
				<button
					type="button"
					pButton
					class="label-md-none mr-2"
					icon="pi pi-download"
					label="Télécharger"
					(click)="download()"
				></button>
			</div>
		</div>
	</ng-template>

	<div class="grid formgrid p-fluid">

		<div class="field col-12 md:col-6 lg:col-4 xl:col-3">
			<label for="tff_taille_fichiers">Taille (nom compressé)</label>
			<input
				pInputText
				type="text"
				readonly
				value="{{archive.tff_taille_fichiers | formatSize}}"
			>
		</div>

		<div class="field col-12 md:col-6 lg:col-4 xl:col-3">
			<label for="nombre_fichiers">Nombre de fichiers </label>
			<input
				pInputText
				type="text"
				readonly
				value="{{archive.nombre_fichiers}}"
			>
		</div>

		<div class="field col-12 md:col-6 lg:col-4 xl:col-3">
			<label for="tff_date_creation">Date de création</label>
			<input
				pInputText
				type="text"
				readonly
				value="{{archive.tff_date_creation | date: 'dd/MM/yyyy HH:mm:ss'}}"
			>
		</div>

		<div class="field col-12 md:col-6 lg:col-4 xl:col-3">
			<label for="tff_date_telechargement">Date dernier téléchargement</label>
			<input
				pInputText
				type="text"
				readonly
				value="{{archive.tff_date_telechargement | date: 'dd/MM/yyyy HH:mm:ss'}}"
			>
		</div>

		<div class="field col-12 md:col-6 lg:col-4 xl:col-3">
			<label for="date_expiration">Date d'expiration</label>
			<input
				pInputText
				type="text"
				readonly
				value="{{archive.tff_date_expiration | date: 'dd/MM/yyyy'}}"
			>
		</div>

	</div>

</p-panel>
