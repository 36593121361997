import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({name: 'utilisateur'})
export class UtilisateurPipe implements PipeTransform {

	constructor() {}

	transform(entity: any): string {
		let tmp = '';
		if (typeof entity.uti_prenom != 'undefined') {
			tmp = this.append(tmp, entity.uti_prenom);
		}
		if (typeof entity.uti_nom != 'undefined') {
			tmp = this.append(tmp, entity.uti_nom);
		}
		if (typeof entity.uti_email != 'undefined') {
			tmp = this.append(tmp, `(${entity.uti_email})`);
		}
		return tmp;
	}

	append(str: string, more: string, separator: string = ' ') {
		if (str.length) {
			str = `${str}${separator}`;
		}
		return `${str}${more}`;
	}
}
