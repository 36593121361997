import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'modeCalcul'})
export class ModeCalculPipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(mca_code?: string): string {
		const modesCalcul = this.conventionService.modesCalcul;
		const segment = modesCalcul.find(one => {return one.mca_code == mca_code;});
		if (segment) {
			return  segment.mca_libelle;
		}
		return mca_code? mca_code.toString() : '';
	}
}
