import { ExtensibleObject } from '@helpers/utils';

export class SignataireConvention extends ExtensibleObject {
	// [key: string]: any;

	con_id: number = 0;

	sco_id: number = 0;
	get id(): number {return this.sco_id;}
	set id(id: number) {this.sco_id = id;}

	sco_email: string|null = null;
	get email(): string|null { return this.sco_email; }
	set email(value: string|null) {this.sco_email = value;}

	sco_tel: string|null = null;
	get tel(): string|null { return this.sco_tel; }
	set tel(value: string|null) { this.sco_tel = value; }

	sco_civilite: string = '';
	get civilite(): string { return this.sco_civilite; }
	set civilite(value: string) { this.sco_civilite = value; }

	sco_identite: string = '';
	get identite(): string { return this.sco_identite; }
	set identite(value: string) { this.sco_identite = value; }

	sco_fonction: string = '';
	get fonction(): string { return this.sco_fonction; }
	set fonction(value: string) { this.sco_fonction = value; }

	sco_relecteur: boolean = false;
	get relecteur(): boolean { return this.sco_relecteur; }
	set relecteur(value: boolean) { this.sco_relecteur = value; }

	sco_signataire_principal: boolean = false;
	get signataire_principal(): boolean { return this.sco_signataire_principal; }
	set signataire_principal(value: boolean) { this.sco_signataire_principal = value; }

	sco_validation_sms: boolean = false;
	get validation_sms(): boolean { return this.sco_validation_sms; }
	set validation_sms(value: boolean) { this.sco_validation_sms = value; }

	sco_ordre: number = 0;
	get ordre(): number { return this.sco_ordre; }
	set ordre(value: number) { this.sco_ordre = value; }

	label: string = '';

	constructor(data?: SignataireConvention) {
		super();
		if (data) {
			this.assign(data);
		}
	}

	assign(data: SignataireConvention) {
		for (let prop in this) {
			if (typeof data[prop] != 'undefined') {
				this[prop as string] = data[prop];
			}
		}
		this.label = `${this.civilite} ${this.identite}`;
	}

}

