import { Pipe, PipeTransform } from '@angular/core';

import { ComptaService } from '@app/compta/compta.service';
import { EtatDistributionReglement } from '@app/compta/etat-distribution-reglement.model';

@Pipe({name: 'etatDistributionReglement'})
export class EtatDistributionReglementPipe implements PipeTransform {

	constructor(private comptaService: ComptaService) {}

	transform(edb_code?: string): string {
		const etatsDistributionReglement = this.comptaService.etatsDistributionReglement;
		const etat = etatsDistributionReglement.find((one: EtatDistributionReglement) => {return one.edb_code == edb_code;});
		if (etat) {
			return  etat.edb_libelle;
		}
		return edb_code? edb_code.toString() : '';
	}
}
