import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';
import { TypePerformance } from '@app/ristourne/type-performance.model';
import { TypePerformanceParam } from '@app/ristourne/type-performance.model';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'typePerformanceRistourne'})
export class TypePerformanceRistournePipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	typesPerformance: TypePerformance[];

	transform(tpe_code: TypePerformanceParam|TypePerformanceParam[], separator: string = ', '): string {
		this.typesPerformance = this.conventionService.typesPerformance;
		if (Array.isArray(tpe_code)) {
			let result: string[] = [];
			tpe_code.forEach((one: TypePerformanceParam) => {
				result.push(this.transformOne(one));
			});
			result.sort();
			return result.join(separator);
		}
		return this.transformOne(tpe_code);
	}

	transformOne(tpe_code: TypePerformanceParam) {
		const typePerformance = this.typesPerformance.find((one: TypePerformance) => {
			return one.tpe_code == tpe_code;
		});
		if (typePerformance) {
			return  typePerformance.tpe_libelle;
		}
		return tpe_code? tpe_code : '';
	}
}
