import { Pipe, PipeTransform } from '@angular/core';

import { ConventionService } from '@app/convention/convention.service';

@Pipe({name: 'etatConvention'})
export class EtatConventionPipe implements PipeTransform {

	constructor(private conventionService: ConventionService) {}

	transform(etc_code: string): string {
		let etat = this.conventionService.findEtatConvention(etc_code);
		if (etat) {
			return  etat.etc_libelle;
		}
		return etc_code? etc_code.toString() : '';
	}
}
