import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EqipModule } from '@global/eqip.module';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';

import { DocumentService } from '@app/document/document.service';
import { Archive } from '@app/document/archive.model';


@Component({
	selector: 'archive-information',
	providers: [],
	templateUrl: './archive-information.html',
})
export class ArchiveInformationComponent implements OnInit {

	@Input() archive: Archive;

	canEdit: boolean;

	constructor(
		private documentService: DocumentService,
	) {

	}

	ngOnInit() {

	}

	download() {
		this.documentService.downloadArchive(this.archive.tff_id);
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		EqipModule,
		ButtonModule,
		InputTextModule,
		PanelModule,
	],
	exports: [ArchiveInformationComponent],
	declarations: [ArchiveInformationComponent]
})
export class ArchiveInformationModule { }
